export default {
  'test': 'testEN',
  registerbutton: {
    register: 'Register'
  },
  eventsearch: {
    nachfachthemen: "By topic",
    nachformaten: "By format",
    nachdatum: "By date",
    nachreferenten: "By speaker",
    nachschlagworten: "By subject",

    unserefachthemen: "Our topics",
    unsereformate: "Our formats",
    unserereferenten: "Our speakers",
    unsereschlagworten: "By subject",
    
    header: "Search",
    suchen: "Search",
    loadevents: "Loading filters",

    selectfilter: "Select a filter",
  },
  headerprofile: {
    profil: "Profile",
    logout: "Logout",
    login: "Login"
  },
  topevents: {
    loadingtext: "Loading events...",
    mightinterestyou: "You also might be interested in"
  },
  uservoices: {
    loadvoices: "Lade Teilnehmerstimmen"
  },
  eventoverview: {
    searchfortheme: "Search by topic: ",
    searchforformat: "Search by format: ",
    searchfordate: "Search by date: ",
    searchforreferent: "Search by speaker: ",
    searchforterms: "Search by terms: ",
    back: "Back",
    loadingevents: 'Loading events'
  },
  addtocart: {
    addtocart: "Add to cart",
    notonstock: "This product is not on stock.",
    tomanyproducts: "You want to add more products than we have in stock.",
    tooOld: "The Young Engineers Membership is only for people available not older than 35",
    tooYoung: "The Seniors Membership is only for people available not younger than 65",
    needbirthdate: "The birthdate ist required",
    cancelcondition: 'I have read and accept the <a href="/en/revocation">event cancel conditions</a>.',
  },
  addtomerkliste: {
    merken: "Bookmark",
    gemerkt: "Bookmarked",
    tomerkliste: "to bookmarks"
  },
  addtowatchlist: {
    added: 'Added to watchlist',
    add: 'Add to watchlist',
    towatchlist: 'to watchlist'
  },
  addroottowatchlist: {
    added: 'Added to watchlist',
    add: 'Add to watchlist',
    towatchlist: 'to watchlist'
  },
  cartmodal: {
    english: 'English',
    german: 'German',
    addedtocart: "Added to cart",
    product: "Product",
    price: "Price",
    amount: "Amount",
    total: "Total",
    incvat: "€ excl. VAT",
    gotocart: "Go to cart",
    shopmore: "Continue shopping",
    lastresult: "Back to last search"
  },
  cart: {
    sum: 'Sum',
    toCart: 'Visit cart',
    usercomment: 'Order details (optional) for further information or feedback',
    usercomment_read: 'Order details: ',
    invoiceAddress: 'Billing address / Recipient',
    differentInvoiceAddress: 'Different billing address',
    choose: 'Choose',
    openAddressBook: 'Open address book',
    closeAddressBook: 'Close address book',
    shippingAddress: 'Shipping address',
    differentShippingAddress: 'Different shipping address',
    choosePaymentMethod: 'Payment method',
    creditCard: 'Credit card',
    invoice: 'Purchase on account',
    noGuest: 'This payment option is not available for guest orders.',
    noInvoice: 'You are not able to purchase on account on your first order because your cart is over € 1000',
    purchaseGroup: "Since 1.1.2014 the Austrian federal government only accepts electronic invoices for the provision of goods and services."
  },
  eventcartmodal: {
    addparticipant: 'Add participant',
    salutation: 'Salutation',
    male: 'Mr',
    female: 'Ms',
    differentemail: 'E-Mails have to be unique.',
    cancel: 'Cancel',
    addtocart: 'Add to cart',
    cannotbeempty: 'cannot be empty.',
    participant: 'Participant',
    register: 'Register',
    close: "Close",
  },
  checkout: {
    rechnungsversand: "Delivery of invoice",
    electronicinvoice: "Electronic invoice",
    mailinvoice: "Paper invoice",
    einvoicetodepartment: "E-Invoice to federal government / other public sector institution",
    invoicerecipientmail: "Invoice recipient e-mail",
    post: "Mail",
    einkaufsgruppe: "Buyer group",
    termstitle: "Terms, Conditions and Privacy",
    tcmessage: "You must accept the terms and conditions to proceed.",
    pay: "Proceed to checkout",
    changeEmail: "Change invoice email address?",
    changeEmailText: "Would you like to change your invoice email address permanently?",
    changeEmailConfirm: "Yes",
    changeEmailCancel: "No",
    agbcheckbox: 'Yes, I have read and accepted the <a href="/agb" title="Terms and conditions" target="_blank">Terms and Conditions</a>, the <a href="/widerruf">revocation</a> and the <a href="/impressum/datenschutz" title="Privacy statement" target="_blank">privacy statement</a>.',
    download_norm: 'Ordering publications in download format is currently not possible with a guest status. Please <a :href="loginUrl">sign in</a> or <a :href="registerUrl">create an account</a>.',
    cart: {
      empty: "The cart is empty.",
      productsearch: "Go to product search",
      loadcart: "Loading cart",
      articledesc: "Item",
      amount: "Quantity",
      delete: "Remove",
      price: "Subtotal",
      netamount: "Net amount",
      vat: "VAT (10%)",
      vouchername: "Voucher name",
      voucher: "Voucher",
      rabatt: "Discount",
      shipping: "Shipping costs",
      inclvat: "(incl. VAT)",
      exclvat: "(excl. VAT)",
      total: "Total",
      vouchercode: "Voucher code",
      apply: "Apply",
      defaultTitle: "Your Cart",
      ordercodeoptional: "Customer order number (optional) (This information will be printed on the invoice)",
      ordercode: "Order code",
      ordercode_read: "Order code: ",
      termstitle: "Terms of service and privacy",
      unitprice: "Unit price"
    },
    userhandler: {
      backtoshop: "Continue shopping",
      buywithaccount: "Checkout with account",
      buyasguest: "Checkout as guest",
      login: "Login",
      register: "Register",
      next: "Next",
    }
  },
  login: {
    login: "Login"
  },
  guest: {
    contactdata: "Contact details",
    company: "Company",
    vatin: "VAT",
    salutation: "Salutation",
    firstname: "First name",
    lastname: "Surname",
    street: "Street name and house number",
    zip: "Postcode",
    city: "City",
    country: "Country",
    confirm_email: "Confirm e-mail",
    next: "Continue",
  },
  register: {
    personal: "Personal",
    company: "Company",
    contactperson: "Contact person",
    zuhaenden: "First- and lastname / Department",
    vatin: "VAT number",
    vatnumber: "VAT number",
    errorVatin: "The VAT number is not valid",
    vatinfo: "If you have a VAT number, please provide it here (e.g. DE123456789). If you do not provide a VAT number, taxes will be added to your invoice if you order from within the EU.",
    salutation: "Salutation",
    herr: "Mr.",
    frau: "Ms.",
    knr: "Customer number",
    title: "Title",
    titlefollowing: "Title (following)",
    firstname: "First name",
    surname: "Surname",
    street: "Street name and house number",
    phone: "Phone number",
    plz: "Postcode",
    city: "City",
    country: "Country",
    birthDate: "Birth date",
    email: "E-Mail",
    email_username: "E-Mail / Username",
    confirmemail: "Confirm e-mail",
    password: "Password",
    passwordinformation: "The password needs to contain at least 6 characters, upper and lowercase characters, a digit and a special character.",
    confirmpassword: "Confirm password",
    privacycheckbox: 'I have read and accept the <a target="_blank" href="https://www.ove.at/imprint/privacy-policy">privacy statement</a>',
    usererror: 'To buy a membership you need to login with a OVE customer portal. Please log in or register here.',
    usereventerror: 'To book this event you need to login with a OVE customer portal. Please log in or register here.',
  },
  searchquery: {
    header: 'Category',
    noresults: "No results found.",
    topproducts: "Top products",
    search: "Search",
    show: "Show",
    all: "All",
    loadingcategories: "Loading categories.",
    loadingproducts: "Loading products.",
    normsearch: "Advanced search standards",
    doctype: "Publication type",
    valid: "Valid",
    invalid: "Superseded / Withdrawn",
    language: "Language",
    publisher: "Publisher",
    supplier: "Publisher",
    specialfieldics: "ICS Code",
    issuedatefrom: "Publication date from",
    issuedateto: "Publication date to",
    resetfilters: "Reset filters",
    applyfilters: "Apply filters",
    loadingsuggestions: "Loading suggestions",
    nosuggestions: "No suggestions found.",
    searchplaceholder: "Search for products",
    searchfor: 'Search for <strong>&quot;:value&quot;</strong>',
    membership: 'Membership',
    technicalliterature: 'Advanced search technical literature',
    blackboards: 'Advanced search miscellaneous publications',
    blackboardtext: 'Safety notices provide information on safety regulations and behaviour during an emergency or hazardous situation. For the operation of electrical systems in accordance with ÖVE/ÖNORM EN 50110-1, operators of electrical systems are recommended to use the following OVE standards: <ul><li>OVE E 8350:2017-12 Fire fighting in and near electrical installations </li><li>OVE E 8351:2016-06 First aid in the event of accidents involving electricity</li></ul>In order to better communicate the content of these standards to the persons concerned, OVE has issued safety notice boards for display in electrical operating facilities.',
    loadingresults: 'Loading results.',
    status: 'Status',
    reset: 'Reset search'
  },
  searchresult: {
    foundresults: "results found.",
    loadingresults: "Loading results.",
    pricevat: '* (excl. VAT, <a href="/en/shipping" target="_blank">excl. Shipping</a>)',
    resultsperpage: "Results per page"
  },
  breadcrumbprofile: {
    loggedin1: "You are logged in as",
    loggedin2: ".",
    notloggedin1: "Not logged in.",
    notloggedin2: "Click here to log in.",
    loggedout: "You are not logged in."
  },
  shopsearchresults: {
    loading: "Searching products..."
  },
  searchproductamount: {
    productsfound: "products found."
  },
  eventlist: {
    noeventsavailable: 'No events available.',
    title: 'OVE ACADEMY NEWS',
  },
  membershipaddtocart: {
    addtocart: 'Add to cart'
  },
  membershipcartmodal: {
    modaltitle: 'Sign-up ',
    areaofexpertise: 'Area of expertise',
    femove: 'femOVE',
    magazine: 'e&i registration',
    magazineText: 'Yes, I would like to receive the association magazine e&i',
    femovenetwork: 'I want to be part of the femOVE network',
    infoviamail: 'Information per E-Mail',
    infoviamailtext: 'I agree to receiving OVE related information via e-mail.',
    heardabout: 'Heard about OVE via',
    ieee_membership: 'IEEE Membership',
    company: 'Employed at',
    yes: 'yes',
    no: 'no',
    futureinformation: 'Send future information to this address',
    homeaddress: 'Home address',
    workaddress: 'Work address',
    digitallysignedinvoices: 'Send digitally signed invoices via email',
    digitallysignedinvoicestext: 'Please send me digitally signed invoice PDFs via e-mail',
    cancel: 'Cancel',
    addtocart: 'Add to cart',
    filledout: 'have to be filled out.',
    filledoutOne: 'has to be filled out.',
    text: 'Für Zusendungen ins Ausland werden zusätzlich Spesen von 15,00 € verrechnet. Mahn- und Inkasso Gebühren werden gesondert verrechnet. Der Gerichtsstand ist Wien. Mit meiner Unterschrift erkläre ich mich einverstanden, OVE-Mitteilungen auch auf elektronischem Weg (E-Mail) zu erhalten. Laut § 22 des Datenschutzgesetzes informiert Sie der Österreichische Verband für Elektrotechnik, Eschenbachgasse 9, 1010 Wien, darüber, dass Ihre Kontaktdaten für Zwecke des OVE‘s gespeichert werden. Die Daten werden nicht an externe Dritte vergeben. Studenten/innen eines einschlägigen Erststudiums erhalten bis zur Vollendendung des 30. Lebensjahres den reduzierten Mitgliedsbeitrag nur, wenn jedes Jahr dem OVE eine aktuelle Studienbestätigung vorgelegt wird.',
    statuten: 'Mit dem Versand des Formulars erkläre ich meinen Beitritt zum OVE und bestätige, die Statuten des OVE, im Falle einer Mitgliedschaft als GMAR Mitglied auch die Statuten der GMAR, gelesen zu haben und diesen zuzustimmen.',
    statutentitle: 'Statutes'
  },
  passwordchange: {
    success: 'Password has been changed.',
    oldpassword: 'Old password',
    newpassword: 'New password',
    confirmnewpassword: 'Confirm new password',
    save: 'Save'
  },
  searchfilters: {
    categories: 'Categories',
    allcategories: 'All categories',
    normsearch: 'Norm search',
    documenttype: 'Publication type',
    all: 'All',
    status: 'Status',
    valid: 'Current',
    replaced: 'Replaced',
    language: 'Language',
    ics: 'Area of Expertise (ICS-Nr.)',
    publishfrom: 'Publication date from (date)',
    publishto: 'Publication date to (date)',
    applyfilter: 'Apply filter',
    resetfilter: 'Reset filter',
    eventsearch: 'Event search',
    eventfrom: 'From (date)',
    eventto: 'To (date)',
    format: 'Format',
    allformats: 'All formats',
    seminar: 'Seminar',
    tagung: 'Conference',
    termin: 'Termin',
    vortrag: 'Lecture',
    workshop: 'Workshop',
    topic: 'Topic',
    alltopics: 'All topics',
    topicelektrotechnik: 'Electrical engineering',
    topicbau: 'Construction',
    topicautomatisierung: 'Automation',
    topicenergie: 'Energy',
    topicindustrie: 'Industry 4.0',
    topicitsecurity: 'IT-Security & Privacy',
    topicpersonality: 'Personality/Executives',
    topicsecurity: 'Security technology',
    topicother: 'Other'
  },
  sponsors: {
    loading: 'Sponsoren are loading',
    learnmore: 'Learn more'
  },
  subscriptions: {
    loading: 'Products are loading',
    join: 'Learn more and join'
  },
  wirecard: {
    creditcard: 'Credit card',
    cardholdername: 'Card owner',
    cardnumber: 'Card number',
    expirationmonth: 'Expiration month',
    expirationyear: 'Expiration year',
    ccv: 'CCV',
    ordernow: 'Order now'
  },
  categories: {
    category_15: 'Standards / Technical Specifications',
    category_13: 'Technical literature',
    category_14: 'Miscellaneous publications',
    category_11: 'Seminars / Events',
    category_1: 'Membership',
    showall: 'Show all'
  }
}