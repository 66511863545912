'use strict';

import Sponsors from './components/Sponsors.vue';

export default class {

    constructor() {

        Vue.component('ove-sponsors', Sponsors);
    }
}