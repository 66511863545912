<template>
    <div class="credit-card-form">
        <div class="row">
            <div class="col-sm-12">
                <div class="blackBar">
                    <div class="title">{{$t("wirecard.creditcard", null, locale)}} <i v-if="!dataStorage" class="fa fa-spin fa-spinner"></i></div>
                </div>
            </div>
        </div>
        <div class="backgroundGray">
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="paymentInformation.cardholdername" name="cardHolderName" :placeholder="$t('wirecard.cardholdername', null, locale)" maxlength="30" id="cardHolderName">                        
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <input type="text" class="form-control" v-mask="'#### #### #### ####'" v-model="paymentInformation.pan" name="pan" :placeholder="$t('wirecard.cardnumber', null, locale) +' 0000-0000-0000-0000'" maxlength="19" id="pan">
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-mask="'##'" v-model="paymentInformation.expirationMonth" name="expirationMonth" :placeholder="$t('wirecard.expirationmonth', null, locale) + ' 00'" maxlength="2" id="expirationMonth">
                    </div>                    
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <input type="text" class="form-control" v-mask="'####'" v-model="paymentInformation.expirationYear" name="expirationYear" :placeholder="$t('wirecard.expirationyear', null, locale) + ' 0000'" maxlength="4" id="expirationYear">
                    </div>                    
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <input type="text" class="form-control" v-mask="'####'" v-model="paymentInformation.cardverifycode" name="cardVerifyCode" :placeholder="$t('wirecard.ccv', null, locale)"  maxlength="4" id="cardVerifyCode">
                    </div>                    
                </div>
            </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <section id="agbaccept">
              <div class="row">
                <div class="col-sm-12 blackBar">
                  <div class="title">{{$t("checkout.cart.termstitle", null, locale)}}</div>
                </div>
              </div>
              <div class="row backgroundGray">
                <div class="col-sm-12 checkbox">
                  <label>
                    <input type="checkbox" id="agbcheckbox" name="agbcheckbox" value="agb-accepted" v-model="agb">
                    <span v-html="$t('checkout.agbcheckbox', null, locale)"></span>
                  </label>
                </div>
                <div class="col-sm-12 agb-error" v-if="!agb && submitted">
                  {{$t("checkout.tcmessage", null, locale)}}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="row">
            <div class="col-sm-6 mb-2 mb-md-0">
              <a :href="'/shop/'+ locale + '/cart'" class="btn btn-primary">Zurück</a>
            </div>
            <div class="col-sm-6 text-md-right">
                <button @click.prevent="submit" class="btn btn-primary" :disabled="submitted">
                  {{$t("wirecard.ordernow", null, locale)}}
                </button>
            </div>
        </div>        
    </div>
</template>

<script>
import Promise from 'promise';

export default {

    props: {
      javascriptUrl: {
        type: [String],
        required: true
      },
      locale: {
        type: String,
        default: 'de'
      }
    },

    data() {
      return {
        dataStorage: null,
        paymentInformation: {
            pan: '',
            expirationMonth: null,
            expirationYear: null,
            cardholdername: null,
            cardverifycode: null
        },
        agb: false,
        submitted: false
      }
    },

    mounted() {

        this.loadScript(this.javascriptUrl).then(() => {
            this.dataStorage = new WirecardCEE_DataStorage();
        });

    },

    methods: {

        loadScript(url) {
            return new Promise((resolve, reject) => {
                var script = document.createElement('script');

                document.body.appendChild(script);

                script.onload = () => resolve();
                script.src = url;
            });
        },

        dataStorageHandler(response) {

            if (response.getStatus() == 0) {
                this.$emit('submit');
            } else {
                var errors = response.getErrors();
                var error = errors.shift();

                swal({
                    html:  true,
                    type:  'error',
                    title: 'Fehler',
                    text: error.consumerMessage,
                });
            }

        },

        submit() {
          
            // return this.agb;
            if (!this.agb) {
                return false;
            }

            this.submitted = true;

            if (this.dataStorage) {

                let paymentInformation = Object.assign({}, this.paymentInformation);
                    paymentInformation.pan = paymentInformation.pan.replace(/ /g, '');

                this.dataStorage.storeCreditCardInformation(
                    paymentInformation,
                    this.dataStorageHandler
                );

            }
        },

    }
}
</script>
