<template>
    <span v-if="loggedin" class="logininfo">{{$t("breadcrumbprofile.loggedin1", null, locale)}} <a href="/shop/profile/">{{ username }}</a> {{$t("breadcrumbprofile.loggedin2", null, locale)}}</span>
   
</template>
<script>
export default {
    data() {
        return {
            loggedin: false,
            username: null
        }
    },
    
    props: {
      locale: {
        type: String,
        default: 'de'
      }
    },

    methods: {
        showSidebar() {
            $('.sidebars .backdrop').addClass('open')
            $('.sidebars .login-sidebar').addClass('open')
            $('body').addClass('modal-open')
        },
    },

    created() {
        App.event.listen('profile.loaded', (response) => {
            if (response)  {
                this.loggedin = true
                this.username = response.username
            }
        })

        App.event.listen('login.success', (response) => {
            if (response) this.loggedin = true
        })
    }
}
</script>
