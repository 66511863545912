import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

import createFilters from './modules/filters'

Vue.use(Vuex)

export default (data) => {
  return new Vuex.Store({
    strict: true,
    state: {
      //
    },
    modules: {
      filters: createFilters(data.filters || {})
    },
    plugins: [createPersistedState({
      paths: [
        // checkout
        'Checkout.invoiceDelivery',

        // invoice address
        'Checkout.InvoiceAddress.address',
        'Checkout.InvoiceAddress.selectedAddress',
        'Checkout.InvoiceAddress.useCustomAddress',

        // shipping address
        'Checkout.ShippingAddress.address',
        'Checkout.ShippingAddress.selectedAddress',
        'Checkout.ShippingAddress.useCustomAddress',
      ]}
    )]
  })
}