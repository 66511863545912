'use strict';

import CartNumItems from './components/CartNumItems.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('cart-num-items', CartNumItems);

    }
}