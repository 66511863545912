<template>
    <section id="payment-types">
        <div class="row">
            <div class="col-sm-12 blackBar"><div class="title">{{$t("cart.choosePaymentMethod", null, locale)}}</div></div>
        </div>
        <div class="row backgroundGray">
            <div class="col-sm-6" v-if="!hasWaitingListEvent && ! hasNonTargungEvent">
                <div class="checkbox">
                    <label>
                        <input type="radio" name="payment" value="1" v-model="paymentType">
                        <span>
                            {{$t("cart.creditCard", null, locale)}} <img src="/fileadmin/newtemplate/resources/images/icon-mastercard.png"/><img src="/fileadmin/newtemplate/resources/images/icon-visa.png"/>
                        </span>
                    </label>
                </div>
            </div>
            <div class="col-sm-6" v-if="user != null"  >
                <div class="checkbox">
                    <label>
                        <input type="radio" v-model="paymentType" :disabled="! user.id || ( ! hasOrders && overBillLimit)" name="payment" value="2">
                        <span>{{$t("cart.invoice", null, locale)}}</span>
                    </label>
                  <p v-if=" ! user.id">{{$t("cart.noGuest", null, locale)}}</p>
                  <p v-if="user.id && ! hasOrders && overBillLimit ">{{$t("cart.noInvoice", null, locale)}}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        hasWaitingListEvent: {
            default: false
        },
        hasNonTargungEvent: {
          default: false  
        },
        locale: {
          type: String,
          default: 'de'
        },
        user: {
          default: null
        },
        hydrationData: {
            type: Object,
            default: function () {
                return {};
            }
        },
        hasOrders: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            overBillLimit: false ,
            paymentType: this.hydrationData.payment ? this.hydrationData.payment : (
              !this.hasWaitingListEvent && ! this.hasNonTargungEvent ? 1 : 2
            )
        };
    },
    created() {
        App.event.listen('cart.data.loaded', (response) => {
          this.overBillLimit = false;
        });

        App.event.listen('cart.updated', (response) => {
            this.overBillLimit = false;
        });
    }
}
</script>
