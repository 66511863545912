<template>
    <div class="event-overview-filters">
        <template v-if="initialized">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col" v-html="topcontent">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="oveacademyfilters">
                                <h2>{{$t('eventsearch.header', null, this.locale)}}</h2>
                                <ul>
                                    <li><a @click="setFilter('themen')">{{$t('eventsearch.nachfachthemen', null, this.locale)}}</a></li>
                                    <li><a @click="setFilter('formate')">{{$t('eventsearch.nachformaten', null, this.locale)}}</a></li>
                                    <li><a @click="setFilter('datum')">{{$t('eventsearch.nachdatum', null, this.locale)}}</a></li>
                                    <li><a @click="setFilter('referenten')">{{$t('eventsearch.nachreferenten', null, this.locale)}}</a></li>
                                    <li><a @click="setFilter('terms')">{{$t('eventsearch.nachschlagworten', null, this.locale)}}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <template v-if="filtersLoaded">
                        <template v-if="loadedFilter === ''">
                            <p>{{$t('eventsearch.selectfilter', null, this.locale)}}</p>
                        </template>
                        <template v-if="loadedFilter === 'themen'">
                            <div class="filterlist">
                                <h3>{{$t('eventsearch.unserefachthemen', null, this.locale)}}</h3>
                                <ul>
                                    <li v-for="thema in themen" :key="thema.id">
                                        <a :href="searchUrl + '?thema='+thema.id+'&value='+thema.name">{{thema.name}}</a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template v-if="loadedFilter === 'formate'">
                            <div class="filterlist">
                                <h3>{{$t('eventsearch.unsereformate', null, this.locale)}}</h3>
                                <ul>
                                    <li v-for="format in formate" :key="format.id">
                                        <a :href="searchUrl + '?format='+format.id+'&value='+format.name">{{format.name}}</a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template v-if="loadedFilter === 'datum'">
                            <FunctionalCalendar
                                v-model="calendarData"
                                :sundayStart="false"
                                :dateFormat="'yyyy-mm-dd'"
                                :is-date-picker="false"
                                :is-date-range="true"
                                :apply-stylesheet="true"
                            ></FunctionalCalendar>
                        </template>
                        <template v-if="loadedFilter === 'referenten'">
                            <div class="filterlist">
                                <h3>{{$t('eventsearch.unserereferenten', null, this.locale)}}</h3>
                                <ul>
                                    <li v-for="referent in referenten" :key="referent.id">
                                        <a :href="searchUrl + '?referent='+referent.id+'&value='+referent.name">{{referent.name}}</a>
                                    </li>
                                </ul>
                            </div>
                        </template>
                        <template v-if="loadedFilter === 'terms'">
                            <div class="filterlist">
                                <h3>{{$t('eventsearch.unsereschlagworten', null, this.locale)}}</h3>
                                <form @submit.prevent="submitSearchterms()">
                                    <div class="input-group">
                                        <input type="text" class="form-control" v-model="searchterms">
                                        <div class="input-group-append">
                                            <button type="submit" class="btn btn-primary"><i class="fas fa-search"></i> {{$t('eventsearch.suchen', null, this.locale)}}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </template>
                    </template>
                    <loading-animation :text="$t('eventsearch.loadevents', null, this.locale)" v-else/>
                </div>
            </div>
        </template>
        <template v-else>
            <loading-animation :text="$t('eventsearch.loadevents', null, this.locale)"/>
        </template>
    </div>
</template>
<script>
import LoadingAnimation from './LoadingAnimation'
// import Datepicker from 'vuejs-datepicker'
// import {en, de} from 'vuejs-datepicker/dist/locale'
import {FunctionalCalendar} from 'vue-functional-calendar';
import moment from 'moment'

export default {
    components: {
        LoadingAnimation,
        // Datepicker
        FunctionalCalendar
    },

    props: {
      locale: {
        type: String,
        default: 'de'
      }
    },

    data() {
        return {
            // en: en,
            // de: de,
            searchUrl: '/oveacademy/veranstaltungskalender/',
            initialized: false,
            filtersLoaded: false,
            results: '',
            topcontent: '',
            loadedFilter: 'themen',
            themen: [],
            formate: [],
            referenten: [],
            searchterms: '',
            trueValue: true,
            calendarData: {}
        }
    },
    methods: {
        async loadEvents() {
            this.topcontent = $('#t3-content').html()
        },

        setFilter(value) {
            this.filtersLoaded = true
            this.loadedFilter = value
        },

        async fetchFilters() {
            let response = await new Form().get('/api/eventfilters')
            this.themen = response.themen
            this.formate = response.formate
            this.referenten = response.referenten
            this.filtersLoaded = true
        },
        customFormatter(date) {
            moment.locale('de')
            return moment(date).format('Do MMMM YYYY')
        },
        submitSearchterms() {
            let terms = encodeURIComponent(this.searchterms);
            window.location.href = this.searchUrl + '?terms=' + terms
        }
    },
    mounted() {
        this.loadEvents()
        this.fetchFilters()
        this.initialized = true
    },
    watch: {
        calendarData: {
          handler: function(data) {
            if (data.dateRange &&
                data.dateRange.start.date &&
                data.dateRange.end.date) {
                  window.location.href = this.searchUrl + '?start='+data.dateRange.start.date+'&end='+data.dateRange.end.date
            }
          },
          deep: true
        }
    }
}
</script>
<style>
    .btn.btn-primary {
        /*background-color: #7885a6;
        border-color: #7885a6;
        padding: 0px .875rem 0;
        height: 2rem;*/
    }

    .input-group>.form-control {
        height: 2rem;
    }

    .oveacademyfilters a {
        display: block;
    }
</style>
