'use strict';

import Spinner from './components/Spinner.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('spinner', Spinner);

    }
}
