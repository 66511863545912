<template>
  <div id="membership-cart-modal" v-if="product">
    <div :class="modalClass" tabindex="-1" role="dialog" style="display: block">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="submit">
          <div class="modal-header">
            <h4 class="modal-title">{{$t("membershipcartmodal.modaltitle", null, locale) + title }}</h4>
            <button type="button" class="close" @click="close">
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            
            <div class="row">
              <div class="form-group col-md-6">
                <label for="salutation">{{$t("register.salutation", null, locale)}} *</label>
                <select class="form-control" v-model="user.salutation" name="" id="salutation" required>
                  <option value="Herr">{{$t("register.herr", null, locale)}}</option>
                  <option value="Frau">{{$t("register.frau", null, locale)}}</option>
                </select>
              </div>

              <div class="form-group col-md-6">
                <label for="title">{{$t("register.title", null, locale)}}</label>
                <input type="text" class="form-control" v-model="user.title" name="" id="title" :placeholder="$t('register.title', null, locale)">
              </div>

              <div class="form-group col-md-6">
                <label for="firstname">{{$t("register.firstname", null, locale)}} *</label>
                <input type="text" class="form-control" v-model="user.firstName" name="" id="firstname" required :placeholder="$t('register.firstname', null, locale)">
              </div>
              <div class="form-group col-md-6">
                <label for="lastname">{{$t("register.surname", null, locale)}} *</label>
                <input type="text" class="form-control" v-model="user.lastName" name="" id="lastname" required :placeholder="$t('register.surname', null, locale)">
              </div>

              <div class="form-group col-md-6">
                <label for="email">{{$t("register.email", null, locale)}} *</label>
                <input readonly type="email" class="form-control" v-model="user.mail" name="" id="email" required :placeholder="$t('register.email', null, locale)">
              </div>
              <div class="form-group col-md-6">
                <label for="street">{{$t("register.street", null, locale)}} *</label>
                <input type="text" class="form-control" v-model="user.street" required name="" id="street" :placeholder="$t('register.street', null, locale)">
              </div>

              <div class="form-group col-md-6">
                <label for="zip">{{$t("register.plz", null, locale)}} *</label>
                <input type="text" class="form-control" v-model="user.zip" name="" id="zip" :placeholder="$t('register.plz', null, locale)" required>
              </div>
              <div class="form-group col-md-6">
                <label for="city">{{$t("register.city", null, locale)}} *</label>
                <input type="text" class="form-control" v-model="user.city" required name="" id="city" :placeholder="$t('register.city', null, locale)">
              </div>

              <div class="form-group col-md-6">
                <label for="exampleInputEmail1">{{$t("register.country", null, locale)}} *</label>
                <select class="form-control" v-model="user.country" name="" id="exampleInputEmail1">
                  <option value="156">Österreich</option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label >{{$t("register.birthDate", null, locale)}} *</label>
                
                
<!--                <date-pick-->
<!--                  v-model="user.birthDate"-->
<!--                  :weekdays="getCalendarWeekdays"-->
<!--                  :months="getCalendarMonths"-->
<!--                  :displayFormat="datePickOptions.displayFormat"-->
<!--                  :selectableYearRange="100"></date-pick>-->

                <date-picker 
                  v-model="user.birthDate" 
                  valueType="format"
                  :lang="lang"
                  input-class="form-control"
                ></date-picker>
                
              </div>

              <div class="form-group col-md-6">
                <label for="company">{{$t("membershipcartmodal.company", null, locale)}}</label>
                <input type="text" class="form-control" v-model="user.company" name="" id="company" placeholder="">
              </div>

              <div class="form-group col-md-6">
                <label for="vatin">{{$t("register.vatin", null, locale)}}</label>
                <input type="text" class="form-control" v-model="user.vatin" name="" id="vatin" :placeholder="$t('register.vatin', null, locale)" >
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">

                <div class="form-group">
                  <p>{{$t("membershipcartmodal.areaofexpertise", null, locale)}}</p>
                    <div class="form-check" :key="index" v-for="( society, index) in societies">
                      <input class="form-check-input" type="radio" name="society" :value="index" :id="'society_' + index" v-model="options.society">
                      <label class="form-check-label" :for="'society_' + index ">
                        {{ society }}
                      </label>
                    </div>
                </div>

                <div class="form-group">
                  <p>{{$t("membershipcartmodal.femove", null, locale)}}</p>

                  <div class="form-check">
                    <input class="form-check-input" id="fem_ove" type="checkbox" v-model="options.femOve" value="1" name="fem_ove">
                    <label class="form-check-label" for="fem_ove">{{$t("membershipcartmodal.femovenetwork", null, locale)}}</label>
                  </div>
                </div>

                <div class="form-group">
                  <p>{{$t("membershipcartmodal.magazine", null, locale)}}</p>

                  <div class="form-check">
                    <input class="form-check-input" id="magazine" type="checkbox" v-model="options.magazine" checked="checked" value="1" name="magazine">
                    <label class="form-check-label" for="magazine">{{$t("membershipcartmodal.magazineText", null, locale)}}</label>
                  </div>
                </div>

                <div class="form-group">
                  <p>{{$t("membershipcartmodal.infoviamail", null, locale)}}</p>
                  <div class="form-check">
                    <input class="form-check-input" id="receive_information" type="checkbox" v-model="options.receiveInformation" value="1"
                           name="receive_information">
                    <label class="form-check-label" for="receive_information">
                      {{$t("membershipcartmodal.infoviamailtext", null, locale)}}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <label for="heard_about">{{$t("membershipcartmodal.heardabout", null, locale)}}</label>
                  <input class="form-control" id="heard_about" v-model="options.heard_about" type="text" value="" name="heard_about">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <p>{{$t("membershipcartmodal.ieee_membership", null, locale)}}</p>
                  <div class="form-check">
                    <input class="form-check-input" id="ieee_yes" v-model="options.ieee" type="radio" value="1" name="ieee">
                    <label class="form-check-label" for="ieee_yes">
                      {{$t("membershipcartmodal.yes", null, locale)}}
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" id="ieee_no" v-model="options.ieee" type="radio" value="0" name="ieee" checked>
                    <label class="form-check-label" for="ieee_no">
                      {{$t("membershipcartmodal.no", null, locale)}}
                    </label>
                  </div>
                </div>

                <div class="form-group">
                  <p>{{$t("membershipcartmodal.futureinformation", null, locale)}}</p>
                  <div class="form-check">
                    <input class="form-check-input" id="news_private" v-model="options.news_address" type="radio" value="1" name="news_address">
                    <label class="form-check-label" for="news_private">{{$t("membershipcartmodal.homeaddress", null, locale)}}</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" id="news_company" v-model="options.news_address" type="radio" value="2" name="news_address">
                    <label class="form-check-label" for="news_company">{{$t("membershipcartmodal.workaddress", null, locale)}}</label>
                  </div>
                </div>

                <div class="form-group">
                  <p>{{$t("membershipcartmodal.statutentitle", null, locale)}} *</p>
                  <div class="form-check">
                    <input class="form-check-input" id="statuten" type="checkbox" v-model="options.statutes" value="1" name="statutes" required>
                    <label class="form-check-label" for="statuten">{{$t("membershipcartmodal.statuten", null, locale)}}</label>
                    <div>
                      <a target="_blank" href="https://www.ove.at/fileadmin/user_upload/Verband/Statuten.pdf">{{$t("membershipcartmodal.statutentitle", null, locale)}} OVE</a><br>
                      <a target="_blank" href="https://www.ove.at/fileadmin/user_upload/Verband/statuten_gmar.pdf">{{$t("membershipcartmodal.statutentitle", null, locale)}} GMAR</a>
                    </div>
                  </div>
                </div>
                
                <p>{{ $t('membershipcartmodal.text', null, locale) }}</p>
              </div>
            </div>

            <div class="error-message text-center text-danger" v-if="errors.length">
              {{errorStrings}}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" @click="close">
              {{$t("membershipcartmodal.cancel", null, locale)}}
            </button> &nbsp;
            <button type="submit" class="btn btn-default btn-primary"><i v-if="loading" class="fa fa-spin fa-spinner"></i>
              {{$t("membershipcartmodal.addtocart", null, locale)}}
            </button>
          </div>
          </form>
        </div>
      </div>
    </div>
    <div :class="backdropClass"></div>
  </div>
</template>
<style>
  .mx-datepicker {
    display:block;
  }
</style>
<script>
    // import DatePick from 'vue-date-pick';
    // import 'vue-date-pick/dist/vueDatePick.css';

    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/de';

    import moment from 'moment';
    
  export default {
    components: {
        // DatePick,
      DatePicker,
    },
    props: {
      locale: {
          type: String,
          default: 'de'
      }, 
      title: {
        type: String
      }
    },
    data() {
      return {
        product: null,
        user: null,
        societies: [],
        loading: false,
        validated: false,
        valid: false,
        errors: [],
        options: {
          society: "1",
          ieee: "0",
          statutes: false,
          femOve: false, 
          magazine: true,
          news_address: 1,
          receiveInformation: true,
          heard_about: ""
        },
        mondayFirst: true,
        // calendarOptions: {
        //     mondayFirst: true,
        //     fullMonthName: false,
        //     calendarButton: true,
        //     clearButton: true,
        //     c: true,
        //     locale: {
        //         days: {
        //             de: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
        //             en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
        //         },
        //         months: {
        //             de: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        //             en: ['Jannuary', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        //         }
        //     }
        // },
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: true,
        },
        datePickOptions: {
            displayFormat: 'YYYY-MM-DD',
        },
      }
    },

    created() {

      new Form().get('/api/profile').then((response) => {
        this.options = response.options
        this.user = response.user

        if (this.options.femOve == null) {
          this.options.femOve = false;
        }
          if (this.options.magazine == null) {
              this.options.magazine = true;
          }
        if (this.options.heard_about == null) {
          this.options.heard_about = "";
        }
        if (this.options.ieee == null) {
          this.options.ieee = "0";
        }
        if (this.options.news_address == null) {
          this.options.news_address = "1";
        }
        if (this.options.receiveInformation == null) {
          this.options.receiveInformation = false;
        }
        if (this.options.society == null) {
          this.options.society = "1";
        }
      })

      new Form().get('/api/societies').then((response) => {
        this.societies = response
      })

      App.event.listen('profile.loaded', (user) => {
        this.user = user
      })

      App.event.listen('cart.membership.inserted', (product) => {
          this.product = product;
          
          if(this.user == null){
            let d = new Date();
            d.setTime(d.getTime() + (4*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = "register_back_product=" + this.product.pseo + ";" + expires + ";path=/";
            $('.sidebars .backdrop').addClass('open')
            $('.sidebars .login-sidebar').addClass('open')
            $('body').addClass('modal-open')
            $('.login-form p').first().replaceWith("<div class=\"help-block\"><strong>" + this.$t("register.usererror", null, this.locale) + "</strong></div>");
          } else{
              document.cookie = "register_back_product=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/"
          }
   
      })

    },

    methods: {
      close() {
        this.product = null;
      },

      isValid() {
          this.errors = [];
          
          if(this.product.pid == 8){
              if(this.user.birthDate == null || this.user.birthDate === '' || (new Date().getFullYear() - new Date(this.user.birthDate).getFullYear()) > 35){
                  this.errors.push(this.$t("register.birthDate", null, this.locale));
                  this.user.birthDate = null;
                  swal({
                      html:true,
                      title:'',
                      text: this.$t("addtocart.tooOld", null, this.locale),
                      type: "error"
                  });
              }
          } else if(this.product.pid == 9){
              if(this.user.birthDate == null || this.user.birthDate === '' || (new Date().getFullYear() - new Date(this.user.birthDate).getFullYear()) < 65) {
                  this.errors.push(this.$t("register.birthDate", null, this.locale));
                  this.user.birthDate = null;
                  swal({
                      html:true,
                      title:'',
                      text: this.$t("addtocart.tooYoung", null, this.locale),
                      type: "error"
                  });
              }
          } else{
              if(this.user.birthDate == null || this.user.birthDate === ''){
                  this.errors.push(this.$t("register.birthDate", null, this.locale));
                  this.user.birthDate = null;
                  swal({
                      html:true,
                      title:'',
                      text: this.$t("addtocart.needbirthdate", null, this.locale),
                      type: "error"
                  });
              }
          }

        return (this.errors.length <= 0)
      },

      contains(a, obj) {
        var i = a.length;
        while (i--) {
          if (a[i] === obj) {
            return true;
          }
        }
        return false;
      },

      customFormatter(date) {
          moment.locale('de')
          return moment(date).format('Do MMMM YYYY')
      },
      
      submit() {
        this.loading = true;

        if (this.isValid()) {
          new Form({
            user: {
              salutation: this.user.salutation,
              name: this.user.lastName,
              firstName: this.user.firstName,
              street: this.user.street,
              postalCode: this.user.zip,
              city: this.user.city,
              birthDate: this.user.birthDate,
              country: this.user.country,
              firm: this.user.company,
              vatin: this.user.vatin,
              title: this.user.title,
              mail: this.user.mail,
            },
            options: this.options
          })
            .put('/api/profile')
            .then((response) => {
              new Form({
                pid: this.product.pid,
                qty: 1,
                pstock: this.product.pstock,
                options: {}
              })
                .post('/api/cart/add')
                .then((response) => {
                  this.loading = false;
                  if (response.success) {
                    App.event.fire('cart.item.inserted', response.item) 
                 //   App.event.fire('cart.updated', response.data) // deactivated

                    this.product = null
                  } else {
                    swal("", this.$t("addtocart.tomanyproducts", null, this.locale), "error")
                  }
                }).catch(errors => {
                this.loading = false;
              });
            }).catch(errors => {
            this.loading = false;
          });
        }
      }
    },

    computed: {
      url() {
        return App.url + '/cart';
      },

      modalClass() {
        return this.product ? 'modal fade show' : 'modal fade';
      },

      backdropClass() {
        return this.product ? 'modal-backdrop fade show' : 'modal-backdrop fade';
      },

      selectedOption() {
        return this.options;
      },

      errorStrings() {
          console.log(this.errors);
          if(this.errors.length > 1) {
              return this.errors.join(', ') + ' ' + this.$t("membershipcartmodal.filledout", null, this.locale)
          } 
          return this.errors.join(', ') + ' ' +  this.$t("membershipcartmodal.filledoutOne", null, this.locale)
      },

        getCalendarLang() {
            if (this.locale == 'en') {
                return this.en
            }
            return this.de
        },

      getCalendarWeekdays() {
          if (this.locale == 'en') {
              return this.calendarOptions.locale.days.en;
          }
          return this.calendarOptions.locale.days.de;
      },
      getCalendarMonths() {
          if (this.locale == 'en') {
              return this.calendarOptions.locale.months.en;
          }
          return this.calendarOptions.locale.months.de;
      }

    }

  }
</script>