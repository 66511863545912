<template>
  <div style="margin-top: 5px;">
    <button class="btn btn-primary" @click="submit">
      <i v-if="loading" class="fa fa-spin fa-spinner fa-fw"></i>
      <i v-else-if="!checked" class="fa fa-list fa-fw" aria-hidden="true"></i>
      <i v-else class="fa fa-check fa-fw" aria-hidden="true"></i>
      <slot name="name"><span v-if="checked">{{$t("addroottowatchlist.added", null, locale)}}</span><span
        v-else>{{$t("addroottowatchlist.add", null, locale)}}</span>
      </slot>
    </button>
    <a v-if="checked" class="btn btn-primary" :href="url"><i class="fa fa-list"></i> {{$t("addroottowatchlist.towatchlist", null, locale)}}</a>
  </div>
</template>
<script>
  export default {
    props: {
      root: {
        type: [String, Number],
        required: true,
        default: null
      },
      locale: {
        type: String,
        default: 'de'
      }
    },

    computed: {
      url() {
        return App.url + '/profile/norm-watchlist';
      },
    },

    data() {
      return {
        loading: false,
        checked: false,
        rowId: 0,

      }
    },

    created() {
      new Form({
        root: this.root
      })
        .post('/api/watchlist/inlist')
        .then((response) => {
          if (response.success) {
            this.checked = true;
            this.rowId = response.rowId;
          }
        })
    },

    methods: {

      showSidebar() {
        $('.sidebars .backdrop').addClass('open')
        $('.sidebars .login-sidebar').addClass('open')
        $('body').addClass('modal-open')
      },

      submit() {
        this.loading = true;
        if (this.checked == false) {
          new Form({
            root: this.root
          })
            .post('/api/watchlist/add')
            .then((response) => {
              this.loading = false;
              this.checked = true;
              this.rowId = response.rowId;
            }).catch(() => {
            this.showSidebar();
          })
        } else {
          new Form({
            rowId: this.rowId
          })
            .post('/api/watchlist/remove')
            .then((response) => {
              this.loading = false;
              this.checked = false;
            })
        }
      }
    }
  }
</script>
