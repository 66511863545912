<template>
    <span id="topmenu-cartcount">{{ items }}</span>
</template>

<script>
export default {
    props: {
        title: {
            default: 'warenkorb'
        },
        readonly: {
            default: false
        },
        comment: {
            default: ''
        }
    },
    data() {
        return {
            items: 0
        }
    },
    created() {
        App.event.listen('cart.data.count', (response) => {
            this.items = response;
        });
        App.event.listen('cart.item.inserted', (response) => {
            this.items++;
        });
    }
}
</script>
