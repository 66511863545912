'use strict';

import CartHeaderCount from './components/CartHeaderCount.vue';
export default class {

    constructor() {
        // Register component globaly
        Vue.component('cart-header-count', CartHeaderCount);

    }
}