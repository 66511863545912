<template>
    <div>
        <div v-if="success">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h2>Form sent.</h2><small>We will contact you as soon as possible.</small>
                </div>
            </div>
        </div>
        <div v-if="showForm">
            <div class="row">
                <div class="col-xs-6">
                    <div :class="formGroupClass('firstname')">
                        <div class="form-group">
                            First name <span class="orange">*</span>
                            <input name="firstname" class="form-control" v-model="form.firstname">
                            <div class="help-block" v-if="form.errors.has('firstname')">
                                <strong>{{ form.errors.get('firstname') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div :class="formGroupClass('name')">
                        <div class="form-group">
                            Last name <span class="orange">*</span>
                            <input name="name" class="form-control" v-model="form.name">
                            <div class="help-block" v-if="form.errors.has('name')">
                                <strong>{{ form.errors.get('name') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6">
                    <div :class="formGroupClass('email')">
                        <div class="form-group">
                            Email <span class="orange">*</span>
                            <input name="email" class="form-control" v-model="form.email">
                            <div class="help-block" v-if="form.errors.has('email')">
                                <strong>{{ form.errors.get('email') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xs-6">
                    <div :class="formGroupClass('phone')">
                        <div class="form-group">
                            Telephone
                            <input name="phone" class="form-control" v-model="form.phone">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div :class="formGroupClass('question')">
                        <div class="form-group">
                            Your comment <span class="orange">*</span>
                            <textarea name="question" class="form-control" v-model="form.question"></textarea>
                            <div class="help-block" v-if="form.errors.has('question')">
                                <strong>{{ form.errors.get('question') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <button class="btn btn-primary" type="button" @click="onClick">
                        <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                        <slot>Send</slot>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            firstname: {
                type: [String],
                default: ''
            },
            lastname: {
                type: [String],
                default: ''
            },
            email: {
                type: [String],
                default: ''
            }
        },
        data() {
            return {
                form: new Form({
                    question: null,
                    email: null,
                    phone: null,
                    name: null,
                    firstname: null,
                }),
                loading: false,
                success: false,
                showForm: true
            }
        },

        methods: {
            formGroupClass(name) {
                return this.form.errors.has(name) ? 'form-group has-error' : 'form-group';
            },
            onClick() {
                this.loading = true;
                this.form.put('/inquiry').then(response => {
                    App.event.fire('inquiry.success', response);
                }).catch(errors => {
                    App.event.fire('inquiry.failed', errors);
                });
            }
        },
        created() {
            this.form.email = this.email;
            this.form.firstname = this.firstname;
            this.form.name = this.lastname;

            App.event.listen('inquiry.failed', () => { this.loading = false; });
            App.event.listen('inquiry.success', response => {
                this.loading = false;
                this.showForm = false;
                this.success = true;
            });
        },
    }
</script>