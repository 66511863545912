'use strict';

export default class {

  init()
  {
    this.$el = $('footer');

    /**
     * Place custom code here, you would like to bind
     * to special functionality on the footer. this.$el
     * is a jQuery object of the <footer> element.
     */

  }

}
