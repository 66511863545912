'use strict';

import AddToCart from './components/AddToCart.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('add-to-cart', AddToCart);

    }
}
