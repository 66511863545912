<template>
    <div class="results row" id="shop">
        <template v-if="results">
            <div class="d-none d-md-block col-md-3"></div>
            <div class="col-12 col-md-6" v-if="pages > 0">
                <search-pagination v-model="page" :page-count="pages"></search-pagination>
            </div>
            <div class="col-12 col-md-3 d-flex align-items-center justify-content-end">
                {{ numberOfResults }} {{$t("searchresult.foundresults", null, locale)}}
            </div>
            <div class="loading-screen d-flex flex-column align-items-center justify-content-start pt-5" v-if="loading">
                <div>
                    <svg class="lds-spinner" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g transform="rotate(0 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(30 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(60 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(90 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(120 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(150 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(180 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(210 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(240 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(270 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(300 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(330 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                    </svg>
                </div>
                <p>{{$t("shopsearchresults.loading", null, locale)}}</p>
            </div>
            <div v-html="results" class="w-100 col-12"></div>
            <div class="col-12" v-if="pages > 0">
                <search-pagination v-model="page" :page-count="pages"></search-pagination>
            </div>
        </template>
        <div v-else class="d-block w-100 mt-3 mb-3 text-center"><i class="fas fa-spinner fa-spin"></i> {{$t('shopsearchresults.loading', null, this.locale)}}</div>
    </div>
</template>

<script>
import axios from 'axios'
import SearchPagination from './SearchPagination'

export default {
    components: {
        SearchPagination
    },

    props: {
      locale: {
        type: String,
        default: 'de'
      },
      searchword: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        results: '',
        numberOfResults: 0,
        limit: 10,
        localPage: 1,
        loading: false
      }
    },

    computed: {
        fetchUrl: function() {
          return  (this.locale == 'en') ? "/en/api/search" : "/api/search"
        },
        pages: function() {
          return Math.ceil(this.numberOfResults / this.limit)
        },
        page: {
            get() {
                return this.localPage
            },
            set(value) {
                this.setPage(value)
            }
        }
    },

    methods: {
      async findProducts() {
        this.loading = true
        let response = await new Form({searchterm: this.searchword, page: this.localPage}).post(this.fetchUrl)
        this.results = response.results
        this.numberOfResults = response.count
        App.event.fire('searchproductamount.searched', this.numberOfResults)
        this.loading = false
      },
      setPage(value) {
        this.localPage = value;
        this.findProducts();
      }
    },

    created() {
      this.findProducts();
    }
}
</script>

<style lang="css" scoped>


    h2.topevents {
        font-family: "Roboto";
        font-size: 14px;
        font-size: .875rem;
        color: #395279;
        border-bottom: 1px solid #b9b3ab;
        margin: 0;
        margin-bottom: 14px;
        margin-bottom: .875rem;
        padding-bottom: 7px;
        padding-bottom: .4375rem;
        text-transform: uppercase;
    }

    .pagination {
        justify-content: center;
    }

    .results {
        position: relative;
        /* min-height: 350px; */
    }

    .results .loading-screen {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: rgba(255, 255, 255, 0.9);
    }
</style>