<template>
    <section id='register-user'>
        <div class="row">
            <div class="col-sm-12 blackBar">
                <div class="title">{{$t("guest.contactdata", null, locale)}}</div>
            </div>
        </div>
        <div class="backgroundGray">
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('company')">
                        <div class="form-group">
                            <input class="form-control" id="company" type="text" name="company" :placeholder="$t('guest.company', null, locale)" v-model="form.company" />
                            <div class="help-block" v-if="form.errors.has('company')">
                                <strong>{{ form.errors.get('company') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div :class="[formGroupClass('vatin'), validVatin ? ' ' : ' form-group has-error ' ]">
                        <div class="form-group">
                            <input class="form-control" id="vatin" type="text" name="vatin" :placeholder="$t('guest.vatin', null, locale)" v-model="form.vatin"/>
                            <div class="help-block" v-if="form.errors.has('vatin')">
                                <strong>{{ form.errors.get('vatin') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div :class="formGroupClass('salutation')">
                        <select class="form-control" v-model="form.salutation">
                            <option value="" disabled selected>{{ $t('guest.salutation', null, locale) }}</option>
                            <option value="Herr">{{ $t("eventcartmodal.male", null, locale) }}</option>
                            <option value="Frau">{{ $t("eventcartmodal.female", null, locale) }}</option>
                        </select>
                        <div class="help-block" v-if="form.errors.has('salutation')">
                            <strong>{{ form.errors.get('salutation') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('firstname')">
                        <div class="form-group">
                            <input class="form-control" id="firstname" type="text" name="firstname" :placeholder="$t('guest.firstname', null, locale)" v-model="form.firstname" required="required" autofocus="autofocus"/>
                            <div class="help-block" v-if="form.errors.has('firstname')">
                                <strong>{{ form.errors.get('firstname') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div :class="formGroupClass('lastname')">
                        <div class="form-group">
                            <input class="form-control" id="lastname" type="text" name="lastname" :placeholder="$t('guest.lastname', null, locale)" v-model="form.lastname" required="required"/>
                            <div class="help-block" v-if="form.errors.has('lastname')">
                                <strong>{{ form.errors.get('lastname') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div :class="formGroupClass('street')">
                        <div class="form-group">
                            <input class="form-control" id="street" type="text" name="street" :placeholder="$t('guest.street', null, locale)" v-model="form.street" required="required"/>
                            <div class="help-block" v-if="form.errors.has('street')">
                                <strong>{{ form.errors.get('street') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <div :class="formGroupClass('postalCode')">
                        <div class="form-group">
                            <input class="form-control" id="postalCode" type="text" name="postalCode" :placeholder="$t('guest.zip', null, locale)" v-model="form.postalCode" required="required"/>
                            <div class="help-block" v-if="form.errors.has('postalCode')">
                                <strong>{{ form.errors.get('postalCode') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div :class="formGroupClass('city')">
                        <div class="form-group">
                            <input class="form-control" id="city" type="text" name="city" :placeholder="$t('guest.city', null, locale)" v-model="form.city" required="required"/>
                            <div class="help-block" v-if="form.errors.has('city')">
                                <strong>{{ form.errors.get('city') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div :class="formGroupClass('country')">
                        <div class="form-group">
                            <select class="form-control" id="country" name="country" v-model="form.country" required="required">
                                    <option value="" disabled selected>{{ $t('guest.country', null, locale) }}</option>
                                    <option v-for="country in countries" :key="country.id" :value="country.id" :selected="(country.id == form.country)">{{ locale == 'de' ? country.name : country.name_en }}</option>
                            </select>
                            <div class="help-block" v-if="form.errors.has('country')">
                                <strong>{{ form.errors.get('country') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 blackBar"><div class="title">E-Mail</div></div>
        </div>
        <div class="backgroundGray">
            <div class="row">
                <div class="col-sm-8">
                    <div :class="formGroupClass('email')">
                        <div class="form-group">
                            <input class="form-control" id="email" type="email" name="email" placeholder="E-Mail" v-model="form.email" required="required"/>
                            <div class="help-block" v-if="form.errors.has('email')">
                                <strong>{{ form.errors.get('email') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div :class="formGroupClass('email_confirmation')">
                        <div class="form-group">
                            <input class="form-control" id="email_confirmation" type="email" name="email_confirmation" :placeholder="$t('guest.confirm_email', null, locale)" v-model="form.email_confirmation" required="required"/>
                            <div class="help-block" v-if="form.errors.has('email_confirmation')">
                                <strong>{{ form.errors.get('email_confirmation') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="form.errors.has('global')">
            <div class="col-sm-12">
                <div class="alert alert-danger">
                    <i class="fa fa-exclamation-triangle"></i> 
                    {{ form.errors.get('global') }}
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    props: {
      locale: {
          type: String,
          default: 'de'
      },
    },
    data() {
        return {
            form: new Form({
                salutation: '',
                company: null,
                vatin: null,
                firstname: null,
                lastname: null,
                street: null,
                postalCode: null,
                city: null,
                country: '',
                email: null,
                email_confirmation: null
            }),
            validVatin: true,
            countries: []
        }
    },

    methods: {
        formGroupClass(name) {
            return this.form.errors.has(name) ? 'form-group has-error' : 'form-group';
        },

        checkVatin: function () {
            if (this.form.vatin === '') {
                App.event.fire('guest.vatin.valid', true);
                this.validVatin = true;
                return
            }

            axios.get('/shop/api/validate-vatin', {
                params: {
                    vatin: this.form.vatin
                }
            })
                .then((response) => {
                    App.event.fire('guest.vatin.valid', response.data.valid);
                    this.validVatin = response.data.valid;
                })
                .catch(function (error) {
                    App.event.fire('guest.vatin.valid', false);
                })
        }
    },

    watch: {
        vatin: function (newVatin, oldVatin) {
            this.debouncedCheckVatin()
        },
        
        country: function(newCountry, oldCountry) {
            App.event.fire('shipping_address.changed', {
                country: newCountry
            });
        }
    },
    
    computed: {
        vatin () {
            return this.form.vatin;
        },
        
        country () {
            return this.form.country;
        }
    },
    
    created() {
        this.debouncedCheckVatin = _.debounce(this.checkVatin, 500)

        this.countries = App.countries;
        App.event.listen('countries.loaded', countries => this.countries = countries);

        //- register user
        App.event.listen('guest.send', () => {
            this.form.put('/guest').then(response => {
                App.event.fire('guest.success', response);
            }).catch(errors => {
                App.event.fire('guest.failed', errors);
            });
        });

    }
}
</script>
