'use strict';

import MembershipCartModal from './components/MembershipCartModal.vue';

export default class {
  constructor() {
    // Register component globaly
    Vue.component('membership-cart-modal', MembershipCartModal);
  }
}
