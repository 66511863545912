'use strict';

import InstantRegistrationModal from './components/InstantRegistrationModal.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('instant-registration-modal', InstantRegistrationModal);

    }
}
