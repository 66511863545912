<template>
  <tr>
    <td scope="row">{{ itemkey + 1 }}</td>
    <td>
      <a :href="'/shop/' + locale + '/product/'+item.seo" target="_blank" v-if="item.seo"><b>{{ item.name }}</b></a>
      <b v-else>{{ item.name }}</b><br>
      <small v-if="item.eventDate != ''">
        <span>{{ item.eventDate }}</span><br>
      </small>
      <small v-if="item.options.validTill != ''">
        <span>{{ item.options.validTill }}</span><br>
      </small>
      <small v-if="hasOptions && item.options.attendees">
        <template v-for="(attendee, key) in item.options.attendees">
          <span :key="key">{{ attendee.firstname }} {{ attendee.lastname }} (&euro; {{ attendee.origprice.toFixed(2) }})</span><br :key="(1000 + key)">
          <template v-for="(specialOption, key2) in attendee.specialOptions" >
            <span :key="((100 * key) + 2000 + key2)">{{ specialOption.name }}  (&euro; {{ parseFloat(specialOption.price).toFixed(2) }})</span><br :key="((10 * key) + 3000 + key2)">
          </template>
          <template v-for="(specialOption, key2) in attendee.requiredOptions" >
            <span :key="((100 * key) + 4000 + key2)">{{ specialOption.name }}  (&euro; {{ parseFloat(specialOption.price).toFixed(2) }})</span><br :key="((10 * key) + 5000 + key2)">
          </template>
        </template>
      </small>
      <select v-if="item.types && Object.keys(item.types).length > 1 && !readonly && typeof item.options.productType != 'undefined'" @change="updateOption" v-model="item.options.productType" class="form-control">
        <option v-for="(value, key) in item.types" :key="key" :value="key">{{value}}</option>
      </select>
      <template v-else>
        <span class="lang-label">{{ (typeof item.options.productType != 'undefined') ? types[item.options.productType] : '' }}</span>
      </template>
      <select v-if="item.languages && Object.keys(item.languages).length > 1 && !readonly && typeof item.options.language != 'undefined' && item.options.language != ''" @change="updateOption" v-model="item.options.language" class="form-control">
        <option v-for="(value, key) in item.languages" :key="key" :value="key">{{value}}</option>
      </select>
      <template v-else>
        <span class="lang-label">{{ (typeof item.options.language != 'undefined' && item.options.language != '') ?  ' / ' + item.languages[item.options.language] : ''}}</span>
      </template>
      <span v-if="item.hasOwnProperty('status')" class="lang-label">{{ ' / ' + item.status }}</span>
      <i class="fas fa-spinner fa-spin" v-if="cartUpdating"></i>
    </td>
    <td class="text-center">
      {{ formatMoney(parseFloat(item.price)) }}&nbsp;€
    </td>
    <td v-if="readonly || (hasOptions && item.options.attendees) || (hasOptions && item.options.statutes)" class="text-center">
      {{ item.qty }}
    </td>
    <td v-else>
      <spinner @change="updateQty" :value="item.qty" :pstock="100" :loading="loading"></spinner>
    </td>
    <td v-if="!readonly" class="text-center">
      <a href="#" @click.prevent="remove">
        <i :class="removeIcon"></i>
      </a>
    </td>
    <td class="col-price">{{ subtotal }}&nbsp;€</td>
  </tr>
</template>

<script>
  export default {
    props: {
      item: {
        required: true
      },
      readonly: {
        default: false
      },
      itemkey: {
        type: Number,
        required: true,
        default: 0
      },
      locale: {
        type: String,
        default: 'de'
      }
    },

    data() {
      return {
        loading: false,
        removing: false,
        initialized: false,
        cartUpdating: false,
      }
    },

    methods: {
      updateOption(option) {
        // Prevent update on first load.
        this.cartUpdating = true;
        let obj = {
          rowId: this.item.rowId,
          locale: this.locale
        }
        if (this.item.options.productType) {
          obj.option = this.item.options.productType
        }
        if (this.item.options.language && this.item.options.language != '') {
          obj.language = this.item.options.language
        }
        new Form(obj)
          .post('/api/cart/updateOption')
          .then((response) => {
            App.event.fire('cart.updated', response);
            this.loading = false;
            this.cartUpdating = false;
          });
      },

      updateQty(qty) {
        // Prevent update on first load.
        if (this.initialized) {
          this.loading = true;
          new Form({rowId: this.item.rowId, qty: qty})
            .post('/api/cart/update')
            .then((response) => {
              App.event.fire('cart.updated', response);
              this.loading = false;
            });
        }
        this.initialized = true;
      },

      remove() {
        this.removing = true;
        new Form({rowId: this.item.rowId})
          .post('/api/cart/remove')
          .then((response) => {
            App.event.fire('cart.updated', response);
            this.removing = false;
          });
      },
      formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

          const negativeSign = amount < 0 ? "-" : "";

          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;

          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
      }
    },

    computed: {
      subtotal() {
        return this.formatMoney(this.item.subtotal);
        // return parseFloat(this.item.subtotal).toFixed(2).replace('.', ',');
      },

      removeIcon() {
        return this.removing ? 'fa fa-spinner fa-spin' : 'fa fa-times';
      },

      hasOptions() {
        return (!Array.isArray(this.item.options))
      },

      types() {
        return {
          Print: 'Print',
          Download: 'Download',
          PrintDownload: 'Print + Download Kombi-Version'
        }
      }
    }
  }
</script>

