<template>
    <div class="event-overview">
        <div class="row">
          <div class="col-12 d-block">
            {{ selectedFilter }}
            <br>
            <a href="javascript:window.history.back();">{{$t("eventoverview.back", null, locale)}}</a>
          </div>
        </div>
        <template v-if="initialized">
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="row w-100" v-html="results"></div>
              </div>
              <div class="col-12 col-md-3" v-html="teaser">
              </div>
            </div>
        </template>
        <template v-else>
            <div class="loading-screen d-flex flex-column align-items-center justify-content-start">
                <div>
                    <svg class="lds-spinner" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g transform="rotate(0 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(30 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(60 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(90 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(120 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(150 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(180 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(210 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(240 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(270 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(300 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(330 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                    </svg>
                </div>
                <p>{{$t("eventoverview.loadingevents", null, locale)}}</p>
            </div>
        </template>
    </div>
</template>
<script>
import qs from 'qs'
import { assign } from 'lodash'

export default {
    data() {
        return {
            initialized: false,
            results: '',
            teaser: '',
            filters: {
                thema: null,
                format: null,
                start: null,
                end: null,
                referent: null,
                terms: null,
                value: null
            }
        }
    },
    props: {
      locale: {
        type: String,
        default: 'de'
      }
    },
    computed: {
      selectedFilter: function() {
        if (this.filters.thema !== null) {
          return this.$t("eventoverview.searchfortheme", null, this.locale) + this.filters.value
        }
        if (this.filters.format !== null) {
          return this.$t("eventoverview.searchforformat", null, this.locale) + this.filters.value
        }
        if (this.filters.start !== null) {
          let returnValue = this.$t("eventoverview.searchfordate", null, this.locale)
          returnValue += this.filters.start
          if (this.filters.end) {
            returnValue += " - " + this.filters.end
          }
          return returnValue
        }
        if (this.filters.referent !== null) {
          return this.$t("eventoverview.searchforreferent", null, this.locale) + this.filters.value
        }
        if (this.filters.terms !== null) {
          return this.$t("eventoverview.searchforterms", null, this.locale) + this.filters.terms
        }

        return ''
      }
    },
    methods: {
        async loadEvents() {
            let teaser = $('#teaserbox').html()
            let response = await new Form({filters: this.filters, locale: this.locale}).post('/api/eventoverview')
            this.teaser = teaser
            this.results = response.results
            this.initialized = true
        }
    },
    mounted() {
        if (location.search.length) {
            this.filters = assign({...this.filters}, qs.parse(location.search.replace('?', '')))
        }
        this.loadEvents()
    }
}
</script>