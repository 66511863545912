'use strict';

import CartTotal from './components/CartTotal.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('cart-total', CartTotal);

    }
}