import Header from "./header";
import Footer from "./footer";
import Slick from "./slick";
import MagnificPopup from "./magnific-popup";
import Lightbox2 from "./lightbox2";
import Spinner from "./spinner";
import AddToCart from "./add-to-cart";
import AddToCartButton from "./add-to-cart-button";
import CartModal from "./cart-modal";
import CartTotal from "./cart-total";
import CartNumItems from "./cart-num-items";
import CartHeader from "./cart-header";
import CartHeaderCount from "./cart-header-count";
import Checkout from "./checkout";
import Register from "./register";
import Login from "./login";
import AddToMerkliste from "./add-to-merkliste";
import PasswordChange from "./password-change";
import OwlCarusel from "./owl-carusel";
import Wirecard from "./wirecard";
import HeaderProfile from "./header-profile";
import EventAddToCart from "./event-add-to-cart";
import MembershipAddToCart from "./membership-add-to-cart";
import AddToWatchlist from "./add-to-watchlist";
import AddRootToWatchlist from "./add-root-to-watchlist";
import EventCartModal from "./event-cart-modal";
import MembershipCartModal from "./membership-cart-modal";
import ContactForm from "./contact-form";
import SearchComponents from './search';
import Sponsors from './sponsors';
import CancelConditionCheckbox from './cancel-condition-checkbox';
import Subscriptions from './subscriptions';
import MobileMenuLogin from './mobile-menu-login';
import InstantRegistrationModal from './instant-registration-modal';
import InstantRegistration from './instant-registration';

export default {
    Header,
    Footer,
    Slick,
    MagnificPopup,
    Lightbox2,
    Spinner,
    AddToCart,
    AddToCartButton,
    CartModal,
    CartNumItems,
    CartHeader,
    CartHeaderCount,
    Checkout,
    Register,
    Login,
    AddToMerkliste,
    PasswordChange,
    OwlCarusel,
    Wirecard,
    HeaderProfile,
    CartTotal,
    EventAddToCart,
    EventCartModal,
    MembershipCartModal,
    MembershipAddToCart,
    ContactForm,
    AddToWatchlist,
    AddRootToWatchlist,
    Sponsors,
    Subscriptions,
    CancelConditionCheckbox,
    ...SearchComponents,
    InstantRegistration,
    InstantRegistrationModal,
}