'use strict';

import InvoiceAddress from './components/InvoiceAddress.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('invoice-address', InvoiceAddress);

    }
}
