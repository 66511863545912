<template>
  <div style="margin-bottom: 5px;">
      <button :class="[ classname ? classname : 'btn btn-primary']" @click="submit" >
        <i v-if="loading" class="fa fa-spin fa-spinner fa-fw"></i>
        <i v-else-if="!checked" class="fa fa-star fa-fw" aria-hidden="true"></i>
        <i v-else class="fa fa-check fa-fw" aria-hidden="true"></i>
        <slot name="name"><span v-if="checked">{{$t("addtomerkliste.gemerkt", null, locale)}}</span><span v-else>{{$t("addtomerkliste.merken", null, locale)}}</span></slot>
      </button>
      <a v-if="checked" :class="[ classname != '' ? classname : 'btn btn-primary']" :href="url" ><i class="fa fa-list"></i> {{$t("addtomerkliste.tomerkliste", null, locale)}}</a>
  </div>
</template>
<script>
  export default {
    props: {
      pid: {
        type: [String, Number],
        required: true,
        default: null
      },
      classname: {
        type: [String],
        required: false,
        default: ''
      },
      locale: {
        type: String,
        default: 'de'
      }
    },

    computed: {
        url() {
            return App.url + '/profile/merkliste';
        },
    },


    data() {
      return {
        loading: false,
        checked: false,
        rowId: 0,
      }
    },

    created() {
      new Form({
        pid: this.pid
      })
        .post('/api/merkliste/inlist')
        .then((response) => {
          if (response.success) {
            this.checked = true;
            this.rowId = response.rowId;
          }
        })
    },

    methods: {

      showSidebar() {
        $('.sidebars .backdrop').addClass('open')
        $('.sidebars .login-sidebar').addClass('open')
        $('body').addClass('modal-open')
      },
      
      submit() {

        this.loading = true;
        if (this.checked == false) {
          new Form({
            pid: this.pid
          })
            .post('/api/merkliste/add')
            .then((response) => {
              this.loading = false;
              this.checked = true;
              this.rowId = response.rowId;
            }).catch(() => {
              this.showSidebar();
            })
        } else {
          new Form({
            rowId: this.rowId
          })
            .post('/api/merkliste/remove')
            .then((response) => {
              this.loading = false;
              this.checked = false;
            })
        }

      }
    }
  }
</script>
