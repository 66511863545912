<template>
  <div class="row">
    <div class="col-md-12">
      <loading-animation :text="$t('sponsors.loading', null, locale)" v-if="isLoading"></loading-animation>
    </div>

    <div class="col-md-12" v-for="sponsor in sponsors" :key="sponsor.id">
      <div class="row">
        <div class="col-md-3">
          <img v-if="sponsor.has_image == 1"
               :src="'/shop/imagecache/?image=/crm_files/1965/customers/logos/' + sponsor.id + '.jpg'"
               :alt="sponsor.company">
          <img v-else
               src="/shop/public/images/Foto Academy_Credit OVE_Christian Fürthner.jpg"
               :alt="sponsor.company"
               />
        </div>
        <div class="col-md-9">
          <h2>{{ sponsor.company }}</h2>
          <p v-html="sponsor.description"></p>
          <p v-if="sponsor.web.length > 0">
            <a :href="sponsor.web">
              {{$t('sponsors.learnmore', null, locale)}}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LoadingAnimation from '../../event-search/components/LoadingAnimation'

  export default {
      props: {
          locale: {
              type: String,
              default: 'de'
          },
      },
    components: {
      LoadingAnimation
    },
    data() {
      return {
        sponsors: [],
        isLoading: true
      }
    },
    created() {
      new Form().get('/api/sponsors')
        .then((response) => {
          this.sponsors = response.data;
          this.isLoading = false;
        }).catch((e) => {
        this.isLoading = false;
        this.sponsors = [];
      });
    },
  }
</script>