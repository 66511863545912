'use strict';

import AddToWatchlist from './components/AddToWatchlist.vue';

export default class {

  constructor() {
    // Register component globaly
    Vue.component('add-to-watchlist', AddToWatchlist);
  }
}