'use strict';

import AddToMerkliste from './components/AddToMerkliste.vue';

export default class {

  constructor() {
    // Register component globaly
    Vue.component('add-to-merkliste', AddToMerkliste);
  }
}