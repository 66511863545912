<template>
  <label>
    <span v-html="$t('addtocart.cancelcondition', null, locale)"></span>
  </label>
</template>

<script>
    export default {
        name: "CancelConditionCheckbox",
        props: {
            locale: {
                type: String,
                default: 'de'
            }
        }
    }
</script>
