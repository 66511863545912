<template>
    <div id="cart-num-items">
            {{ added }}
    </div>
</template>

<script>
export default {

    data() {
        return {
            added: 0
        }
    },

    created() {
        App.event.listen('cart.data.loaded', ({data}) => {
            this.added = data.count;
        });

        App.event.listen('cart.updated', ({data}) => {
            this.added = data.count;
        });
    },
}
</script>
