'use strict';

import Vue from 'vue';
import VueMask from 'v-mask';
import Event from './event';
import Config from '../config';
import Module from '../modules/';
import components from '../modules/components';
import createStore from '../store';
import Language from '../language';
import VueCookie from 'vue-cookie';

export default class {

  constructor() {
    let token = document.head.querySelector('meta[name="csrf-token"]');
    this.token = token ? token.content : null;
    this.event = new Event();
    this.config = Config;
    this.countries = [];
    this.user = [];
    this.locale = 'de';

    Vue.use(VueMask);
    Vue.use(Language);
    Vue.use(VueCookie);

    this.slick = new Module.Slick();
    this.header = new Module.Header();
    this.footer = new Module.Footer();
    this.magnificPopup = new Module.MagnificPopup();
    this.lightbox2 = new Module.Lightbox2();

    new Module.AddToCart();
    new Module.Spinner();
    new Module.CartModal();
    new Module.Checkout();
    new Module.PasswordChange();
    new Module.Wirecard();
    new Module.CartHeaderCount();
    new Module.HeaderProfile();
    new Module.EventAddToCart();
    new Module.EventCartModal();
    new Module.InstantRegistration();
    new Module.InstantRegistrationModal();
    new Module.MembershipAddToCart();
    new Module.MembershipCartModal();
    new Module.AddToMerkliste();
    new Module.AddToWatchlist();
    new Module.AddRootToWatchlist();
    new Module.Sponsors();
    new Module.Subscriptions();
    new Module.CancelConditionCheckbox();
  }

  init(url, locale) {
    if (typeof locale == 'undefined') {
      locale = 'de';
    } else {
      this.locale = locale;
    }
    
    this.url = url.replace(/\/+$/, "");

    // - Trigger cookie consent banner.
    cookieconsent.initialise(Config.cookieconsent);

    this.slick.init();
    this.header.init();
    this.magnificPopup.init();
    this.lightbox2.init();

    new Module.ContactForm();
    new Module.Register();
    new Module.Login();

    this.fetchUser().then((response) => {
      this.event.fire('profile.loaded', response);
    })

    let lang = new Language(locale);

    this.vue = new Vue({
      el: '#app',
      components,
      lang,
      store: createStore([])
    });

    this.cartCount();
    this.fetchCountries();
  }

  cartCount() {
    new Form().get('/'  + this.locale +  '/api/cart/count')
      .then((response) => {
        this.event.fire('cart.data.count', response);
      });
  }

  fetchCountries() {
    new Form().get('/register/countries')
      .then(countries => {
        this.countries = countries;
        this.event.fire('countries.loaded', countries);
      });
  }

  fetchUser() {
    return new Form().get('/api/me')
      .then(user => this.user = user);
  }
}