<template>
  <div>
    <button class="btn btn-primary" @click="submit">
      <i v-if="loading" class="fa fa-spin fa-spinner fa-fw"></i>
      <i v-else-if="!checked" class="fa fa-list fa-fw" aria-hidden="true"></i>
      <i v-else class="fa fa-check fa-fw" aria-hidden="true"></i>
      <slot name="name"><span v-if="checked">{{$t("addtowatchlist.added", null, locale)}}</span><span v-else>{{$t("addtowatchlist.add", null, locale)}}</span></slot>
    </button>
    <a v-if="checked" class="btn btn-primary" :href="url" ><i class="fa fa-list"></i> {{$t("addtowatchlist.towatchlist", null, locale)}}</a>
  </div>
</template>
<script>
  export default {
    props: {
      pid: {
        type: [String, Number],
        required: true,
        default: null
      },
      locale: {
        type: String,
        default: 'de'
      }
    },

    computed: {
        url() {
            return App.url + '/profile/norm-watchlist';
        },
    },

    data() {
      return {
        loading: false,
        checked: false,
        rowId: 0,
        
      }
    },

    created() {
      new Form({
        pid: this.pid
      })
        .post('/api/watchlist/inlist')
        .then((response) => {
          if (response.success) {
            this.checked = true;
            this.rowId = response.rowId;
          }
        })
    },

    methods: {

      showSidebar() {
        $('.sidebars .backdrop').addClass('open')
        $('.sidebars .login-sidebar').addClass('open')
        $('body').addClass('modal-open')
      },
      
      submit() {

        this.loading = true;
        if (this.checked == false) {
          new Form({
            pid: this.pid
          })
            .post('/api/watchlist/add')
            .then((response) => {
              this.loading = false;
              this.checked = true;
              this.rowId = response.rowId;
              
              if (response.action == 'parent') {
                let parentText = '';
                
                if (response.parents.length > 1) {
                  parentText = "Die aktuellen Ausgaben dieser Norm sind: ";
                  
                  let productarray = [];
                  for (const parentIndex in response.parents) {
                    productarray.push("<a href='" + App.url + "/product/" + response.parents[parentIndex].seo + "'>" + response.parents[parentIndex].name + "</a>");
                  }
                  
                  parentText += productarray.join(', ');
                  parentText += "<br>Diese wurden Ihrer Watchlist hinzugefügt.";
                } else {
                  parentText = "Die aktuelle Ausgabe dieser Norm ist: <a href='" + App.url + "/product/" + response.parents[0].seo + "'>" + response.parents[0].name + "</a><br>Diese wurde Ihrer Watchlist hinzugefügt.";
                }
                
                  swal({
                      html:  true,
                      type:  'info',
                      title: '',
                      text: parentText,
                  });
              } else if (response.action == 'both') {
                let parentText = '';

                if (response.parents.length > 1) {
                  parentText = "Diese Norm wurde teilweise ersetzt durch: ";

                  let productarray = [];
                  for (const parentIndex in response.parents) {
                    productarray.push("<a href='" + App.url + "/product/" + response.parents[parentIndex].seo + "'>" + response.parents[parentIndex].name + "</a>");
                  }

                  parentText += productarray.join(', ');
                  parentText += "<br>Diese wurden ebenfalls Ihrer Watchlist hinzugefügt.";
                } else {
                  parentText = "Diese Norm wurde teilweise ersetzt durch: <a href='" + App.url + "/product/" + response.parents[0].seo + "'>" + response.parents[0].name + "</a><br>Diese wurde ebenfalls Ihrer Watchlist hinzugefügt.";
                }

                swal({
                      html:  true,
                      type:  'info',
                      title: '',
                      text: parentText,
                  });
              }
            }).catch(() => {
              this.showSidebar();
        })
        } else {
          new Form({
            rowId: this.rowId
          })
            .post('/api/watchlist/remove')
            .then((response) => {
              this.loading = false;
              this.checked = false;
            })
        }

      }
    }
  }
</script>
