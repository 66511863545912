/**
 * Main javascript entry point
 * Should handle bootstrapping/starting application
 *
 * @author Matej Svajger <hello@matejsvajger.com>
 */

/*global window, document, jQuery, $, require, alert, location, parent, global */
import 'promise-polyfill/src/polyfill';
import 'es6-promise/auto';
import Application from './core/app';
import Form from './core/form';
import axios from 'axios';

/**
 * First we will load all of this project's JavaScript dependencies which
 * include jQuery, Bootstrap, bootstrap-datepicker and bootstrap-select,
 * slick-slider, magnificPopup, mmenu, parsley, sweetalert and
 * cookieconsent. This gives a great starting point for building
 * robust, powerful web applications.
 */



require('./bootstrap');


/**
 * Setup some globals for objects we'll be using across application.
 */
window.Form = Form;
window.axios = axios;

window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest'
};

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}

/**
 * Next, we will create a fresh Application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

let App = new Application();

/** Expose the App to browser window object */
window.App = App;


var callback = function () {
  var finallybuy = document.getElementById('finallybuy')
  if (finallybuy) {
    finallybuy.addEventListener (
      "submit", 
      function (evt) {
        var agb = document.getElementById('agbcheckbox').checked;
        if (!agb) {
          evt.preventDefault();
          document.querySelector("#agbaccept .agb-error").classList.remove("d-none");
        }
      }
    );
  }
};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  callback();
} else {
  document.addEventListener("DOMContentLoaded", callback);
}

/**
 * Initialize the application on document ready. All code that needs
 * to be executed when page is done, should be handled from within the
 * Application init method.
 */

// $(() => {
//     App.init(); // App initialized in footer;
// })

document.addEventListener("DOMContentLoaded", function () {
  if (document.querySelectorAll('[data-toggle="popover"]').length > 0) {
    $('[data-toggle="popover"]').popover({
      html: true
    });
  }

  $('[data-toggle="tooltip"]').tooltip({template: '<div class="tooltip show" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'})

  require('jstree/dist/jstree.min')
  require('jstree/dist/themes/default/style.min.css')

  if (document.querySelector("#jstree_div") != null) {
    $("#jstree_div")
      .on('activate_node.jstree', function(e, data) {
        if (typeof data.event.target.href != 'undefined' && ! data.event.target.href.endsWith('#')) {
          window.location.href = data.event.target.href;
        }
    }).jstree({
      "core" : {
        "multiple" : false,
      }
    });
  }

  $(document).on('click', function (e) {
    $('[data-toggle="popover"],[data-original-title]').each(function () {
      //the 'is' for buttons that trigger popups
      //the 'has' for icons within a button that triggers a popup
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
        (($(this).popover('hide').data('bs.popover')||{}).inState||{}).click = false  // fix for BS 3.3.6
      }

    });
  });
});