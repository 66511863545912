<template>
    <div id="slider" class="owl-carousel owl-theme owl-slider2">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        mounted: function () {
            this.$nextTick(function () {
                $('.owl-slider2').owlCarousel({
                    loop: false,
                    center: false,
                    items: 3,
                    margin: 0,
                    autoplay: false,
                    dots:false,
                    autoplayTimeout: 8500,
                    smartSpeed: 450,
                    nav:true,              
                    rewind:true,
                    slideBy:'page',
                    mouseDrag:false,
                    touchDrag: false,
                    responsive: {
                            0:{
                                items:1,
                                touchDrag: true,
                                mouseDrag:true,
                            },
                            768:{
                                items:1,
                                touchDrag: false,
                                mouseDrag:false,
                            }
                    }  
                });
            })
        }
    }
</script>