<template>
    <span>€ {{ subtotal }}</span>
</template>

<script>
export default {

    data() {
        return {
            subtotal: 0
        }
    },

    created() {
        App.event.listen('cart.data.loaded', ({data}) => {
            let val = parseFloat(data.subtotal).toFixed(2);
            this.subtotal = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });

        App.event.listen('cart.updated', ({data}) => {
            let val = parseFloat(data.subtotal).toFixed(2);
            this.subtotal = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
    },
}
</script>
