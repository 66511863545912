<template>
    <section id='register-user'>
        <div class="backgroundGray">
          <div class="row">
            <div class="col-sm-12">
              <div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="isfirma" id="isfirmaNo" value="0" v-model="form.isfirma">
                  <label class="form-check-label" for="isfirmaNo">{{$t("register.personal", null, locale)}}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="isfirma" id="isfirmaYes" value="1" v-model="form.isfirma" checked>
                  <label class="form-check-label" for="isfirmaYes">{{$t("register.company", null, locale)}}</label>
                </div>
              </div>
            </div>
          </div>
            <template v-if="form.isfirma == 1">
              <div class="row mt-2">
                <div class="col-sm-12">
                  <h5>{{$t("register.contactperson", null, locale)}}</h5>
                </div>
              </div>
              <div class="row" >
                <div class="col-sm-4">
                  <div :class="formGroupClass('salutation')">
                    <label for="salutation">{{$t("register.salutation", null, locale)}} *</label>
                    <select class="form-control" v-model="form.salutation" id="salutation" required>
                      <option value="" disabled selected></option>
                      <option value="Herr">{{$t("register.herr", null, locale)}}</option>
                      <option value="Frau">{{$t("register.frau", null, locale)}}</option>
                    </select>
                    <div class="help-block" v-if="form.errors.has('salutation')">
                      <strong>{{ form.errors.get('salutation') }}</strong>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div :class="formGroupClass('title')">
                    <label for="title">{{$t("register.title", null, locale)}}</label>
                    <input class="form-control" id="title" type="text" name="title" placeholder="" v-model="form.title"/>
                    <div class="help-block" v-if="form.errors.has('title')">
                      <strong>{{ form.errors.get('title') }}</strong>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div :class="formGroupClass('title_after')">
                    <label for="title_after">{{$t("register.titlefollowing", null, locale)}}</label>
                    <input class="form-control" id="title_after" type="text" name="title_after" placeholder="" v-model="form.title_after"/>
                    <div class="help-block" v-if="form.errors.has('title_after')">
                      <strong>{{ form.errors.get('title_after') }}</strong>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div :class="formGroupClass('firstname')">
                    <label for="firstname">{{$t("register.firstname", null, locale)}} *</label>
                    <input class="form-control" id="firstname" type="text" name="firstname" placeholder="" v-model="form.firstname" required/>
                    <div class="help-block" v-if="form.errors.has('firstname')">
                      <strong>{{ form.errors.get('firstname') }}</strong>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div :class="formGroupClass('lastname')">
                    <label for="lastname">{{$t("register.surname", null, locale)}} *</label>
                    <input class="form-control" id="lastname" type="text" name="lastname" placeholder="" v-model="form.lastname" required/>
                    <div class="help-block" v-if="form.errors.has('lastname')">
                      <strong>{{ form.errors.get('lastname') }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <h5>{{$t("register.company", null, locale)}}</h5>
                </div>
              </div>
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('firm')">
                        <label for="firm">{{$t("register.company", null, locale)}} *</label>
                        <input class="form-control" id="firm" type="text" name="firm" placeholder="" v-model="form.firm" required />
                        <div class="help-block" v-if="form.errors.has('firm')">
                            <strong>{{ form.errors.get('firm') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div :class="[formGroupClass('vatin'), validVatin ? ' ' : ' form-group has-error ']">
                        <label for="vatin">{{$t("register.vatnumber", null, locale)}} <i class="fas fa-info-circle" data-toggle="tooltip" :title='$t("register.vatinfo", null, locale)'></i></label>
                        <input class="form-control" id="vatin" type="text" name="vatin" placeholder="" v-model="form.vatin"/>
                        <div class="help-block" v-if="form.errors.has('vatin')">
                            <strong>{{ form.errors.get('vatin') }}</strong>
                        </div>
                             <div class="help-block" v-if="!validVatin">
                            <strong>{{ $t("register.errorVatin", null, locale) }}</strong>
                        </div>
                    </div>
                </div>
            </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="knr">{{$t("register.knr", null, locale)}} (Optional)</label>
                    <input class="form-control" id="knr" type="text" name="knr" placeholder="" v-model="form.knr"/>
                  </div>
                </div>
              </div>
              
            </template>

          <div class="row" v-if="form.isfirma == 0">
            <div class="col-sm-4">
              <div :class="formGroupClass('salutation')">
                <label for="salutation">{{$t("register.salutation", null, locale)}} *</label>
                <select class="form-control" v-model="form.salutation" id="salutation" required>
                  <option value="" disabled selected></option>
                  <option value="Herr">{{$t("register.herr", null, locale)}}</option>
                  <option value="Frau">{{$t("register.frau", null, locale)}}</option>
                </select>
                <div class="help-block" v-if="form.errors.has('salutation')">
                  <strong>{{ form.errors.get('salutation') }}</strong>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div :class="formGroupClass('title')">
                <label for="title">{{$t("register.title", null, locale)}}</label>
                <input class="form-control" id="title" type="text" name="title" placeholder="" v-model="form.title"/>
                <div class="help-block" v-if="form.errors.has('title')">
                  <strong>{{ form.errors.get('title') }}</strong>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div :class="formGroupClass('title_after')">
                <label for="title_after">{{$t("register.titlefollowing", null, locale)}}</label>
                <input class="form-control" id="title_after" type="text" name="title_after" placeholder="" v-model="form.title_after"/>
                <div class="help-block" v-if="form.errors.has('title_after')">
                  <strong>{{ form.errors.get('title_after') }}</strong>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div :class="formGroupClass('firstname')">
                <label for="firstname">{{$t("register.firstname", null, locale)}} *</label>
                <input class="form-control" id="firstname" type="text" name="firstname" placeholder="" v-model="form.firstname" required/>
                <div class="help-block" v-if="form.errors.has('firstname')">
                  <strong>{{ form.errors.get('firstname') }}</strong>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div :class="formGroupClass('lastname')">
                <label for="lastname">{{$t("register.surname", null, locale)}} *</label>
                <input class="form-control" id="lastname" type="text" name="lastname" placeholder="" v-model="form.lastname" required/>
                <div class="help-block" v-if="form.errors.has('lastname')">
                  <strong>{{ form.errors.get('lastname') }}</strong>
                </div>
              </div>
            </div>
          </div>
            <div class="row">
                <div class="col-sm-12">
                    <div :class="formGroupClass('street')">
                        <label for="street">{{$t("register.street", null, locale)}} *</label>
                        <input class="form-control" id="street" type="text" name="street" placeholder="" v-model="form.street" required/>
                        <div class="help-block" v-if="form.errors.has('street')">
                            <strong>{{ form.errors.get('street') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('postalCode')">
                        <label for="postalCode">{{$t("register.plz", null, locale)}} *</label>
                        <input class="form-control" id="postalCode" type="text" name="postalCode" placeholder="" v-model="form.postalCode" required/>
                        <div class="help-block" v-if="form.errors.has('postalCode')">
                            <strong>{{ form.errors.get('postalCode') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div :class="formGroupClass('city')">
                        <label for="city">{{$t("register.city", null, locale)}} *</label>
                        <input class="form-control" id="city" type="text" name="city" placeholder="" v-model="form.city" required/>
                        <div class="help-block" v-if="form.errors.has('city')">
                            <strong>{{ form.errors.get('city') }}</strong>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div :class="formGroupClass('country')">
                    <label for="country">{{$t("register.country", null, locale)}} *</label>
                    <select class="form-control" id="country" name="country" v-model="form.country" required>
                            <option value="" disabled selected></option>
                            <option v-for="country in countries" :key="country.id" :value="country.id" :selected="(country.id == form.country)">{{ locale == 'de' ? country.name : country.name_en }}</option>
                    </select>
                    <div class="help-block" v-if="form.errors.has('country')">
                        <strong>{{ form.errors.get('country') }}</strong>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div :class="formGroupClass('phone')">
                    <label for="phone">{{$t("register.phone", null, locale)}}</label>
                    <input class="form-control" id="phone" type="text" name="phone" placeholder="" v-model="form.phone" />
                    <div class="help-block" v-if="form.errors.has('phone')">
                    <strong>{{ form.errors.get('phone') }}</strong>
                    </div>
                </div>
            </div>
        </div>
        <div class=" backgroundGray">
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('email')">
                        <label for="email">{{$t("register.email_username", null, locale)}} *</label>
                        <input class="form-control" id="email" type="email" name="email" placeholder="" v-model="form.email" required/>
                        <div class="help-block" v-if="form.errors.has('email')">
                            <strong>{{ form.errors.get('email') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div :class="formGroupClass('email_confirmation')">
                        <label for="email_confirmation">{{$t("register.confirmemail", null, locale)}} *</label>
                        <input class="form-control" id="email_confirmation" type="email" name="email_confirmation" placeholder="" v-model="form.email_confirmation" required/>
                        <div class="help-block" v-if="form.errors.has('email_confirmation')">
                            <strong>{{ form.errors.get('email_confirmation') }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="backgroundGray">
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('password')">
                        <label for="password">{{$t("register.password", null, locale)}} * <i class="fas fa-info-circle" data-toggle="tooltip" v-bind:title='$t("register.passwordinformation", null, locale)'></i></label>
                        <input class="form-control" id="password" type="password" name="password" v-model="form.password" placeholder="" required />
                        <div class="help-block" v-if="form.errors.has('password')">
                            <strong>{{ form.errors.get('password') }}</strong>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="password-confirm">{{$t("register.confirmpassword", null, locale)}} *</label>
                        <input class="form-control" id="password-confirm" type="password" name="password_confirmation" v-model="form.password_confirmation" placeholder="" required />
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="form.errors.has('global')">
            <div class="col-sm-12">
                <div class="alert alert-danger">
                    <i class="fa fa-exclamation-triangle"></i> 
                    {{ form.errors.get('global') }}
                </div>
            </div>
        </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-check" :class="formGroupClass('privacy')">
            <input class="form-check-input" type="checkbox" name="privacy" value="1" id="privacy_checkbox" required v-model="form.privacy">
            <label class="form-check-label" for="privacy_checkbox" v-html="$t('register.privacycheckbox', null, locale)">
              
            </label>
            <div class="help-block" v-if="form.errors.has('privacy')">
              <strong>{{ form.errors.get('privacy') }}</strong>
            </div>
          </div>
        </div>
      </div>
     
      <div class="row mb-2">
        <div class="col-sm-12 form-group has-error">
          <vue-recaptcha
            ref="normalRecaptcha"
            @verify="onVerify"
            @expired="onExpired"
            size="normal"
            type="checkbox"
            :language="locale"
            :loadRecaptchaScript="true"
            :sitekey="sitekey">
          </vue-recaptcha>

          <div class="help-block" v-if="form.errors.has('grecaptcha')">
            <strong>{{ form.errors.get('grecaptcha') }}</strong>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  props: {
    locale: {
      type: String,
      default: 'de'
    }
  },
  components: { VueRecaptcha },
    data() {
        return {
            form: new Form({
                salutation: '',
                title: '',
                title_after: '',
                firm: null,
                vatin: null,
                firstname: null,
                lastname: null,
                street: null,
                postalCode: null,
                phone: null,
                isfirma: 1,
                city: null,
                country: '',
                email: null,
                mailNl: null,
                password: null,
                email_confirmation: null,
                password_confirmation: null,
                privacy: false,
                locale: this.locale,
                knr: '',
                grecaptcha:''
            }),
            validVatin: true,
            countries: [],
          sitekey: process.env.MIX_CAPTCHA_SITE_KEY
        }
    },

    watch: {
      vatin: function (newVatin, oldVatin) {
        this.debouncedCheckVatin()
      }
    },

    computed: {
        vatin () {
            return this.form.vatin;
        }
    },

    methods: {
        formGroupClass(name) {
            return this.form.errors.has(name) ? 'form-group has-error' : 'form-group';
        },
        
        checkVatin: function () {
            if (this.form.vatin === '') {
                App.event.fire('vatin.valid', true);
                this.validVatin = true;
                return
            }

            axios.get('/shop/api/validate-vatin', {
                params: {
                    vatin: this.form.vatin
                }
            })
                .then((response) => {
                    App.event.fire('vatin.valid', response.data.valid);
                    this.validVatin = response.data.valid;
                })
                .catch(function (error) {
                    App.event.fire('vatin.valid', false);
                })
        },

      onVerify: function (response) {
        console.log('Verify: ' + response)
        this.form.grecaptcha = response;
      },
      onExpired: function () {
        console.log('Expired')
        this.form.grecaptcha = '';
      },
    },

    created() {
        this.debouncedCheckVatin = _.debounce(this.checkVatin, 500)
        this.countries = App.countries;
        App.event.listen('countries.loaded', countries => this.countries = countries);
    },

    mounted() {
        $('[data-toggle="tooltip"]').tooltip({template: '<div class="tooltip show" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'})

        //- register user
        App.event.listen('registration.send', () => {
            this.form.submit('put', '/' + this.locale + '/register', false).then(response => {
                App.event.fire('registration.success', response);
            }).catch(errors => {
                App.event.fire('registration.failed', errors);
                this.form.grecaptcha = '';
                this.$refs.normalRecaptcha.reset()
            });
        });
  }
}
</script>
