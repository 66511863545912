<template>
        <a :href="(locale == 'en') ? '/shop/en/login/' : '/shop/login/'" v-if="!loggedin">{{$t('headerprofile.login', null, locale)}}<span class="icon"></span></a>
        <a :href="(locale == 'en') ? '/shop/en/profile/' : '/shop/profile/'" v-else>{{$t('headerprofile.profil', null, locale)}}<span class="icon"></span></a>
</template>
<script>
import Vue from "vue";

export default {

    data() {
        return {
            loggedin: false
        }
    },

    props: {
      locale: {
        type: String,
        default: 'de'
      }
    },

    methods: {
        showSidebar() {
            $('.sidebars .backdrop').addClass('open')
            $('.sidebars .login-sidebar').addClass('open')
            $('body').addClass('modal-open')
        },
    },

    created() {
        App.event.listen('profile.loaded', (response) => {
            if (response) this.loggedin = true;
        });

        App.event.listen('login.success', (response) => {
            if (response) this.loggedin = true;
        });
    }
}
</script>
