<template>
  <div style="position:relative;" class="mobile-scroll">
    <h1 v-text="getTitle"></h1>
    <div class="row" v-if="empty">
      <div class="col-sm-8 blackBar">
        <div class="title">
          {{$t("checkout.cart.empty", null, locale)}}
        </div>
      </div>
      <div class="col-sm-4 blackBar">
        <a :href="link" class="btn active">{{$t("checkout.cart.productsearch", null, locale)}}</a>
      </div>
    </div>
    <div class="loading-screen d-flex flex-column align-items-center justify-content-start" v-if="!initialized">
      <div>
        <svg class="lds-spinner" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100"
             preserveAspectRatio="xMidYMid">
          <g transform="rotate(0 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(30 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(60 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(90 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(120 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(150 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(180 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(210 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(240 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(270 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(300 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
            </rect>
          </g>
          <g transform="rotate(330 50 50)">
            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
              <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
            </rect>
          </g>
        </svg>
      </div>
      <p>{{$t("checkout.cart.loadcart", null, locale)}}</p>
    </div>
    <table v-else class="table">
      <thead>
      <tr>
        <th scope="col" class="numbering">#</th>
        <th scope="col" class="col-description">{{$t("checkout.cart.articledesc", null, locale)}}</th>
        <th scope="col" class="col-unitprice">{{$t("checkout.cart.unitprice", null, locale)}}</th>
        <th scope="col" class="col-amount">{{$t("checkout.cart.amount", null, locale)}}</th>
        <th v-if="!readonly" scope="col" class="col-delete">{{$t("checkout.cart.delete", null, locale)}}</th>
        <th scope="col" class="col-price">{{$t("checkout.cart.price", null, locale)}}</th>
      </tr>
      </thead>
      <tbody>
      <cart-row v-for="(item, index) in items" :key="item.rowId" :item="item" :locale="locale" :readonly="readonly" :itemkey="index"></cart-row>
      <tr>
        <td colspan="2">
          <div class="form-group">
            <template v-if="readonly">
              <span v-if="comment.length > 0" v-text="$t('cart.usercomment_read', null, locale)"></span> {{ comment }}
            </template>
            <template v-else>
              <label for="user_comment" v-text="$t('cart.usercomment', null, locale)"></label>
              <textarea  class="form-control" v-model="userComment" name="user_comment" id="user_comment"></textarea>
            </template>
          </div>
          <div class="form-group">
            <template v-if="readonly">
              <span v-if="ordernumberprop.length > 0" v-text="$t('checkout.cart.ordercode_read', null, locale)"></span> {{ ordernumberprop }}
            </template>
            <template v-else>
              <label for="order_number" v-text="placeholder"></label>
              <input id="order_number" class="form-control" :required="this.user ? this.user.orderCodeRequired : false" type="text" v-model="orderNumber" name="order_number">
            </template>
           
          </div>
        </td>
        <td :colspan="totalsSpan" class="sumTextCell">
          <div class="sumText">{{$t("checkout.cart.shipping", null, locale)}} <span>{{$t("checkout.cart.exclvat", null, locale)}}</span></div>
          <div class="sumText">{{$t("checkout.cart.netamount", null, locale)}}</div>
          <div class="sumText">{{$t("checkout.cart.vat", null, locale)}}</div>
          <div class="sumTextLineTop" v-if="data.voucher.discount">{{$t("checkout.cart.vouchername", null, locale)}}</div>
          <div class="sumText" v-if="data.voucher.discount">{{$t("checkout.cart.voucher", null, locale)}}</div>
          <div class="sumTextLineTop"><b>{{$t("checkout.cart.total", null, locale)}}</b></div>
        </td>
        <td class="sumValueCell">
          <div class="sumValue">{{ shippingCost }}&nbsp;€</div>
          <div class="sumValue">{{ subtotal }}&nbsp;€</div>
          <div class="sumValue">{{ tax }}&nbsp;€</div>
          <div class="sumValueLineTop" v-if="data.voucher.discount">{{ data.voucher.name }}</div>
          <div class="sumValue" v-if="data.voucher.discount">{{ data.voucher.discount }}&nbsp;%</div>
          <div class="sumValueLineTop"><b>{{ total }}&nbsp;€</b></div>
        </td>
      </tr>
      </tbody>
    </table>
    <section id="payment-types" v-if="(!readonly || voucherCode)" style="display:none;">
      <div class="row">
        <div class="col-sm-12">
          <div class="title">{{$t("checkout.cart.vouchercode", null, locale)}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div>
            <div class="form-group">
              <div v-if="readonly" class="voucherCode">
                {{ voucherCode }}
              </div>
              <input v-else class="form-control" id="voucherCode" type="text" name="voucherCode" placeholder="Geben Sie ihren Gutscheincode ein"
                     v-model="voucherCode" @keyup.enter="addVoucher"/>
              <div class="help-block" v-if="voucherCodeError">
                <strong>{{ voucherCodeError }}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-2">
          <button type="button" class="btn btn-primary" @click="addVoucher" v-if="!readonly">{{$t("checkout.cart.apply", null, locale)}}</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {

    props: {
      title: {
        default: 'Warenkorb'
      },
      readonly: {
        default: false
      },
      comment: {
        default: ''
      },
      ordernumberprop: {
        default: ''
      },
      shippingprop: {
        default: 7
      },
      user: {
        default: null
      },
      locale: {
        type: String,
        default: 'de'
      },
      hydrationData: {
          type: Object,
          default: function () {
              return {};
          }
      }
    },

    data() {
      return {
        initialized: false,
        userComment: '',
        voucherCode: '',
        voucherCodeError: "",
        orderNumber: "",
        items: {},
        data: {
          tax: '0',
          total: '0',
          shippingCost: '0',
          voucher: "",
          subtotal: '0',
          discountValue: '0',
          subtotalVatDiscount: '0',
          fullPrice: '0'
        }
      }
    },

    created() {
      
      new Form().get('/'  + this.locale +  '/api/cart/content')
        .then((response) => {
          App.event.fire('cart.data.loaded', response);
        });
      
      App.event.listen('cart.data.loaded', (response) => {
        this.items = response.items;
        this.data = response.data;
        this.voucherCode = response.data.voucher.code;
        this.initialized = true;
        this.$emit('onLoad', this.items);

        if (this.readonly) {
          this.data.shippingCost = this.shippingprop;
        }
      });

      App.event.listen('cart.updated', (response) => {
        this.items = response.items;
        this.data = response.data
        
        if (this.readonly) {
          this.data.shippingCost = this.shippingprop;
        }
      });

      App.event.listen('shipping_address.changed', (address) => {
        new Form().get('/' + this.locale +  '/api/cart/content?shipping_country=' + address.country)
          .then((response) => {
            App.event.fire('cart.updated', response);
          })
      })
      
      if (this.readonly) {
        this.data.shippingCost = this.shippingprop;
      }
      
      if(this.hydrationData.user_comment) {
          this.userComment = this.hydrationData.user_comment;
      }

      if (this.hydrationData.order_number) {
          this.orderNumber = this.hydrationData.order_number;
      }
    },

    computed: {
      getTitle() {
        return this.$t("checkout.cart.defaultTitle", null, this.locale, this.title)
      },
      subtotal() {
        this.data.subtotal = parseFloat(this.data.subtotal) + parseFloat(this.data.shippingCost);
        return this.formatMoney(this.data.subtotal);
        // return parseFloat(this.data.subtotal).toFixed(2).replace('.', ',');
      },

      tax() {
        return this.formatMoney(this.data.tax);
        // return parseFloat(this.data.tax).toFixed(2).replace('.', ',');
      },

      discountValue() {
        this.data.discountValue = parseFloat(this.data.fullPrice) - parseFloat(this.data.subtotal);
        this.data.discountValue = parseFloat(this.data.discountValue).toFixed(2) * 1.20;
        return this.formatMoney(parseFloat(this.data.discountValue));
        // return parseFloat(this.data.discountValue).toFixed(2).replace('.', ',');
      },

      subtotalVatDiscount() {
        this.data.subtotalVatDiscount = parseFloat(this.data.subtotal) - parseFloat(this.data.discountValue);
        return this.formatMoney(parseFloat(this.data.subtotalVatDiscount));
        // return parseFloat(this.data.subtotalVatDiscount).toFixed(2).replace('.', ',');
      },

      placeholder() {
        let ordercodeOptional = this.$t("checkout.cart.ordercodeoptional", null, this.locale)
        let ordercode = this.$t("checkout.cart.ordercode", null, this.locale);
        return !this.user ? ordercodeOptional : (this.user.orderCodeRequired ? ordercode : ordercodeOptional);
      },

      shippingCost() {
        if (this.data.shippingCost != 0) {
          return this.formatMoney(this.data.shippingCost);
        }

        return 0;
      },

      total() {
        if (this.data.subtotalVatDiscount) {
          if (this.data.shippingCost != 0) {
            this.data.total = parseFloat(this.data.subtotalVatDiscount) + parseFloat(this.data.shippingCost);
          } else {
            this.data.total = parseFloat(this.data.subtotalVatDiscount);
          }
        } else {
        this.data.total = parseFloat(this.data.total) + parseFloat(this.data.shippingCost);
        }
        return this.formatMoney(parseFloat(this.data.total));
        // return parseFloat(this.data.total).toFixed(2).replace('.', ',');
      },

      link() {
        return App.url;
      },

      empty() {
        return this.initialized && Object.keys(this.items).length === 0;
      },

      totalsSpan() {
        return this.readonly ? 2 : 3;
      }
    },

    watch: {
      user(user) {
        if (user && this.orderNumber.length == 0) {
          this.$nextTick(() => {
            this.orderNumber = user.orderCode
          })
        }
      }
    },

    methods: {
      addVoucher() {
        new Form({voucherCode: this.voucherCode, locale: this.locale})
          .post('/api/cart/voucherAdd')
          .then((response) => {
            if (response.error) {
              this.voucherCodeError = response.errorText;
              this.items = response.items;
              this.data = response.data;
            } else {
              this.voucherCodeError = "";
              this.items = response.items;
              this.data = response.data;
            }
          });
      },
      formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

          const negativeSign = amount < 0 ? "-" : "";

          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;

          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
