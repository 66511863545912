import Errors from './errors';
import Promise from 'promise-polyfill';

export default class Form {

    /**
     * Create a new Form instance.
     *
     * @param {object} data
     */
    constructor(data) {
        this.originalData = data;

        for (let field in data) {
            this[field] = data[field];
        }

        this.errors = new Errors();
        this.request = this.getAxios();
    }

    /**
     * Fetch all relevant data for the form.
     */
    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }


    /**
     * Reset the form fields.
     */
    reset() {
        for (let field in this.originalData) {
            this[field] = '';
        }

        this.errors.clear();
    }


    /**
     * Send a GET request to the given URL.
     * .
     * @param {string} url
     */
    get(url) {
        return this.submit('get', url);
    }

    /**
     * Send a POST request to the given URL.
     * .
     * @param {string} url
     */
    post(url) {
        return this.submit('post', url);
    }


    /**
     * Send a PUT request to the given URL.
     * .
     * @param {string} url
     */
    put(url) {
        return this.submit('put', url);
    }


    /**
     * Send a PATCH request to the given URL.
     * .
     * @param {string} url
     */
    patch(url) {
        return this.submit('patch', url);
    }


    /**
     * Send a DELETE request to the given URL.
     * .
     * @param {string} url
     */
    delete(url) {
        return this.submit('delete', url);
    }


    /**
     * Submit the form.
     *
     * @param {string} requestType
     * @param {string} url
     * @param {boolean} clearFormOnSuccess
     */
    submit(requestType, url, clearFormOnSuccess = true) {
        return new Promise((resolve, reject) => {
            this.request[requestType](App.url + url, this.data())
                .then(response => {
                    this.onSuccess(response.data, clearFormOnSuccess);

                    resolve(response.data);
                })
                .catch(error => {
                    this.onFail(error.response.data);

                    reject(error.response.data);
                });
        });
    }


    /**
     * Handle a successful form submission.
     *
     * @param {object} data
     * @param {boolean} clearFormOnSuccess
     */
    onSuccess(data, clearFormOnSuccess = true) {
      if (clearFormOnSuccess) {
        this.reset();
      }
    }

        /**
     * Handle a failed form submission.
     *
     * @param {object} errors
     */
    onFail({errors}) {
        this.errors.record(errors);
    }

    /**
   * Preprare axios library for form requests
   */
  getAxios () {
    let token = document.head.querySelector('meta[name="csrf-token"]')

    if (token) {
      axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': token.content
      }
    } else {
      // console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
    }

    return axios
  }
}