<template>
    <div class="uservoices with-bgr" v-if="uservoices.length">
      <h2>Teilnehmerstimmen</h2>
      <div class="slider" data-role="vueuservoice-slider" v-if="uservoices.length" ref="slider">
        <div class="uservoice" v-for="(voice, index) in uservoices" :key="index" v-html="voice">
        </div>
      </div>
      <template v-else>
        <loading-animation :text="$t('uservoices.loadvoices', null, locale)" color="#ffffff"/>
      </template>
    </div>
</template>
<script>
import LoadingAnimation from '@Modules/loading-animation/LoadingAnimation'

export default {
  components: {
    LoadingAnimation
  },
  props: {
    amount:{
      type: Number,
      default: 10
    },
    locale: {
      type: String,
      default: 'de'
    }
  },
  data() {
      return {
        uservoices: [],
        loading: true,
      }
  },
  methods: {
      async loadUservoices() {
          let response = await new Form({amount: this.amount, locale: this.locale}).post('/api/uservoices')
          this.loading = false
          this.uservoices = response.results
          this.$nextTick(this.initializeSlider)
      },
      initializeSlider() {
        $(this.$refs.slider).each((index, element) => {
          $(element).slick({
            autoplay: true,
            autoplaySpeed: 5000,
            speed: 1200,
            arrows: false,
            dots: false,
            draggable: true,
            touchMove: true,
            fade: false,
            pauseOnHover: false,
            infinite: true,
            rows: 0,
            prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  arrows: false,
                  dots: false
                }
              }
            ]
          })
        })
      }
  },
  mounted() {
      this.loadUservoices()
  }
}
</script>
<style>

</style>
