import qs from 'qs'
import axios from 'axios'
import moment from 'moment'

export default () => {
  let search = location.search.length
    ? qs.parse(location.search.replace('?', ''))
    : {
      searchterm: null,
      category: null,
      page: 1,
      dateFrom: null,
      dateTo: null,
      ics: null,
      language: null,
      doctype: 1,
      status: "valid",
      origin: null,
      supplier: null,
      run: null,
      limit: null
    }
  return {
    namespaced: true,
    state: {
      searchterm: search.searchterm,
      category: search.category,
      categories: [],
      loading: false,
      results: '',
      topevents: '',
      topproducts: [],
      page: isNaN(parseInt(search.page)) ? 1 : parseInt(search.page),
      numberOfResults: 0,
      limit: search.limit || 10,
      categoryCount: [],
      firstView: true,
      run: search.run,
      filters: {
        dateFrom: search.dateFrom ? search.dateFrom : null,
        dateTo: search.dateTo ? search.dateTo : null,
        ics: search.ics || null,
        language: search.language || null,
        doctype: search.doctype || null,
        status: search.status || null,
        origin: search.origin || null,
        supplier: search.supplier || null,
      }
    },
    mutations: {
      searchterm(state, {searchterm}) {
        state.searchterm = searchterm
      },
      category(state, category) {
        state.category = category
      },
      categories(state, {categories}) {
        state.categories = categories
      },
      loading(state, loading) {
        state.loading = loading
      },
      results(state, results) {
        state.results = results
      },
      page(state, page) {
        state.page = page
      },
      numberOfResults(state, count) {
        state.numberOfResults = count
      },
      limit(state, limit) {
        state.limit = limit
      },
      run(state, run) {
        state.run = run
      },
      categoryCount(state, count) {
        state.categoryCount = count
      },
      filters(state, {filters}) {
        state.filters = filters
      },
      firstView(state, firstview) {
        state.firstView = firstview
      },
      topevents(state, events) {
        state.topevents = events
      },
      topproducts(state, products) {
        state.topproducts = products
      }
    },
    actions: {
      setRun({commit}, payload) {
        commit('run', payload)
      },
      setLimit({commit}, payload) {
        commit('limit', payload)
      },
      setSearchterm({commit, dispatch}, payload) {
        commit('firstView', false)
        commit('searchterm', payload)
        // dispatch('refresh')
      },
      setCategory({commit, dispatch}, payload) {
        commit('firstView', false)
        commit('page', 1)
        commit('category', payload)
        // dispatch('refresh')
      },
      setPage({commit, dispatch}, payload) {
        commit('firstView', false)
        commit('page', payload)
        dispatch('refresh')
      },
      setDateFrom({commit}, payload) {
        commit('dateFrom', payload)
      },
      setDateTo({commit}, payload) {
        commit('dateTo', payload)
      },
      setIcs({commit}, payload) {
        commit('ics', payload)
      },
      setOrigin({commit}, payload) {
        commit('origin', payload)
      },
      setSupplier({commit}, payload) {
        commit('supplier', payload)
      },
      updateFilters({commit}, payload) {
        commit('firstView', false)
        commit('filters', payload)
      },
      setCategoryWithoutRefresh({commit}, payload) {
        // commit('firstView', false)
        commit('page', 1)
        commit('category', payload)
      },
      setResults({commit}, payload) {
        commit('results', payload)
      },
      setTopProducts({commit}, payload) {
        commit('topproducts', payload)
        // commit('loading', false)
      },
      async fetchCategories({commit, dispatch}) {
        let response = await axios.get(App.url+'/api/categories')
        commit('categories', {categories: response.data})
        // dispatch('refresh')
      },
      async refresh({commit, getters, dispatch}, locale) {
        let path = getters.path + getters.qs
        window.history.replaceState({ path }, '', path)
        commit('loading', true)
        // let response = await axios.post(App.url+"/api/search/", getters.search)
        let postValue = getters.emptySearch

        if (getters.category == 15 
          || getters.category == 13 || getters.category == 14) {
          postValue = getters.search
        }
        let url = "/api/search"

        if (locale == 'en') {
          url = "/en/api/search"
        }

        let response = await new Form(postValue).post(url)

        if(response.count > 0 && postValue.page > Math.ceil(response.count / postValue.limit)){
          commit('firstView', false)
          commit('page', 1)
          dispatch('refresh')
        }
        
        commit('loading', false)
        commit('results', response.results)
        commit('numberOfResults', response.count)
        commit('categoryCount', response.categoryCount)
      },
      async fetchTopEvents({commit}, locale) {
        let url = "/api/topevents"

        if (locale == 'en') {
          url = "/en/api/topevents"
        }
        commit('loading', true)
        let response = await new Form().get("/api/topevents")
        commit('loading', false)
        commit('topevents', response.topevents)
      }
    },
    getters: {
      /**
       * Current url location without searchterm string
       */
      path: (state, getters) => {
        let {
          protocol,
          host,
          pathname
        } = window.location

        return protocol + '//' + host + pathname
      },/**
      * Parsed array for server side filtering
      */
      search: state => {
       return {
         searchterm: state.searchterm,
         category: state.category,
         page: state.page,
         dateFrom: state.filters.dateFrom ? moment(state.filters.dateFrom).format('YYYY-MM-DD') : null,
         dateTo: state.filters.dateTo ? moment(state.filters.dateTo).format('YYYY-MM-DD') : null,
         ics: state.filters.ics,
         language: state.filters.language,
         doctype: state.filters.doctype,
         status: state.filters.status,
         origin: state.filters.origin,
         supplier: state.filters.supplier,
         run: state.run,
         limit: state.limit
       }
     },
     emptySearch: state => {
      return {
        searchterm: state.searchterm,
        category: state.category,
        page: state.page,
        dateFrom: null,
        dateTo: null,
        ics: null,
        language: null,
        doctype: null,
        status: 'valid',
        origin: null,
        supplier: null,
        run: null,
        limit: state.limit
      }
     },
     category: state => {
       return state.category
     },
     /**
      * Generates searchterm string from selected filters and updates location
      */
     qs: (state, getters) => {
       return qs.stringify(
         { ...getters.search },
         {
           arrayFormat: 'brackets',
           encodeValuesOnly: true,
           addQueryPrefix: true
         }
       )
     },

     pages: ({numberOfResults, limit}) => Math.ceil(numberOfResults / limit)
    }
  }
}