<template>
    <section id='login-user'>
        <div class="row">
            <div class="col-sm-12 blackBar">
                <div class="title">
                    {{$t("login.login", null, locale)}}
                    <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                </div>
            </div>
        </div>
        <div class="row backgroundGray">
            <div class="row">
                <div class="col-sm-6">
                    <div :class="formGroupClass('email')">
                        <div class="form-group">
                            <input class="form-control" id="email" type="email" name="email" :placeholder="$('register.email', null, locale)" v-model="form.email" required="required"/>
                            <div class="help-block" v-if="form.errors.has('email')">
                                <strong>{{ form.errors.get('email') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div :class="formGroupClass('password')">
                        <div class="form-group">
                            <input class="form-control" id="password" type="password" name="password" :placeholder="$('register.password', null, locale)" v-model="form.password" required="required"/>
                            <div class="help-block" v-if="form.errors.has('password')">
                                <strong>{{ form.errors.get('password') }}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
  props: {
    locale: {
      type: String,
      default: 'de'
    }
  },

    data() {
        return {
            loading: false,
            form: new Form({
                email: null,
                password: null,
            })
        };
    },

    methods: {
        formGroupClass(name) {
            return this.form.errors.has(name) ? 'form-group has-error' : 'form-group';
        }
    },

    created() {

        //- register user
        App.event.listen('login.send', () => {
            this.loading = true;
            this.form.post('/login').then(response => {
                this.loading = false;
                App.event.fire('login.success', response);
            }).catch(errors => {
                this.loading = false;
                App.event.fire('login.failed', errors);
            });
        });

    }
}
</script>
