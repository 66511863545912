'use strict';

import Vmask from 'v-mask';
import Wirecard from './components/Wirecard.vue';

export default class {

  constructor() {
    Vue.use(Vmask);
    // Register component globaly
    Vue.component('wirecard', Wirecard);

  }
}
