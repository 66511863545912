<template>
  <div class="row event-overview">
    <div class="col-md-12">
      <loading-animation :text="$t('subscriptions.learnmore', null, locale)" v-if="isLoading"></loading-animation>
    </div>

    <div class="col-12 col-sm-6 col-md-3" style="margin-bottom: 25px;" v-for="subscription in subscriptions" :key="subscription.id">
      <div class="single-event">
        <a :href="'/shop/product/' + subscription.seo" v-if="subscription.seo && subscription.image">
          <div class="event-image" :style="backgroundStyle(subscription.image)"> </div>
        </a>
        <a :href="subscription.link" v-else-if="subscription.link && subscription.image">
          <div class="event-image" :style="backgroundStyle(subscription.image)"> </div>
        </a>
        <h2 class="title">{{ subscription.description }}<template v-if="subscription.price > 0"> - € {{ subscription.price }}</template></h2>
        <div class="description" v-html="subscription.short_description"></div>
        <a :href="'/shop/product/' + subscription.seo" class="productlink" v-if="subscription.seo">
          {{$t('subscriptions.join', null, locale)}}
        </a>
        <a :href="subscription.link" class="productlink" v-else-if="subscription.link">
          {{$t('subscriptions.join', null, locale)}}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import LoadingAnimation from '../../event-search/components/LoadingAnimation'

  export default {
      props: {
          locale: {
              type: String,
              default: 'de'
          },
      },
    components: {
      LoadingAnimation
    },
    data() {
      return {
        subscriptions: [],
        isLoading: true
      }
    },
    methods: {
      backgroundStyle: function($link) {
        return 'background-image: url(\''+$link+'\');';
      }
    },
    created() {
      new Form().get('/api/subscriptions')
        .then((response) => {
          this.subscriptions = response.data;
          this.isLoading = false;
        }).catch((e) => {
        this.isLoading = false;
        this.subscriptions = [];
      });
    },
  }
</script>