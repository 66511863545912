<template>
  <div class="search-form">
    <form @submit.prevent="searchFor()">
      <div class="input-group">
        <div class="flex-fill position-relative">
          <input type="text" class="form-control searchinput" v-model="value" @keyup="suggest"
                 :placeholder="$t('searchquery.searchplaceholder', null, locale)">
          <div class="suggest-box position-absolute" :class="{'open': (suggestOpen && suggestCount > 0 && value.length > 2)}">
            <ul v-if="!suggestionLoading && suggestOpen && suggestions && suggestions.length > 0">
                <li class="searchfor">
                  <a @click="searchFor()">
                    <span v-html="$t('searchquery.searchfor', {value}, locale)"></span>
                  </a>
                </li>
                <template v-if="suggestCount > 0">
                  <li v-for="suggestion in suggestions" :key="suggestion.id" class="item">
                    <a :href="suggestion.url">{{ suggestion.description }}</a>
                  </li>
                </template>
                <li v-else>{{$t("searchquery.noresults", null, locale)}}</li>
                <!-- <li v-if="suggestCount > 0" class="allresults"><a @click="searchFor()">Alle {{suggestCount}} Ergebnisse anzeigen</a></li> -->
            </ul>
            <div v-else>
              <div class="d-flex align-items-center justify-content-center">
                <svg class="lds-spinner" width="30" height="30" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <g transform="rotate(0 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(30 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(60 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(90 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(120 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(150 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(180 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(210 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(240 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(270 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(300 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                  <g transform="rotate(330 50 50)">
                    <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                      <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                    </rect>
                  </g>
                </svg>
                {{$t("searchquery.loadingresults", null, locale)}}
              </div>
            </div>
          </div>
        </div>
        <div class="input-group-append">
          <button class="btn btn-primary searchbutton" type="submit">
            <i class="fas fa-search"></i> {{$t("searchquery.search", null, locale)}}
          </button>
        </div>
      </div>
    </form>

    <button class="btn btn-primary mt-2" id="resetSearch" @click.prevent="clearSearchtermFromCookie()">
      <i class="fa fa-redo"></i>
      {{$t("searchquery.reset", null, locale)}}
    </button>

    <div class="row">
      <div class="col-md-4" id="left-search">
        <div class="row no-gutters">
          <div class="col-md-12 left-column content-page">
            <div class="oveacademyfilters">
              <h2>{{$t('searchquery.header', null, this.locale)}}</h2>
              <template v-if="categories">
                <ul>
                  <li :class="{'active': !category}">
                    <a href="#" @click.prevent="chooseCategory(null)">
                      {{$t('searchquery.all', null, this.locale)}}
                    </a>
                  </li>
                  <li v-for="cat in categories" :key="cat.id" :class="{'active': cat.id == category}">
                    <a href="#" v-if="cat.id != 11 && cat.id != 1" @click.prevent="chooseCategory(cat.id)">
                      {{ $t("categories.category_" + cat.id, null, locale) }}
                    </a>
                    <a href="/ove-network/mitgliedschaft" v-else-if="cat.id != 11">
                      {{ $t("categories.category_" + cat.id, null, locale) }}
                    </a>
                    <a href="/oveacademy" v-else>
                      {{ $t("categories.category_" + cat.id, null, locale) }}
                    </a>
                  </li>
                </ul>
              </template>
              <p v-else>
                <i class="fa fa-spin fa-spinner"></i>
                {{$t("searchquery.loadingcategories", null, locale)}}
              </p>
              <select @change="setItemLimit" v-model="limit" v-if="false">
                <option value="10" selected>10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 ">
        <div class="detail-search open">
          <div class="detail-search-body">
            <div class="row no-gutters empty-category-slider" v-if="!category">
              <div class="col-12" v-if="topprods.length > 0">
                <h2>{{$t('searchquery.topproducts', null, this.locale)}}</h2>
                <!-- <slick ref="slick" :options="slickOptions"> -->
                <div class="row" v-html="getTopProdHtml">
                  <!-- <div v-for="(tprod, index) in topprods" :key="'tprod'+index" v-html="tprod.html"></div> -->
                </div>
                <!-- </slick> -->
              </div>
              <div class="col-12" v-else>
                <h2>{{$t('searchquery.topproducts', null, this.locale)}}</h2>
                <p>
                  <i class="fa fa-spin fa-spinner"></i>
                  {{$t("searchquery.loadingproducts", null, locale)}}
                </p>
              </div>
            </div>
            <!-- Normen -->
            <div class="row no-gutters detail-filters" v-if="category == 15">
              <div class="col-12">
                <h2>
                  {{$t("searchquery.normsearch", null, locale)}}
                </h2>
              </div>
              <div class="col-12 filters">
                <div class="row">
                  <div class="col-12 col-md-6 order-2 order-md-4 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="status" class="col-12 col-md-4">
                        {{$t("searchquery.status", null, locale)}}
                      </label>
                      <div class="col-12 col-md-8">
                        <select class="form-control" id="status" v-model="localFilters.status">
                          <option :value="null">{{$t("searchquery.all", null, locale)}}</option>
                          <option value="valid" selected>{{$t("searchquery.valid", null, locale)}}</option>
                          <option value="invalid">{{$t("searchquery.invalid", null, locale)}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 order-1 order-md-1 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="documenttype" class="col-12 col-md-4">{{$t("searchquery.doctype", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                        <select class="form-control" id="documenttype" v-model="localFilters.doctype">
                          <option :value="null">{{$t("searchquery.all", null, locale)}}</option>
                          <option v-for="doctype in doctypes" :key="doctype.key" :value="doctype.key">{{ doctype.name[locale] }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 order-3 order-md-2 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="language" class="col-12 col-md-4">{{$t("searchquery.language", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                        <select class="form-control" id="language" v-model="localFilters.language">
                          <option :value="null" selected>{{$t("searchquery.all", null, locale)}}</option>
                          <option v-for="language in languages" :key="language.key" :value="language.key">{{ language.name[locale] }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 order-4 order-md-2 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="origin" class="col-12 col-md-4">{{$t("searchquery.publisher", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                        <select class="form-control" id="origin" v-model="localFilters.origin">
                          <option :value="null" selected>{{$t("searchquery.all", null, locale)}}</option>
                          <option v-for="originItem in origins" :key="originItem.key" :value="originItem.key">{{ originItem.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 order-5 order-md-7 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="fachgebiet" class="col-12 col-md-4">{{$t("searchquery.specialfieldics", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                        <input type="text" class="form-control" id="fachgebiet" v-model="localFilters.ics" v-on:keyup.enter="applyFilters">
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 order-6 order-md-5 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="ausgabevon" class="col-12 col-md-4">{{$t("searchquery.issuedatefrom", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                        <date-pick
                            v-model="localFilters.dateFrom"
                            :weekdays="getCalendarWeekdays"
                            :months="getCalendarMonths"
                            :displayFormat="datePickOptions.displayFormat"></date-pick>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 order-7 order-md-6 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="ausgabebis" class="col-12 col-md-4">{{$t("searchquery.issuedateto", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                        <date-pick
                            v-model="localFilters.dateTo"
                            :weekdays="getCalendarWeekdays"
                            :months="getCalendarMonths"
                            :displayFormat="datePickOptions.displayFormat"></date-pick>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4"></div>
                  <div class="col-12 col-md-4 align-self-center">
                    <button type="button" class="btn btn-block btn-primary" @click="applyFiltersReset()">
                      {{$t("searchquery.resetfilters", null, locale)}}
                    </button>
                  </div>
                  <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-block btn-primary" @click="applyFilters()">
                      {{$t("searchquery.applyfilters", null, locale)}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Mitgliedschaften -->
            <div class="row no-gutters detail-filters" v-if="category == 1">
              <div class="col-12">
                <h2>
                  {{$t("searchquery.membership", null, locale)}}
                </h2>
              </div>
              <div class="col-12 filters">
                Über 1.400 Expert/innen und Studierende, 300 Industriebetriebe, Klein- und mittelständische Unternehmen, 26 Energieversorgungsunternehmen und Partnerverbände aus der Elektrotechnik und Informationstechnik und darauf aufbauenden Richtungen nutzen bereits die Vorteile einer Mitgliedschaft im OVE Österreichischer Verband für Elektrotechnik.<br>
<br>
                Profitieren auch Sie von den Mitgliedervorteilen und der Zugehörigkeit zu diesem österreichweiten Branchennetzwerk und gestalten Sie das Verbandsgeschehen aktiv mit!
              </div>
            </div>
            <!-- Fachliteratur -->
            <div class="row no-gutters detail-filters" v-if="category == 13">
              <div class="col-12">
                <h2>
                  {{$t("searchquery.technicalliterature", null, locale)}}
                </h2>
              </div>
              <div class="col-12 filters">
                <div class="row">
                  <div class="col-12 col-md-6 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="ausgabevon" class="col-12 col-md-4">{{$t("searchquery.issuedatefrom", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                        <date-pick
                            v-model="localFilters.dateFrom"
                            :weekdays="getCalendarWeekdays"
                            :months="getCalendarMonths"
                            :displayFormat="datePickOptions.displayFormat"></date-pick>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="ausgabebis" class="col-12 col-md-4">{{$t("searchquery.issuedateto", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                        <date-pick
                            v-model="localFilters.dateTo"
                            :weekdays="getCalendarWeekdays"
                            :months="getCalendarMonths"
                            :displayFormat="datePickOptions.displayFormat"></date-pick>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 filter-item">
                    <div class="form-group row no-gutters align-items-center">
                      <label for="supplier" class="col-12 col-md-4">{{$t("searchquery.supplier", null, locale)}}</label>
                      <div class="col-12 col-md-8">
                          <select class="form-control" id="supplier" v-model="localFilters.supplier">
                            <option :value="null" selected>{{$t("searchquery.all", null, locale)}}</option>
                            <option v-for="supplierItem in suppliers" :key="supplierItem.key" :value="supplierItem.key">{{ supplierItem.name }}</option>
                          </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-4"></div>
                  <div class="col-12 col-md-4 align-self-center">
                    <button type="button" class="btn btn-block btn-primary" @click="applyFiltersReset()">
                      {{$t("searchquery.resetfilters", null, locale)}}
                    </button>
                  </div>
                  <div class="col-12 col-md-4">
                    <button type="button" class="btn btn-block btn-primary" @click="applyFilters()">
                      {{$t("searchquery.applyfilters", null, locale)}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Wandtafeln -->
            <div class="row no-gutters detail-filters" v-if="category == 14">
              <div class="col-12">
                <h2>
                  {{$t("searchquery.blackboards", null, locale)}}
                </h2>
              </div>
              <div class="col-12 filters" >
                  <div class="row">
                    <div class="col-12 col-md-6 filter-item">
                      <div class="form-group row no-gutters align-items-center">
                        <label for="ausgabevon" class="col-12 col-md-4">{{$t("searchquery.issuedatefrom", null, locale)}}</label>
                        <div class="col-12 col-md-8">
                          <date-pick
                            v-model="localFilters.dateFrom"
                            :weekdays="getCalendarWeekdays"
                            :months="getCalendarMonths"
                            :displayFormat="datePickOptions.displayFormat"></date-pick>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 filter-item">
                      <div class="form-group row no-gutters align-items-center">
                        <label for="ausgabebis" class="col-12 col-md-4">{{$t("searchquery.issuedateto", null, locale)}}</label>
                        <div class="col-12 col-md-8">
                          <date-pick
                            v-model="localFilters.dateTo"
                            :weekdays="getCalendarWeekdays"
                            :months="getCalendarMonths"
                            :displayFormat="datePickOptions.displayFormat"></date-pick>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6 filter-item">
                      <div class="form-group row no-gutters align-items-center">
                        <label for="supplier2" class="col-12 col-md-4">{{$t("searchquery.supplier", null, locale)}}</label>
                        <div class="col-12 col-md-8">
                          <select class="form-control" id="supplier2" v-model="localFilters.supplier">
                            <option :value="null" selected>{{$t("searchquery.all", null, locale)}}</option>
                            <option v-for="supplierItem in suppliers" :key="supplierItem.key" :value="supplierItem.key">{{ supplierItem.name }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4"></div>
                    <div class="col-12 col-md-4 align-self-center">
                      <button type="button" class="btn btn-block btn-primary" @click="applyFiltersReset()">
                        {{$t("searchquery.resetfilters", null, locale)}}
                      </button>
                    </div>
                    <div class="col-12 col-md-4">
                      <button type="button" class="btn btn-block btn-primary" @click="applyFilters()">
                        {{$t("searchquery.applyfilters", null, locale)}}
                      </button>
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import {createNamespacedHelpers} from 'vuex'
    import {debounce} from 'lodash'
    // import {en, de} from 'vuejs-datepicker/dist/locale'
    import moment from 'moment'
    import Slick from 'vue-slick'
    import DatePick from 'vue-date-pick';
    import 'vue-date-pick/dist/vueDatePick.css';

    const {mapState, mapActions} = createNamespacedHelpers('filters')

    export default {
        components: {
            Slick,
            DatePick
        },

        props: {
            locale: {
                type: String,
                default: 'de'
            }
        },

        data() {
            return {
                value: this.searchterm,
                suggestions: [],
                suggestionLoading: false,
                suggestCount: 0,
                suggestOpen: false,
                limit: 10,
                // en: en,
                // de: de,
                mondayFirst: true,
                calendarOptions: {
                  mondayFirst: true,
                  fullMonthName: false,
                  calendarButton: true,
                  clearButton: true,
                  c: true,
                  locale: {
                    days: {
                      de: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
                      en: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
                    },
                    months: {
                      de: ['Jänner', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                      en: ['Jannuary', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
                    }
                  }
                },
                datePickOptions: {
                  displayFormat: 'YYYY-MM-DD',
                },
                c: true,
                detailSearchOpen: true,
                topprods: [],
                events: {
                    from: null,
                    to: null
                },
                defaults: {
                    default:{
                      dateFrom: null,
                      dateTo: null,
                      doctype: null,
                      status: null,
                      language: null,
                      ics: null,
                      origin: null,
                      supplier: null
                    },
                    15:{
                      dateFrom: null,
                      dateTo: null,
                      doctype: 1,
                      status: "valid",
                      language: null,
                      ics: null,
                      origin: null,
                      supplier: null
                    }
                },
                localFilters: {
                    dateFrom: null,
                    dateTo: null,
                    ics: null,
                    language: null,
                    doctype: null,
                    status: null,
                    origin: null,
                    supplier: null
                },
                languages: [
                    {
                        key: 3,
                        name: {
                            de: "Deutsch",
                            en: "German"
                        }
                    },
                    {
                        key: 4,
                        name: {
                            de: "Englisch",
                            en: "English"
                        }
                    }
                ],
                origins: [
                    {
                        key: 1,
                        name: "OVE"
                    },
                    {
                        key: 3,
                        name: "IEC"
                    },
                    {
                        key: 4,
                        name: "IEEE"
                    },
                    {
                        key: 2,
                        name: "ASI"
                    },
                ],
                suppliers: [
                    {
                        key: 1,
                        name: "OVE"
                    },
                    {
                        key: 2,
                        name: "OVE/Oesterreichs Energie"
                    },
                    {
                        key: 3,
                        name: "Oesterreichs Energie"
                    },
                    {
                        key: 4,
                        name: "VDE Verlag"
                    },
                    {
                        key: 5,
                        name: "VDE/OVE"
                    },
                      {
                        key: 6,
                        name: "Kuratorium für Elektrotechnik (KFE)"
                    },
                ],
                doctypes: [
                    {
                        key: 1,
                        name: {
                            de: "Normen / Richtlinien",
                            en: "Standard/Technical Specification"
                        }
                    },
                    {
                        key: 2,
                        name: {
                            de: "Entwurf",
                            en: "Draft standard"
                        }
                    }
                ],
                slickOptions: {
                  slidesToShow: 3,
                  dots: true,
                  arrows: false,
                  autoplay: false,
                  autoplaySpeed: 2000
                },
            }
        },

        computed: {
            ...mapState(['searchterm', 'categories', 'category', 'categoryCount', 'filters', 'firstView', 'run']),
            suggestContent: {
              get() {
                if (this.suggestionLoading) {
                  return "<p>" + $t("searchquery.loadingsuggestions", null, this.locale) + "</p>"
                }
                if (this.suggestions && this.suggestions.length > 0) {
                  let html = '<ul>'
                  for (let i = 0; i < this.suggestions.length; i++) {
                      let sug = this.suggestions[i]
                      html = html + '<li><a href="' + sug.url + '">' + sug.name + '</a></li>'
                  }
                  html = html + '</ul>'
                  return html
                } else {
                  return '<p>' + $t("searchquery.nosuggestions", null, this.locale) + '</p>'
                }
              },
              set(value) {
                  this.suggestions = value
              }
            },
            totalCount() {
              let count = 0
              for (let key in this.categoryCount) {
                  count += this.categoryCount[key]
              }
              return count
            },
            computedCategory: {
              get() {
                  return this.category
              },
              set(value) {
                  this.chooseCategory(value)
              }
            },
            getCalendarLang() {
              if (this.locale == 'en') {
                  return this.en
              }
              return this.de
            },

            getCalendarWeekdays() {
              if (this.locale == 'en') {
                return this.calendarOptions.locale.days.en;
              }
              return this.calendarOptions.locale.days.de;
            },
            getCalendarMonths() {
              if (this.locale == 'en') {
                return this.calendarOptions.locale.months.en;
              }
              return this.calendarOptions.locale.months.de;
            },
            getTopProdHtml() {
              let returnValue = '';
              for(let i = 0; i < this.topprods.length; i++) {
                returnValue = returnValue + this.topprods[i].html
              }
              return returnValue;
            }
        },

        methods: {
            ...mapActions(['setSearchterm', 'refresh', 'fetchCategories', 'setLimit', 'setCategory', 'updateFilters', 'resetFilters', 'setCategoryWithoutRefresh', 'setTopProducts', 'setLoading', 'setRun', 'setResults']),
            suggest: debounce(async function (event) {
              if (event.key == 'Enter') {
                  return;
              }
              if (this.value && this.value.length > 2) {
                this.suggestOpen = true
                this.suggestionLoading = true
                let response = await new Form({searchterm: this.value, locale: this.locale}).post("/api/suggest")
                this.suggestionLoading = false
                this.suggestions = response.results
                this.suggestCount = response.count
              }
            }, 1000),
            searchFor() {
              this.suggestOpen = false
              this.detailSearchOpen = false
              this.setSearchterm({searchterm: this.value})
              this.applyFilters()

              this.$cookie.set('searchterm', this.value, 1);

                // scroll to search bar
              // document.querySelector('.searchinput').scrollIntoView({behavior: 'smooth'})
              document.querySelector('.results.row').scrollIntoView({behavior: 'smooth'})
            },
            customFormatter(date) {
              moment.locale('de')
              return moment(date).format('Do MMMM YYYY')
            },
            setItemLimit() {
                this.setLimit(this.limit);
                this.applyFilters();
            },
            applyFilters() {
              this.applySearchTerm();
              this.updateFilters({filters: {...this.localFilters}})
          
              this.refresh(this.locale)

              this.$cookie.set('filter', window.location.search, 1);
            },
            applySearchTerm() {
              this.setSearchterm({searchterm: this.value})
            },
            applyFiltersReset() {
              this.applyFiltersWithoutRefresh()
              this.refresh(this.locale)

              this.$cookie.set('filter', window.location.search, 1);
            },
            applyFiltersWithoutRefresh() {
              // this.detailSearchOpen = false
              let defaultValues = this.defaults.default
              if (this.category != null && this.category.toString() in this.defaults) {
                defaultValues = this.defaults[this.category];
              }
              this.localFilters.dateFrom = defaultValues.dateFrom
              this.localFilters.dateTo = defaultValues.dateTo
              this.localFilters.ics = defaultValues.ics
              this.localFilters.supplier = defaultValues.supplier
              this.localFilters.language = defaultValues.language
              this.localFilters.doctype = defaultValues.doctype
              this.localFilters.status = defaultValues.status
              this.localFilters.origin = defaultValues.origin
              // this.setCategoryWithoutRefresh(null)
              this.updateFilters({filters: {...this.localFilters}})
            },
            toggleDetailSearch() {
              // this.eventFilterOpen = false
              this.detailSearchOpen = !this.detailSearchOpen
            },
            chooseCategory($catId) {
              // this.detailSearchOpen = false
              this.detailSearchOpen = true;
              this.value = "";
              this.setCategory($catId)
              this.applySearchTerm()
              this.applyFiltersWithoutRefresh()
              this.refresh(this.locale)

              this.$cookie.set('filter', window.location.search, 1);
            },
            async fetchTopProducts() {
              let topproducts = await new Form({}).get('/' + this.locale + '/api/topproducts')
              this.topprods = topproducts.results
              this.setTopProducts(topproducts.sortedProducts)
            },
            reInitSlick() {
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },
          clearSearchtermFromCookie() {
              this.$cookie.set('searchterm', '', 1);
              this.setSearchterm({searchterm: ''})
              this.value = '';
              // remount
              this.setResults('');
            }

        },

        mounted() {
            this.fetchCategories()
            this.fetchTopProducts()
            this.localFilters = {...this.filters}
            this.value = this.searchterm

          if (this.value == null || (this.value != null && this.value.trim() === '')) {
            if (this.$cookie.get('searchterm') != null && this.$cookie.get('searchterm').trim() !== '') {
              this.value = this.$cookie.get('searchterm');
              this.setRun(true);
            }
          }
          
            if (this.run != null) {
                this.applyFilters()
            }
        },
        created() {
          document.body.addEventListener('click', (event)=> {
            const element = event.target.closest(".suggest-box");
            if (element === null) {
              this.suggestOpen = false;
            }
          })
          App.event.listen('selectCategory', (response) => {
            this.chooseCategory(response)
          })
        }
    }
</script>

<style scoped>

  h2 {
    font-family: "Roboto";
    font-size: 14px;
    font-size: .875rem;
    margin: 0;
    margin-bottom: 14px;
    margin-bottom: .875rem;
    padding-bottom: 7px;
    padding-bottom: .4375rem;
    text-transform: uppercase;
  }

  .detail-search-header h2  {
    margin-bottom: 0;
  }

  #shop .form-control.searchinput, #shop .btn-primary.searchbutton {
    height: 3rem;
    padding: .5rem .875rem;
  }

  #shop .extendedbuttons {
    font-size: 150%;
    display: flex;
    align-items: center;
  }

  #shop .extendedbuttons a {
    padding: 0.435rem;
    color: #7885a6;
  }
  
  #left-search {
    margin-top: .875rem;
  }

  #left-search ul a {
    color: #fff;
  }

  #left-search ul li.active {
    background-color: #f1f1f1;
  }

  #left-search ul li.active a {
    color: #212529;
  }

  .suggest-box li:not('searchfor') {
    border-bottom: solid 1px #ddd;
    line-height: 25px;
  }

  .suggest-box li:not('searchfor') {
      border-bottom: none;
  }
</style>
