<template>
    <div class="event-add-to-cart add-to-cart">
        <div v-if="type == 'detail'" :class="['row', 'no-gutters', {hasSize:hasOptions}]">
            <div class="col-3">
                <spinner @change="updateQty" :minorder="1" :pstock="9"></spinner>
            </div>
            <div class="col-9">
                <button class="btn btn-primary" @click="submit" :disabled="loading" >
                    <i v-if="loading" class="fa fa-spin fa-spinner"></i>
                    <i v-if="!loading" class="fas fa-shopping-bag"></i>
                    <slot name="name">In den Warenkorb</slot>
                </button>
            </div>
        </div>
        <div v-if="type == 'overview'" :class="{hasSize:hasOptions}">
            <div class="basket row no-gutters">
                <!-- <div class="col-6 text-center">Download</div> -->
                <div class="col-6 amount">
                    <spinner @change="updateQty" :minorder="1" :pstock="9">
                        <template slot="minus">-</template>
                        <template slot="plus">+</template>
                    </spinner>
                </div>
                <div class="col-6 text-center">
                    <button @click="submit" :disabled="loading" class="btn active">
                        <i v-if="loading" class="fa fa-spin fa-spinner"></i>
                        <i v-if="!loading" class="fas fa-shopping-bag"></i>
                    </button>
                </div>
            </div>        
        </div>
    </div>
</template>
<script>
import Vue from "vue";

export default {
  props: {
      pid: {
        type: [String, Number],
        required: true,
        default: null
      },
      pseo: {
        type: [String],
        default: null
      },
      type: {
        type: [String],
        default: 'overview'
      },
      options: {
          type: Array,
          default: function () { return [] }
      },
      reducedprice: {
          type: [String, Number],
          required: true
      },
      reducedamount: {
          type: [String, Number],
          required: true
      },
      normalprice: {
          type: [String, Number],
          required: true
      },
    participating: {
        type: Boolean,
        default: false,
        required: false
    }
  },

  data() {
      return {
          qty: 1,
          loading: false,
          selectedArtNr: null,
          reducedpriceData: this.reducedprice,
          normalpriceData: this.normalprice,
          isParticipating: this.participating
      }
  },

  computed: {
      coptions() {
          let $returnValue = [];
          if (this.hasOptions) {
            let $options = this.options;
            $options.forEach(element => {
                element["ShopProductOption.options"].forEach(opt => {
                    $returnValue.push({name: (element["ShopProductOption.size"] + " - " + decodeURIComponent(opt.color)).toUpperCase(), art: opt.artikelnr});
                });
            });
          }
          return $returnValue;
      },
      firstCoption() {
          return this.coptions.slice().shift();
      },
      selectedOption() {
          let option = [];
          if (this.hasOptions) {
              this.options.forEach(element => {
                  element["ShopProductOption.options"].forEach(opt => {
                      if (opt.artikelnr == this.selectedArtNr) {
                          option = {
                              Size: element["ShopProductOption.size"],
                              ArticleNr: opt.artikelnr,
                              Color: decodeURIComponent(opt.color)
                          }
                      }
                  })
              })
          }
          return option;
      },
      hasOptions() {
          return (this.options.length > 0);
      }
  },

  mounted() {
      if(this.hasOptions) {
          this.selectedArtNr = this.firstCoption.art;
      } 
      
      App.event.listen('cart.instantregistration.completed', () => {
        this.isParticipating = true;
      });
  },

  created(){
    App.event.listen('profile.loaded', (user) => {
      if ( !this.isParticipating) {
        if(document.cookie.match('(^|;) ?register_back_product=([^;]*)(;|$)')){
          this.submit()
        }
      }
    })
  },
  methods: {
        onSelected({currentTarget}) {
            window.location = App.url+'/product/' + this.pseo;
        },

      updateQty(qty) {
        this.qty = qty;
      },

      submit() {

        if (this.isParticipating) {
          return;
        }

        let priceElement = document.querySelector('input[name="price"]:checked');
        let costid = null;
        if (priceElement) {
          this.reducedpriceData = priceElement.value;
          this.normalpriceData = priceElement.value;
          costid = priceElement.dataset.costId;
        }

        let specialOptions = document.querySelectorAll("input[name='specialprice[]']:checked");
        if (typeof specialOptions !== 'undefined') {
          let temp = [];
          for (let specialOption of specialOptions) {
            let option = {
              id: specialOption.value,
              name: specialOption.dataset.name,
              price: specialOption.dataset.price
            };
            temp.push(option);
          }
          specialOptions = temp;
        } else {
          specialOptions = [];
        }

        let requiredOptions = document.querySelectorAll("input[type='radio'].requiredPrice");
        if (typeof requiredOptions !== 'undefined') {
          let temp = [];
          for (let requiredOption of requiredOptions) {
            let checkedRequiredOption = document.querySelector("input[name='" + requiredOption.name + "']:checked")
            if (checkedRequiredOption == null){
              document.querySelector('.requiredPricesError').innerHTML = '* Bitte wählen Sie für alle Zusatzleistungen eine Option';
              document.getElementsByClassName('requiredPricesError')[0].scrollIntoView({ left: 0, block: 'center', behavior: 'smooth' });
              return false;
            }

            if(checkedRequiredOption.value == 1) {
              let option = {
                id: checkedRequiredOption.dataset.id,
                name: checkedRequiredOption.dataset.name,
                price: checkedRequiredOption.dataset.price
              };
              temp.push(option);
            }
          }
          requiredOptions = temp;
          document.querySelector('.requiredPricesError').innerHTML = '';
        } else {
          requiredOptions = [];
        }

        // this.loading = true;
        App.event.fire('cart.instantregistration.inserted', {
          pid: this.pid,
          qty: this.qty,
          pstock: this.pstock,
          options:this.selectedOption,
          reducedprice: parseFloat(this.reducedpriceData),
          reducedamount: parseFloat(this.reducedamount),
          normalprice: parseFloat(this.normalpriceData),
          specialOptions: specialOptions,
          requiredOptions: requiredOptions,
          costid: costid,
        })
        
        // new Form({
        //     pid: this.pid,
        //     qty: this.qty,
        //     pstock: this.pstock,
        //     options: this.selectedOption
        // })
        // .post('/api/cart/add')
        // .then((response) => {
        //     this.loading = false;
        //     if(response.success) {
        //         App.event.fire('cart.item.inserted', response.item);
        //         App.event.fire('cart.updated', response.data);
        //     } else {
        //         swal("", "Sie wollen mehr Produkte in den Warenkorb hinzufügen als derzeit lagernd sind.",  "error");
        //     }
            
        // }).catch(errors => {
        //     this.loading = false;
        // });
      }

  }
}
</script>
