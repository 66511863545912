<template>
    <section id="checkout-user-handler">
        <br><br>
        <div class="row justify-content-end">
            <div class="col-sm-3 nopadding-left text-center mb-1">
                <a class="btn btn-block btn-primary btn-shadow" href="/shop/" style="text-decoration: none; min-width: 180px;">{{$t("checkout.userhandler.backtoshop", null, locale)}}</a>
            </div>
            <div class="col-sm-3 nopadding-left nopadding-right text-center mb-1">
                <button class="btn btn-block btn-primary btn-shadow" type="button" @click="showLogin()">{{$t("checkout.userhandler.buywithaccount", null, locale)}}</button>
            </div>
            <div class="col-sm-3 nopadding-right text-center mb-1" v-if="false">
                <button class="btn btn-block btn-primary btn-shadow" type="button" @click="setMode('guest')">{{$t("checkout.userhandler.buyasguest", null, locale)}}</button>                    
            </div>
        </div>

        <div class="row" v-if="mode == 'guest'">
            <guest :locale="locale"></guest>
            <br>
            <button :disabled="this.guestDisabled" class="btn btn-primary" type="button" @click="guest">{{$t("checkout.userhandler.next", null, locale)}}</button>
        </div>

    </section>
</template>

<script>
export default {
    props: {
      locale: {
        type: String,
        default: 'de'
      }
    },

    data() {
        return {
            mode: null,
            guestDisabled: false
        }
    },

    methods: {

        setMode(mode) {
            this.mode = mode;
        },
        showLogin() {
            $('.sidebars .backdrop').addClass('open')
            $('.sidebars .login-sidebar').addClass('open')
            $('body').addClass('modal-open')
        },
        login() {
            App.event.fire('login.send');
        },

        register() {
            App.event.fire('registration.send');
        },

        guest() {
            App.event.fire('guest.send');
        }
    },
    
    created() {
        App.event.listen('guest.vatin.valid', (valid) => {
            this.guestDisabled = ! valid;
        });
    }
}
</script>