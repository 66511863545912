<template>
        <a class="nav-link login" href="#" v-if="!loggedin" @click.prevent="showSidebar">{{$t('headerprofile.login', null, locale)}}<span class="icon"></span></a>
        <span v-else><a :href="(locale == 'en') ? '/shop/en/profile/' : '/shop/profile/'" class="nav-link login" style="display:inline-block;">{{$t('headerprofile.profil', null, locale)}}<span class="icon"></span></a><a href="/shop/logout" class="nav-link" style="display:inline-block;">{{$t('headerprofile.logout', null, locale)}}</a></span>
</template>
<script>
import Vue from "vue";

export default {

    data() {
        return {
            loggedin: false
        }
    },

    props: {
      locale: {
        type: String,
        default: 'de'
      }
    },

    methods: {
        showSidebar() {
            $('.sidebars .backdrop').addClass('open')
            $('.sidebars .login-sidebar').addClass('open')
            $('body').addClass('modal-open')
        },
    },

    created() {
        App.event.listen('profile.loaded', (response) => {
            if (response) this.loggedin = true;
        });

        App.event.listen('login.success', (response) => {
            if (response) this.loggedin = true;
        });

    }
}
</script>
