<template>
    <div class="image-zoom-wrapper" ref="container">
        <slot></slot>
    </div>
</template>

<script>
import Vue from 'vue';
import ImageZoom from './image-zoom';
import forEach from '../../utils/forEach';

export default {

    data() {
        return {
            imageZoom: null
        }
    },

    mounted() {
        this.initZoom();       
        forEach(this.images, (index, image) => {
            image.addEventListener('click', (e) => {
                this.image.src = e.currentTarget.src;
                this.imageZoom.kill();
                this.initZoom();
            });
        });
    },

    computed: {
        image() {
            return this.$parent.$refs.image
        },
        images() {
            return this.$parent.$refs.images.querySelectorAll('img');
        },
        container() {
            return this.$parent.$refs.imageContainer;
        }
    },

    methods: {
        initZoom() {
            this.imageZoom = new ImageZoom(this.container, {
                width: this.image.width,
                height: this.image.height,
                scale: 2,
                offset: {
                    vertical: 0,
                    horizontal: 0
                }
            });
        }
    }
}
</script>
