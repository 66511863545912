<template>
  <div class="search-product-amount" v-if="amount">
    <a href="#shop-search-list">{{amount}} {{$t("searchproductamount.productsfound", null, locale)}}</a>
  </div>
</template>

<script>
export default {
  props: {
    locale: {
      type: String,
      default: 'de'
    }
  },
  data() {
    return {
      amount: null
    }
  },
  created() {
    App.event.listen('searchproductamount.searched', (response) => {
      this.amount = response

      // The speed of the scroll in milliseconds
      var speed = 1000;

      // Find links that are #anchors and scroll to them
      $('a[href^=\\#]:not(.pages)')
        .unbind('click.smoothScroll')
        .bind('click.smoothScroll', function(event) {
          event.preventDefault();
          $('html, body').animate({ scrollTop: $( $(this).attr('href') ).offset().top }, speed);
        });
    })
  }
}
</script>

<style>

</style>