<template>
    <div class="d-none">
        <div class="row">
            <div class="col-sm-12 blackBar"><div class="title">Versandart wählen</div></div>
        </div>
        <div class="row backgroundGray">
            <div v-for="(shippingType, key, index) in shippingTypes" :key="key" class="col-sm-6">
                <div class="checkbox">
                    <label>
                        <input name="shipping" :value="key" type="radio" :checked="index === 0">
                        <span>{{shippingType.name}}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
    data() {
        return {
            shippingTypes: null
        }
    },

    created() {
        new Form().get('/api/cart/shipping')
        .then(shippingTypes => {
            this.shippingTypes = shippingTypes;
        });
    }
  
}
</script>
