<template>
    <div class="results row">
        <template v-if="results">
            <template v-if="pages > 0">
            <div class="d-md-block col-md-3 align-self-center">
              {{$t("searchresult.resultsperpage", null, locale)}}:
              <select @change="setItemLimit" v-model="locallimit">
                <option value="10" selected>10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <div class="col-12 col-md-6">
                <search-pagination v-model="page" :page-count="pages"></search-pagination>
            </div>
            <div class="col-12 col-md-3 d-flex align-items-center justify-content-end">
                {{ numberOfResults }} {{$t("searchresult.foundresults", null, locale)}}
            </div>
            </template>
            <div class="loading-screen d-flex flex-column align-items-center justify-content-start" v-if="loading">
                <div>
                    <svg class="lds-spinner" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                        <g transform="rotate(0 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(30 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(60 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(90 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(120 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(150 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(180 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(210 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(240 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(270 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(300 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(330 50 50)">
                            <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                    </svg>
                </div>
                <p>{{$t("searchresult.loadingresults", null, locale)}}</p>
            </div>
            <div v-html="results" class="w-100"></div>
            <div class="col-12" v-if="pages > 0">
                <search-pagination v-model="page" :page-count="pages"></search-pagination>
                <p v-html="$t('searchresult.pricevat', null, locale)"></p>
            </div>
        </template>
        <template v-if="!results && topevents && !loading">
            <div class="w-100">
                <h2 class="topevents">Top Events</h2>
            </div>
            <div v-html="topevents" class="w-100">
            </div>
        </template>
        <template v-if="!results && topproducts.length > 0 && !loading">
          <div v-for="(item, index) in topproducts" :key="index" class="col-12 mb-3">
            <div class="row">
              <div class="col-12">
                <h2 class="topevents">{{ $t("categories.category_" + item.id, null, locale) }}</h2>
              </div>
            </div>
            <div class="row event-overview content-wrap mb-0 mt-0" v-html="item.html">
            </div>
            <div class="row">
              <div class="col-12 mb-5">
                <a href="#" v-if="item.id != 11 && item.id != 1" @click.prevent="chooseCategory(item.id)" class="btn btn-primary">
                  {{ $t("categories.showall", null, locale) }}
                </a>
                <a href="/ove-network/mitgliedschaft" v-else-if="item.id != 11" class="btn btn-primary">
                  {{ $t("categories.showall", null, locale) }}
                </a>
                <a href="/oveacademy" v-else class="btn btn-primary">
                  {{ $t("categories.showall", null, locale) }}
                </a>
              </div>
            </div>
          </div>
        </template>
        <div class="loading-screen position-relative w-100 d-flex flex-column align-items-center justify-content-start" v-if="(!results && loading) || (!results && topproducts.length <= 0)">
            <div>
                <svg class="lds-spinner" width="200" height="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="rotate(0 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(30 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(60 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(90 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(120 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(150 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(180 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(210 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(240 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(270 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(300 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                    <g transform="rotate(330 50 50)">
                        <rect x="47" y="24" rx="4.7" ry="2.4" width="6" height="12" fill="#1d3f72">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                        </rect>
                    </g>
                </svg>
            </div>
            <p>{{$t("searchresult.loadingresults", null, locale)}}</p>
        </div>
    </div>
</template>
<style lang="css" scoped>


    h2.topevents {
        font-family: "Roboto";
        font-size: 14px;
        font-size: .875rem;
        color: #395279;
        border-bottom: 1px solid #b9b3ab;
        margin: 0;
        margin-bottom: 14px;
        margin-bottom: .875rem;
        padding-bottom: 7px;
        padding-bottom: .4375rem;
        text-transform: uppercase;
    }

    .pagination {
        justify-content: center;
    }

    .results {
        position: relative;
        /* min-height: 350px; */
    }

    .results .loading-screen {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: rgba(255, 255, 255, 0.9);
    }
</style>


<script>
import { createNamespacedHelpers } from 'vuex'
import SearchPagination from './SearchPagination'

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('filters')

export default {
    components: {
        SearchPagination
    },

    props: {
      locale: {
        type: String,
        default: 'de'
      }
    },

    data() {
      return {
        locallimit: 10
      }
    },

    computed: {
        ...mapState({
            loading: state => state.loading, 
            results: state => state.results,
            topevents: state => state.topevents
        }),
        ...mapState(['firstView', 'numberOfResults', 'limit', 'topproducts']),
        ...mapGetters(['pages']),
        page: {
            get() {
                return this.$store.state.filters.page
            },
            set(value) {
                this.setPage(value)
            }
        },
    },

    methods: {
        ...mapActions(['setPage', 'fetchTopEvents', 'setLimit', 'refresh', 'setCategory']),
        setItemLimit() {
            this.setLimit(this.locallimit);
            this.refresh(this.locale);
        },
        chooseCategory($catId) {
          App.event.fire('selectCategory', $catId)
          // this.setCategory($catId)
          // this.refresh(this.locale)
        }
    },

    created() {
        // this.fetchTopEvents()
    },
    mounted() {
      this.locallimit = this.limit
    }
}
</script>
