'use strict';

import HeaderProfile from "./components/HeaderProfile.vue";

export default class {

    constructor() {
        Vue.component('header-profile', HeaderProfile);
    }
}
