<template>
    <div class="add-to-cart">
        <div v-if="type == 'detail' && pstock != '0'" :class="[{hasSize:hasOptions}]">
            <div class="add">
                <spinner @change="updateQty" :minorder="minorder" :pstock="pstock"></spinner>
            </div>
            <div class="add">
                <button  class="btn btn-primary" @click="submit" :disabled="loading" >
                    <i v-if="loading" class="fa fa-spin fa-spinner"></i>
                    <i v-if="!loading" class="fas fa-shopping-bag"></i>
                    <slot name="name">{{$t("addtocart.addtocart", null, locale)}}</slot>
                </button>
            </div>
        </div>
        <div v-if="type == 'overview'" :class="{hasSize:hasOptions}">
            <div class="basket row no-gutters">
                <div class="col-6 text-center">
                  <select name="bezugsart" v-model="productType">
                    <option value="Download">Download</option>
                    <option v-if="!isPrintAndDownloadEnabled" value="Print">Print</option>
                    <option v-if="isPrintAndDownloadEnabled" value="PrintDownload">Print + Download Kombi-Version</option>
                  </select>
                </div>
                <div class="col-4 amount">
                    <spinner @change="updateQty" :minorder="minorder" :pstock="pstock">
                        <template slot="minus">-</template>
                        <template slot="plus">+</template>
                    </spinner>
                </div>
                <div class="col-2 text-center">
                    <button @click="submit" :disabled="loading" v-if="pstock != '0'" class="btn active">
                        <i v-if="loading" class="fa fa-spin fa-spinner"></i>
                        <i v-if="!loading" class="fas fa-shopping-bag"></i>
                    </button>
                    <div v-else class="notOnStock">
                        {{$t("addtocart.notonstock", null, locale)}}
                    </div>
                </div>
            </div>        
        </div>
    </div>
</template>
<script>
import Vue from "vue";

export default {
  props: {
      pid: {
        type: [String, Number],
        required: true,
        default: null
      },
      isPrintAndDownloadEnabled: {
        type: Boolean,
        required: false,
        default: false
      },
      pstock: {
        type: [String, Number],
        required: true,
        default: null
      },
      pseo: {
        type: [String],
        default: null
      },
      minorder: {
        type: [String, Number],
        required: true,
        default: null
      },
      type: {
        type: [String],
        default: 'overview'
      },
      options: {
          type: Array,
          default: function () { return [] }
      },
      locale: {
        type: String,
        default: 'de'
      }
  },

  data() {
      return {
        qty: 1,
        loading: false,
        selectedArtNr: null,
        productType: '',
        language:''
      }
  },

  computed: {
      coptions() {
          let $returnValue = [];
          if (this.hasOptions) {
            let $options = this.options;
            $options.forEach(element => {
                element["ShopProductOption.options"].forEach(opt => {
                    $returnValue.push({name: (element["ShopProductOption.size"] + " - " + decodeURIComponent(opt.color)).toUpperCase(), art: opt.artikelnr});
                });
            });
          }
          return $returnValue;
      },
      firstCoption() {
          return this.coptions.slice().shift();
      },
      selectedOption() {
          let option = [];
          if (this.hasOptions) {
              this.options.forEach(element => {
                  element["ShopProductOption.options"].forEach(opt => {
                      if (opt.artikelnr == this.selectedArtNr) {
                          option = {
                              Size: element["ShopProductOption.size"],
                              ArticleNr: opt.artikelnr,
                              Color: decodeURIComponent(opt.color),
                              productType: this.productType
                          }
                      }
                  })
              })
          } else {
            let optionSelection = $('#bezugsart').val();
            if (optionSelection) {
                this.productType = optionSelection;
            }

            let languageSelection = $('#sprache').val();
            if (languageSelection && languageSelection !== 'undefined') {
                this.language = languageSelection
            }

            option = {
              productType: this.productType,
              language: this.language
            }
          }
          return option;
      },
      hasOptions() {
          return (this.options.length > 0);
      }
  },

  mounted() {
      if(this.hasOptions) {
          this.selectedArtNr = this.firstCoption.art;
      }
  },

  methods: {
        onSelected({currentTarget}) {
            window.location = App.url+'/product/' + this.pseo;
        },

      updateQty(qty) {
        this.qty = qty;
      },

      submit() {

        this.loading = true;

        let optionSelection = $('#bezugsart').val();
        if (optionSelection) {
            this.productType = optionSelection;
        }

        let languageSelection = $('#sprache').val();
        if (languageSelection && languageSelection !== 'undefined') {
            this.language = languageSelection
        }

        let options = {
            pid: this.pid,
            qty: this.qty,
            pstock: this.pstock,
            options: this.selectedOption,
            locale: this.locale
        }

        new Form(options)
        .post('/' + this.locale + '/api/cart/add')
        .then((response) => {
            this.loading = false;
            if(response.success) {
                App.event.fire('cart.item.inserted', response.item);
               // App.event.fire('cart.updated', response.data); // deactivated
            } else {
                swal("", this.$t("addtocart.tomanyproducts", null, this.locale), "error");
            }
            
        }).catch(errors => {
            this.loading = false;
        });
      }

  }
}
</script>
