<template>
    <div class="row no-gutters amount-changer">
        <div class="col-4">
            <button @click.prevent="decrease" :disabled="qty <= 1 || loading" class="btn-number" type="button" data-type="minus" data-field="quant[1]">
                <slot name="minus">
                    <span :class="minusIcon"></span>
                </slot>
            </button>
        </div>
        <div class="col-4"><input class="input-number" pattern="[0-9]*" name="qty" v-model="qty" @change="inputChanged"></div>
        <div class="col-4">
            <button @click.prevent="increase" :disabled="qty >= pstock || loading" class="btn-number" type="button" data-type="plus" data-field="quant[1]">
                <slot name="plus">
                    <span :class="plusIcon"></span>
                </slot>
            </button>
        </div>
    </div>  
</template>

<script>
export default {

  props: {
      value: {
          type: [String, Number],
          default: 1,
      },
      pstock: {
        type: [String, Number],
        required: true,
        default: null
      },
      loading: {
          type: [Boolean],
      }
  },

  data() {
      return {
          qty: null,
          clicked: null
      }
  },

  created() {
      this.qty = this.value;
      this.$emit('change', this.qty);
  },

  methods: {

      inputChanged() {
          if (this.qty <= 0) {
              this.qty = 1;
          }
          
          if (parseInt(this.qty) > parseInt(this.pstock)) {
              this.qty = parseInt(this.pstock);
          }
          
          this.qty = parseInt(this.qty);
          
          this.$emit('change', this.qty);
      },
      
      increase() {
        this.qty = parseInt(this.qty) + 1;
        this.clicked = 'plus';
        this.$emit('change', this.qty);
      },

      decrease() {
        this.qty = parseInt(this.qty) - 1;
        this.clicked = 'minus';
        this.$emit('change', this.qty);
      }
  },

  computed: {

      plusIcon() {
          return (this.loading && this.clicked == 'plus')  ? 'fa fa-spinner fa-spin' : 'fa fa-plus';
      },

      minusIcon() {
          return (this.loading && this.clicked == 'minus') ? 'fa fa-spinner fa-spin' : 'fa fa-minus';
      }
  }

}
</script>
