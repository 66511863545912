<template>
    <div class="uservoices grid container pl-0 pr-0">
      <div class="row">
        <template v-if="uservoices.length">
          <div class="col-12 col-md-4" v-for="(uservoice, index) in uservoices" :key="index">
            <div class="single-tile pb-3">
              <h2 class="title">{{ uservoice.author }}</h2>
              <div class="description">
                <p v-html=uservoice.text></p>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <loading-animation :text="$t('uservoices.loadvoices', null, locale)" color="#ffffff"/>
        </template>
      </div>
    </div>
</template>
<script>
import LoadingAnimation from '@Modules/loading-animation/LoadingAnimation'

export default {
  components: {
    LoadingAnimation
  },
  props: {
    amount:{
      type: Number,
      default: 10
    },
    locale: {
      type: String,
      default: 'de'
    }
  },
  data() {
      return {
        uservoices: [],
        loading: true,
      }
  },
  methods: {
      async loadUservoices() {
          let response = await new Form({amount: this.amount, locale: this.locale, view: 'grid'}).post('/api/uservoices')
          this.loading = false
          this.uservoices = response.results
      }
  },
  mounted() {
      this.loadUservoices()
  }
}
</script>
<style>

</style>
