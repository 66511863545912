import ImageZoom from './image-zoom/ImageZoom.vue';
import EventCartModal from './event-cart-modal/components/EventCartModal';
import SearchComponents from './search';
import EventOverview from './event-overview';
import EventSearch from './event-search';
import BreadcrumbProfile from './header-profile/components/BreadcrumbProfile.vue';
import EventList from './event-list';
import TopEvents from './top-events';
import UserVoices from './user-voices';
import MobileMenuLogin from './mobile-menu-login';

export default {
  ImageZoom,
  EventCartModal,
  ...SearchComponents,
  ...EventOverview,
  ...EventSearch,
  BreadcrumbProfile,
  ...EventList,
  ...TopEvents,
  ...UserVoices,
  ...MobileMenuLogin
}
