<template>
  <section id="checkout">
    <form :action="processUrl" @submit="beforeSubmit" method="post">
      <input type="hidden" name="_token" :value="token"/>
      <div class="row">
        <div class="col-sm-12">

          <cart @onLoad="onCartLoad" :locale="locale" :user="user" :hydration-data="hydrationData"></cart>

        </div>
      </div>

      <user-handler :user="user" :locale="locale" v-if="!user && initialized"></user-handler>

      <template v-else>
        <div v-if="!cartEmpty" class="row">
          <div class="col-sm-12">

            <invoice-address  :locale="locale" v-if="initialized" :user="user" :hydration-data="hydrationData"></invoice-address>

            <shipping-address :locale="locale" v-if="initialized" :user="user" :hydration-data="hydrationData"></shipping-address>

            <shipping-type :locale="locale"></shipping-type>

            <payment-type :has-orders="hasOrders" :locale="locale" :has-waiting-list-event="hasWaitingListEvent" :has-non-targung-event="hasNonTargungEvent"  :user="user" :hydration-data="hydrationData"></payment-type>

            <section id="invoice_delivery">
              <div class="row">
                <div class="col-sm-12 blackBar">
                  <div class="title">{{$t("checkout.rechnungsversand", null, locale)}}</div>
                </div>
              </div>
              <div class="row backgroundGray">
                <div class="col-sm-12">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="invoiceDeliveryType" v-model="invoiceDelivery" id="invoiceDeliveryType1" value="mail"
                           checked>
                    <label class="form-check-label" for="invoiceDeliveryType1">
                      {{$t("checkout.electronicinvoice", null, locale)}}
                    </label>
                  </div>

                  <div v-if="invoiceDelivery === 'mail'">
                    <div class="form-group">
                      <label for="deliveryEmailType">{{$t("checkout.invoicerecipientmail", null, locale)}}</label>
                      <input class="form-control" name="invoiceDeliveryMail" id="deliveryEmailType" v-if="this.user" type="email" v-model="invoiceDeliveryValue" v-on:blur="checkInvoiceEmail">
                    </div>
                  </div>
                  
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="invoiceDeliveryType" v-model="invoiceDelivery" id="invoiceDeliveryType2" value="post">
                    <label class="form-check-label" for="invoiceDeliveryType2">
                      {{$t("checkout.mailinvoice", null, locale)}}
                    </label>
                  </div>

                  <div class="form-check" v-if="false">
                    <input class="form-check-input" type="radio" name="invoiceDeliveryType" v-model="invoiceDelivery" id="invoiceDeliveryType3" value="digital">
                    <label class="form-check-label" for="invoiceDeliveryType3">
                      {{$t("checkout.einvoicetodepartment", null, locale)}}
                    </label>
                  </div>
                  
                  <div v-if="false">
                    <section>
                      <div class="row">
                        <div class="col-sm-12 blackBar">
                          <div class="title">{{$t("checkout.einkaufsgruppe", null, locale)}}</div>
                        </div>
                      </div>
                      <div class="row backgroundGray">
                        <div class="col-sm-12">
                          <p>{{$t("cart.purchaseGroup", null, locale)}}</p>
                          <div class="form-group">
                            <label for="einkaufsgruppe">{{$t("checkout.einkaufsgruppe", null, locale)}}</label>
                            <select name="shoppinggroup" class="form-control" id="einkaufsgruppe">
                              <option></option>
                              <option v-for="(item, index) in purchaseGroups" :key="index" :value="index" v-text="item"></option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="row" v-if="false">
          <div class="col-sm-12">
            <section id="agbaccept">
              <div class="row">
                <div class="col-sm-12 blackBar">
                  <div class="title">{{$t("checkout.termstitle", null, locale)}}</div>
                </div>
              </div>
              <div class="row backgroundGray">
                <div class="col-sm-12 checkbox">
                  <label>
                    <input type="checkbox" id="agbcheckbox" name="agbcheckbox" value="agb-accepted" v-model="agb">
                    <span v-html="$t('checkout.agbcheckbox', null, locale)"></span>
                  </label>
                </div>
                <div class="col-sm-12 agb-error" v-if="!agb && submitted">
                  {{$t("checkout.tcmessage", null, locale)}}
                </div>
              </div>
            </section>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-sm-12" v-if="isGuestWithDownload" >
            <div class="alert alert-danger">
              {{$t("checkout.download_norm", null, locale)}}
            </div>
          </div>
          
          <div class="col-sm-12" v-if="hasNonTargungEvent" >
            <div class="alert alert-warning">
              Das Bestellen von Veranstaltungen per Kreditkarte ist nur bei Tagungen möglich.
            </div>
          </div>

          <div class="col-sm-12 text-right">
            <button type="submit" class="btn btn-primary" :disabled="cartEmpty || deliveryVatinInvalid || invoiceVatinInvalid || isGuestWithDownload">{{$t("checkout.pay", null, locale)}}</button>
          </div>
        </div>
      </template>
    </form>
  </section>
</template>

<script>
import axios from 'axios'

  export default {
    props: {
      locale: {
        type: String,
        default: 'de'
      }, 
      hydrationData: {
          type: Object,
          default: function () {
              return {};
          }
      },
      hasOrders: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        invoiceDelivery: "mail",
        invoiceDeliveryValue: "",
        invoiceDeliveryValueOriginal: "",
        initialized: false,
        cartEmpty: true,
        user: null,
        agb: false,
        submitted: false,
        hasWaitingListEvent: false,
        purchaseGroups: [],
        deliveryVatinInvalid: false,
        invoiceVatinInvalid: false,
        isGuestWithDownload: false,
        hasNonTargungEvent: false
      }
    },

    computed: {

      processUrl() {
        return App.url + '/' + this.locale + '/checkout/process';
      },
      
      registerUrl() {
          return App.url + '/' + this.locale + '/register'
      },

      loginUrl() {
        return App.url + '/' + this.locale + '/login'
      },

      token() {
        return App.token;
      }
    },

    methods: {

      beforeSubmit(event) {
        // if (!this.agb && event) {
        //   event.preventDefault();
        // }
        this.submitted = true;
        // return this.agb;
        return this.submitted;
      },

      onCartLoad(items) {
        this.cartEmpty = Object.keys(items).length === 0;
      },

      fetchUser() {
        new Form().get('/api/me')
          .then(user => {
            this.user = user;
            this.initialized = true;
            App.event.fire('user.logged-in', user);

              if(this.hydrationData.invoiceDeliveryType) {
                  this.invoiceDelivery = this.hydrationData.invoiceDeliveryType;
                  this.invoiceDeliveryValue = this.hydrationData.invoiceDeliveryMail;
                  this.invoiceDeliveryValueOriginal = this.hydrationData.invoiceDeliveryMail;
              } else {
                  if (this.user) {
                      if (this.user.invoiceMail) {
                        this.invoiceDeliveryValue = this.user.invoiceMail;
                        this.invoiceDeliveryValueOriginal = this.user.invoiceMail;
                      } else {
                        this.invoiceDeliveryValue = this.user.username;
                        this.invoiceDeliveryValueOriginal = this.user.username;
                      }
                  }
              }
          }).catch(error => {
              this.initialized = true;
          App.event.fire('user.logged-out', error);
        });
      },

      fetchPurchaseGroups() {
        new Form().get('/purchase-groups')
          .then(data => {
            this.purchaseGroups = data;
          }).catch(error => {
          this.purchaseGroups = [];
        });
      },

      checkInvoiceEmail() {
        if (this.invoiceDeliveryValueOriginal != this.invoiceDeliveryValue) {
          // ask about storing the email in the profile
          swal({
            title: this.$t("checkout.changeEmail", null, this.locale),
            text: this.$t("checkout.changeEmailText", null, this.locale),
            confirmButtonText: this.$t("checkout.changeEmailConfirm", null, this.locale),
            cancelButtonText: this.$t("checkout.changeEmailCancel", null, this.locale),
            type: "info",
            showCancelButton: true,
            closeOnConfirm: true,
            disableButtonsOnConfirm: true,
            closeOnCancel: true,
          }, (updateEmail) => {
            if (updateEmail) {
              axios.post('/shop/profile/user-data/invoice-mail/update', {
                mail_invoice: this.invoiceDeliveryValue
              })
            }
          });
        }
      }
    },

    created() {
      this.fetchUser();

      this.fetchPurchaseGroups();

      App.event.listen('login.success', (response) => {
        this.user = response.user
        new Form().get('/' + this.locale + '/api/cart/content')
          .then((response) => {
            App.event.fire('cart.updated', response);
          });
      });
      App.event.listen('registration.success', response => this.user = response.user);
      App.event.listen('guest.success', response => this.user = response.user);

      App.event.listen('cart.data.loaded', (response) => {
        this.hasWaitingListEvent = response.hasWaitingListEvent
        
        this.isGuestWithDownload = false;

        if (response.checkIfContainsDownload) {
          if ( this.user != null && ! this.user.id) {
            this.isGuestWithDownload = true
          }
        }

        this.hasNonTargungEvent = response.hasNonTargungEvent
      });

      App.event.listen('cart.updated', (response) => {
        this.hasWaitingListEvent = response.hasWaitingListEvent
        this.isGuestWithDownload = false;

        if (response.checkIfContainsDownload) {
          if ( this.user != null && ! this.user.id) {
            this.isGuestWithDownload = true
          }
        }

        this.hasNonTargungEvent = response.hasNonTargungEvent
      });

        App.event.listen('delivery.vatin.valid', (valid) => {
            this.deliveryVatinInvalid = ! valid;
        });

        App.event.listen('invoice.vatin.valid', (valid) => {
            this.invoiceVatinInvalid = ! valid;
        });

        
    }
  }
</script>
