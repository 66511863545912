export default {
  'test': 'testDE',
  registerbutton: {
    register: 'Registrieren',
  },
  eventsearch: {
    nachfachthemen: "nach Themen",
    nachformaten: "nach Formaten",
    nachdatum: "nach Datum",
    nachreferenten: "nach Referenten",
    nachschlagworten: "nach Schlagworten",

    unserefachthemen: "Unsere Fachthemen",
    unsereformate: "Unsere Formate",
    unserereferenten: "Unsere Referenten",
    unsereschlagworten: "Nach Schlagworten",

    header: "Suche",
    suchen: "Suchen",
    loadevents: "Lade Filter",

    selectfilter: "Wählen Sie einen Filter aus.",
  },
  headerprofile: {
    profil: "Profil",
    logout: "Abmelden",
    login: "Anmelden"
  },
  topevents: {
    loadingtext: "Lade Veranstaltungen...",
    mightinterestyou: "Das könnte Sie auch interessieren"
  },
  uservoices: {
    loadvoices: "Lade Teilnehmerstimmen"
  },
  eventoverview: {
    searchfortheme: "Suche nach Thema: ",
    searchforformat: "Suche nach Format: ",
    searchfordate: "Suche nach Datum: ",
    searchforreferent: "Suche nach Referent: ",
    searchforterms: "Suche nach Schlagworten: ",
    back: "Zurück",
    loadingevents: 'Lade Veranstaltungen'
  },
  addtocart: {
    addtocart: "In den Warenkorb",
    notonstock: "Produkt ist nicht auf Lager.",
    tomanyproducts: "Sie wollen mehr Produkte in den Warenkorb hinzufügen als derzeit lagernd sind.",
    tooOld: "Die Young Engineers Mitgliedschaft ist nur für Personen verfügbar, die jünger als 35 sind",
    tooYoung: "Die Senioren Mitgliedschaft ist nur für Personen verfügbar, die älter als 65 sind",
    needbirthdate: "Das Geburtsdatum muss ausgefüllt sein",
    cancelcondition: 'Ich habe die <a target="_blank" href="https://www.ove.at/impressum/rechtliches-agb#c10189">AGB’s (u.a. Stornobedingungen für Events und Seminare)</a> gelesen und akzeptiere diese.',
  },
  addtomerkliste: {
    merken: "merken",
    gemerkt: "Gemerkt",
    tomerkliste: "zur Merkliste"
  },
  addtowatchlist: {
    added: 'Zur Watchlist hinzugefügt',
    add: 'Zur Watchlist hinzufügen',
    towatchlist: 'zur Watchlist'
  },
  addroottowatchlist: {
    added: 'Zur Watchlist hinzugefügt',
    add: 'Zur Watchlist hinzufügen',
    towatchlist: 'zur Watchlist'
  },
  cartmodal: {
    english: 'Englisch',
    german: 'Deutsch',
    addedtocart: "Zum Warenkorb hinzugefügt",
    product: "Produkt",
    price: "Preis",
    amount: "Anzahl",
    total: "Gesamtbetrag",
    incvat: "€ zzgl. USt.",
    gotocart: "Warenkorb ansehen",
    shopmore: "Weiter einkaufen",
    lastresult: "Zurück zur letzten Suche"
  },
  cart: {
    sum: 'Summe',
    toCart: 'Zum Warenkorb',
    usercomment: 'Bestellhinweis (optional) für weitere Informationen oder Feedback',
    usercomment_read: 'Bestellhinweis: ' ,
    invoiceAddress: 'Rechnungsadresse / Empfänger',
    differentInvoiceAddress: 'Abweichende Rechnungsadresse / Empfänger',
    choose: 'Auswählen',
    openAddressBook: 'Adressbuch öffnen',
    closeAddressBook: 'Addressbuch schließen',
    shippingAddress: 'Lieferadresse',
    differentShippingAddress: 'Abweichende Lieferadresse',
    choosePaymentMethod: 'Zahlart wählen',
    creditCard: 'Kreditkarte',
    invoice: 'Rechnung',
    noGuest: 'Bei Gastbestellungen ist die Bezahlung per Rechnung nicht möglich.',
    noInvoice: 'Bei der Erstbestellung ist die Bezahlung per Rechnung bei einem Warenkorb Wert über 1000 € nicht möglich'
  },
  eventcartmodal: {
    addparticipant: 'Teilnehmer hinzufügen',
    salutation: 'Anrede',
    male: 'Herr',
    female: 'Frau',
    differentemail: 'E-Mails müssen unterschiedlich sein.',
    cancel: 'Abbrechen',
    addtocart: 'In den Warenkorb legen',
    cannotbeempty: 'müssen ausgefüllt werden.',
    participant: 'Teilnehmer',
    register: 'Anmelden',
    close: "Schließen",
  },
  checkout: {
    rechnungsversand: "Rechnungsversand",
    electronicinvoice: "Elektronische Rechnung",
    mailinvoice: "Rechnung per Post",
    einvoicetodepartment: "e-Rechnung an Bundesdienststelle",
    invoicerecipientmail: "Rechnungsempfänger E-Mail Adresse",
    post: "Mail",
    einkaufsgruppe: "Einkaufsgruppe",
    termstitle: "Allgemeine Geschäftsbedingungen &amp; Datenschutz",
    tcmessage: "Die Allgemeinen Geschäftsbedingungen und die Datenschutzerklärung müssen akzeptiert werden.",
    pay: "zur Kasse",
    changeEmail: "Rechnungs-E-Mail Adresse ändern?",
    changeEmailText: "Möchten Sie Ihre Rechnungs-E-Mail Adresse permanent ändern?",
    changeEmailConfirm: "Ja",
    changeEmailCancel: "Nein",
    agbcheckbox: 'Ja, ich habe die <a href="/agb" title="Allgemeine Geschäftsbedingungen" target="_blank">Allgemeinen Geschäftsbedingungen</a>, die <a href="/widerruf">Widerrufserklärung</a> und die <a href="/impressum/datenschutz" title="Datenschutzerklärung" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiert.',
    download_norm: 'Das Bestellen von Download-Normen ist als Gast aktuell nicht möglich. Bitte <a :href="registerUrl">registrieren Sie sich</a> oder <a :href="loginUrl">melden Sie sich</a> im Shop mit den bestehenden Zugangsdaten an.',
    cart: {
      empty: "Ihr Warenkorb ist leer.",
      productsearch: "Zur Produktsuche",
      loadcart: "Lade Warenkorb.",
      articledesc: "Artikelbezeichnung",
      amount: "Menge",
      delete: "Entfernen",
      price: "Preis",
      netamount: "Nettobetrag",
      vat: "USt. (10%)",
      vouchername: "Gutschein Name",
      voucher: "Veranstaltungsgutschein",
      rabatt: "Rabatt",
      shipping: "Versandkosten",
      inclvat: "(incl. VAT)",
      exclvat: "(exkl. USt.)",
      total: "Gesamtbetrag",
      vouchercode: "Gutscheincode",
      apply: "Anwenden",
      defaultTitle: "Warenkorb",
      ordercodeoptional: "Bestellnummer (Optional) (hier eingetragener Text wird auf der Rechnung angedruckt)",
      ordercode: "Bestellnummer",
      ordercode_read: "Bestellnummer: ",
      termstitle: "Allgemeine Geschäftsbedingungen & Datenschutz",
      unitprice: "Einzelpreis",
      purchaseGroup: "Seit 1.1.2014 sind alle VertragspartnerInnen von Bundesdienststellen im Waren- und Dienstleistungsverkehr mit Bundesdienststellen zur Ausstellung und Übermittlung von e-Rechnungen verpflichtet."
    },
    userhandler: {
      backtoshop: "Zurück in den Shop",
      buywithaccount: "Mit Kundenkonto kaufen",
      buyasguest: "Als Gast kaufen",
      login: "Anmelden",
      register: "Register",
      next: "Weiter",
    }
  },
  login: {
    login: "Anmelden"
  },
  guest: {
    contactdata: "Kontaktdaten",
    company: "Firma",
    vatin: "UID",
    salutation: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    street: "Straße und Hausnr.",
    zip: "PLZ",
    city: "Ort",
    country: "Land",
    confirm_email: "E-Mail wiederholen",
    next: "Continue",
  },
  register: {
    personal: "Privat",
    company: "Firma",
    contactperson: "Ansprechpartner",
    zuhaenden: "Vor- und Nachname / Abteilung",
    vatin: "UID",
    vatnumber: "UID",
    errorVatin: "Die Eingabe der UID ist fehlerhaft",
    vatinfo: "Für eine steuerfreie innergemeinschaftliche Lieferung an Unternehmen außerhalb Österreichs benötigen wir Ihre UID-Nr. Wenn diese Nummer fehlt, stellen wir Umsatzsteuer in Rechnung.",
    salutation: "Anrede",
    herr: "Herr",
    frau: "Frau",
    title: "Titel",
    knr: "Kundennummer",
    titlefollowing: "Titel (nachgestellt)",
    firstname: "Vorname",
    surname: "Nachname",
    street: "Straße und Hausnummer",
    phone: "Telefonnummer",
    plz: "PLZ",
    city: "Ort",
    birthDate: "Geburtsdatum",
    country: "Land",
    email: "E-Mail",
    email_username: "E-Mail / Benutzername",
    confirmemail: "E-Mail wiederholen",
    password: "Passwort",
    passwordinformation: "Passwort muss mindestens 6 Zeichen lang sein und zusätzlich einen Groß- und Kleinbuchstaben, eine Zahl und ein Sonderzeichen (!?,. etc) beinhalten",
    confirmpassword: "Passwort wiederholen",
    privacycheckbox: 'Ich habe die <a target="_blank" href="https://www.ove.at/impressum/rechtliches-agb#c10184">Datenschutzbestimmungen</a> gelesen und akzeptiert.',
    usererror: 'Um eine Mitgliedschaft erwerben zu können ist eine Anmeldung mit einem OVE-Kundenkonto erforderlich. Bitte loggen Sie sich ein oder registrieren Sie sich neu.',
    usereventerror: 'Um diese Veranstaltung buchen zu können ist eine Anmeldung mit einem OVE-Kundenkonto erforderlich. Bitte loggen Sie sich ein oder registrieren Sie sich neu.'
  },
  searchquery: {
    header: 'Kategorie',
    noresults: "Keine Ergebnisse gefunden.",
    topproducts: "Top Produkte",
    search: "Suchen",
    show: "Show",
    all: "Alle",
    loadingcategories: "Lade Kategorien.",
    loadingproducts: "Lade Produkte.",
    normsearch: "Detailsuche Normen / Richtlinien",
    doctype: "Dokumentart",
    valid: "Gültig",
    invalid: "Ersetzt / Zurückgezogen",
    language: "Sprache",
    publisher: "Herausgeber",
    supplier: "Lieferant",
    specialfieldics: "ICS-Nr.",
    issuedatefrom: "Ausgabedatum Von (Datum)",
    issuedateto: "Ausgabedatum Bis (Datum)",
    resetfilters: "Filter zurücksetzen",
    applyfilters: "Filter anwenden",
    loadingsuggestions: "Lade Vorschläge",
    nosuggestions: "Keine Vorschläge gefunden.",
    searchplaceholder: "Suche nach Produkten",
    searchfor: 'Nach <strong>&quot;:value&quot;</strong> suchen',
    membership: 'Mitgliedschaften',
    technicalliterature: 'Detailsuche Fachpublikationen',
    blackboards: 'Detailsuche Sonstige Publikationen',
    blackboardtext: 'Sicherheitsaushänge dienen einer ordnungsgemäßen Information über Sicherheitsvorschriften und Verhaltensvorgaben während eines Notfalls oder einer Gefahrensituation. Für den Betrieb von elektrischen Anlagen gemäß ÖVE/ÖNORM EN 50110-1 werden den Betreibern von elektrischen Anlagen die Anwendung folgender OVE-Normen empfohlen: <ul><li>OVE E 8350:2017-12  Bekämpfung von Bränden in elektrischen Anlagen und in deren Nähe </li><li>OVE E 8351:2016-06  Erste Hilfe bei Unfällen durch Elektrizität</li></ul>Um den betroffenen Personen den Inhalt dieser Normen besser und dauerhaft zu vermitteln, hat der OVE Wandtafeln zum Aushang in elektrischen Betriebsstätten herausgegeben.',
    loadingresults: 'Lade Ergebnisse.',
    status: 'Status',
    reset: 'Suche zurücksetzen'
  },
  searchresult: {
    foundresults: "Ergebnisse gefunden.",
    loadingresults: "Lade Ergebnisse.",
    pricevat:  '* (zzgl. gesetzl. USt., <a href="/versand" target="_blank">zzgl. Versand</a>)',
    resultsperpage: 'Ergebnisse pro Seite'
  },
  breadcrumbprofile: {
    loggedin1: "Sie sind als ",
    loggedin2: "angemeldet.",
    notloggedin1: "Not logged in.",
    notloggedin2: "Click here to log in.",
    loggedout: "You are not logged in."
  },
  shopsearchresults: {
    loading: "Suche Produkte..."
  },
  searchproductamount: {
    productsfound: "Produkte gefunden."
  },
  eventlist: {
    noeventsavailable: 'Keine Veranstaltungen verfügbar.',
    title: 'OVE ACADEMY AKTUELL',
  },
  membershipaddtocart: {
    addtocart: 'In den Warenkorb'
  },
  membershipcartmodal: {
    modaltitle: 'Beitrittsformular ',
    areaofexpertise: 'Falls gewünscht, bitte einer Gesellschaft zuordnen',
    femove: 'femOVE',
    magazine: 'e&i Registrierung',
    magazineText: 'Ja, ich möchte die Verbandszeitschrift e&i erhalten',
    femovenetwork: 'Mitgliedschaft im Frauen-Netzwerk femOVE gewünscht',
    infoviamail: 'Informationen per E-Mail',
    infoviamailtext: 'Ich bin damit einverstanden, Informationen des OVE vorzugsweise per E-Mail zu erhalten.',
    heardabout: 'OVE kennengelernt durch',
    ieee_membership: 'IEEE Mitglied',
    company: 'Angestellt bei',
    yes: 'ja',
    no: 'nein',
    futureinformation: 'zukünftig Informationen des OVE an folgende Adresse schicken',
    homeaddress: 'Privatanschrift',
    workaddress: 'Firmenanschrift',
    digitallysignedinvoices: 'digital signierte Rechnung per E-Mail',
    digitallysignedinvoicestext: 'Bitte senden Sie mir die Rechnung als digital signierte PDF Datei (per E-Mail).',
   cancel: 'Abbrechen',
    addtocart: 'In den Warenkorb legen',
    filledout: 'müssen ausgefüllt werden.',
    filledoutOne: 'muss ausgefüllt werden.',
    text: 'Für Zusendungen ins Ausland werden zusätzlich Spesen von 15,00 € verrechnet. Mahn- und Inkasso Gebühren werden gesondert verrechnet. Der Gerichtsstand ist Wien. Mit meiner Unterschrift erkläre ich mich einverstanden, OVE-Mitteilungen auch auf elektronischem Weg (E-Mail) zu erhalten. Laut § 22 des Datenschutzgesetzes informiert Sie der Österreichische Verband für Elektrotechnik, Eschenbachgasse 9, 1010 Wien, darüber, dass Ihre Kontaktdaten für Zwecke des OVE‘s gespeichert werden. Die Daten werden nicht an externe Dritte vergeben. Studenten/innen eines einschlägigen Erststudiums erhalten bis zur Vollendendung des 30. Lebensjahres den reduzierten Mitgliedsbeitrag nur, wenn jedes Jahr dem OVE eine aktuelle Studienbestätigung vorgelegt wird.',
    statuten: 'Mit dem Versand des Formulars erkläre ich meinen Beitritt zum OVE und bestätige, die Statuten des OVE, im Falle einer Mitgliedschaft als GMAR Mitglied auch die Statuten der GMAR, gelesen zu haben und diesen zuzustimmen.',
    statutentitle: 'Statuten'
},
  passwordchange: {
    success: 'Passwort wurde erfolgreich geändert.',
    oldpassword: 'Altes Passwort',
    newpassword: 'Neues Passwort',
    confirmnewpassword: 'Wiederholung neues Passwort',
    save: 'SPEICHERN'
  },
  searchfilters: {
    categories: 'Kategorien',
    allcategories: 'Alle Kategorien',
    normsearch: 'Normensuche',
    documenttype: 'Dokumentart',
    all: 'Alle',
    status: 'Status',
    valid: 'Gültig',
    replaced: 'Ersetzt',
    language: 'Sprache',
    ics: 'ICS-Nr.',
    publishfrom: 'Ausgabedatum Von (Datum)',
    publishto: 'Ausgabedatum Bis (Datum)',
    applyfilter: 'Filter anwenden',
    resetfilter: 'Filter zurücksetzen',
    eventsearch: 'Veranstaltungssuche',
    eventfrom: 'Von (Datum)',
    eventto: 'Bis (Datum)',
    format: 'Format',
    allformats: 'Alle Formate',
    seminar: 'Seminar',
    tagung: 'Tagung',
    termin: 'Termin',
    vortrag: 'Vortrag',
    workshop: 'Workshop',
    topic: 'Thema',
    alltopics: 'Alle Themen',
    topicelektrotechnik: 'Elektrotechnik',
    topicbau: 'Bau',
    topicautomatisierung: 'Automatisierung',
    topicenergie: 'Energie',
    topicindustrie: 'Induestrie 4.0',
    topicitsecurity: 'IT-Security & Datenschutz',
    topicpersonality: 'Persönlichkeit/Führungskräfte',
    topicsecurity: 'Sicherheitstechnik',
    topicother: 'Sonstige'
  },
  sponsors: {
    loading: 'Sponsoren werden geladen',
    learnmore: 'Mehr erfahren'
  },
  subscriptions: {
    loading: 'Produkte werden geladen',
    join: 'Informieren und beitreten'
  },
  wirecard: {
    creditcard: 'Kreditkarte',
    cardholdername: 'Karteninhaber',
    cardnumber: 'Kreditkartennummer',
    expirationmonth: 'Ablaufmonat',
    expirationyear: 'Ablaufjahr',
    ccv: 'Kartenprüfnummer',
    ordernow: 'Jetzt zahlungspflichtig bestellen'
  },
  categories: {
    category_15: 'Normen / Richtlinien',
    category_13: 'Fachpublikationen',
    category_14: 'Sonstige Publikationen',
    category_11: 'Veranstaltungen',
    category_1: 'Mitgliedschaften',
    showall: 'Alle anzeigen'
  },
}