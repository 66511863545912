<template>
    <div id="cart-modal" v-if="product">
        <div :class="modalClass" tabindex="-1" role="dialog" style="display: block">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t("cartmodal.addedtocart", null, locale)}}</h4>
                        <button type="button" class="close" @click="close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{$t("cartmodal.product", null, locale)}}</th>
                                        <th>{{$t("cartmodal.price", null, locale)}}</th>
                                        <th>{{$t("cartmodal.amount", null, locale)}}</th>
                                        <th>{{$t("cartmodal.total", null, locale)}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td v-if="product.options && product.options.productType">{{ product.name }} <template v-if="product.options.productType"><br>{{ types[product.options.productType] }}</template><template v-if="product.options.language">{{ computedLanguage }}</template></td>
                                        <td v-else-if="product.eventDate != ''">
                                          {{ product.name }} <br> <small>{{ product.eventDate }}
                                          <template v-if="product.options.attendees && product.options.attendees.length > 0" v-for="(attendee) in product.options.attendees">
                                            <br><span v-text="attendee.firstname"></span> <span v-text="attendee.lastname"></span> (€ <span v-text="attendee.origprice"></span>)
    
                                            <template v-if="attendee.specialOptions && attendee.specialOptions.length > 0" v-for="(specialOption) in attendee.specialOptions">
                                              <br><span v-text="specialOption.name"></span> (€ <span v-text="specialOption.price"></span>)
                                            </template>

                                            <template v-if="attendee.requiredOptions && attendee.requiredOptions.length > 0" v-for="(specialOption) in attendee.requiredOptions">
                                              <br><span v-text="specialOption.name"></span> (€ <span v-text="specialOption.price"></span>)
                                            </template>
                                          </template>
                                          
                                        </small>
                                        
                                        </td>  
                                        <td v-else>
                                          {{ product.name }}</td>
                                        <td>{{ formatMoney(parseFloat(product.price.replace(",", "."))) }}&nbsp;{{$t("cartmodal.incvat", null, locale)}}</td>
                                        <td>{{ product.qty }}</td>
                                        <td>{{ formatMoney(parseFloat(product.subtotal.replace(",", "."))) }}&nbsp;{{$t("cartmodal.incvat", null, locale)}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--img class="img-responsive img-thumbnail" :src="product.image">-->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <a :href="url" class="btn btn-primary">{{$t("cartmodal.gotocart", null, locale)}}</a>
                        <a :href="lastResultUrl" class="btn btn-primary">{{$t("cartmodal.lastresult", null, locale)}}</a>
                        <a :href="listUrl" class="btn btn-primary">{{$t("cartmodal.shopmore", null, locale)}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div :class="backdropClass"></div>
    </div>
</template>

<script>
export default {

  data() {
    return {
        product: null
    }
  },
  props: {
    locale: {
      type: String,
      default: 'de'
    }
  },

  created() {

      App.event.listen('cart.item.inserted', (product) => {
          product.subtotal = product.subtotal.toFixed(2).replace(".", ",");
          product.price = product.price.toFixed(2).replace(".", ",");
          product.tax = parseFloat(product.tax).toFixed(2).replace(".", ",");
          this.product = product;
      });

  },

  methods: {
      close() {
          this.product = null;
      },
      formatMoney(amount, decimalCount = 2, decimal = ",", thousands = ".") {
        try {
          decimalCount = Math.abs(decimalCount);
          decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

          const negativeSign = amount < 0 ? "-" : "";

          let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
          let j = (i.length > 3) ? i.length % 3 : 0;

          return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
          console.log(e)
        }
      }
  },

  computed: {
      url() {
          return App.url + '/' + this.locale + '/cart';
      },

      listUrl() {
          return App.url + '/' + this.locale;
      },

      lastResultUrl() {
          if (this.$cookie.get('filter') == null || this.$cookie.get('filter') == "null") {
              return App.url;
          }

          if (this.$cookie.get('filter').startsWith("/")) {
              return App.url + this.$cookie.get('filter');
          }

          return App.url + "/" + this.locale + "/" +this.$cookie.get('filter');
      },

      modalClass() {
          return this.product ? 'modal fade show' : 'modal fade';
      },

      backdropClass() {
          return this.product ? 'modal-backdrop fade show' : 'modal-backdrop fade';
      },
      computedLanguage() {
          let prefix = ''

          let language = '';
          if (this.product.options.language) {
              if (this.product.options.language == 'en') {
                  language = this.$t("cartmodal.english", null, this.locale)
              }
              else if (this.product.options.language == 'de') {
                  language = this.$t("cartmodal.german", null, this.locale)
              }

              if (this.product.options.productType) {
              prefix = ' / '
            }
          }

          return prefix + language
      },

    types() {
      return {
        Print: 'Print',
        Download: 'Download',
        PrintDownload: 'Print + Download Kombi-Version'
      }
    }
  }

}
</script>