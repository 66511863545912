'use strict';

import ContactForm from './components/ContactForm.vue';

export default class {

    constructor() {

        Vue.component('contact-form', ContactForm);
    }
}