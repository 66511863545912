<template>
    <div class="row">
        <div class="col-sm-12">
            <div class="row text-md-right">
                <button @click.prevent="submit" class="btn btn-primary">
                  {{$t("wirecard.ordernow", null, locale)}}
                </button>
            </div>
        </div>
    </div>        
</template>

<script>
export default {
    props: {
        locale: {
            type: String,
            default: 'de'
        }
    },
    methods: {
        submit() {
            this.$emit('submit');
        }
    }

}
</script>
