<template>
  <div>
    <div class="row">
      <div class="col-sm-12 blackBar">
        <div class="title">{{$t("cart.invoiceAddress", null, locale)}}</div>
      </div>
    </div>
    <div class="row backgroundGray">
      <div class="col-sm-12">
        <i v-if="!useCustomAddress && user && user.isFirm == 1">
          {{user.company}} {{(user.vatin != '') ? ' - ' + user.vatin : ''}}<br>{{user.street}}<br>{{user.zip}} {{user.city}}<br>{{(user.country != 156) ? user.countryName : ''}}<br>
        </i>
        <i v-else-if="!useCustomAddress && user && user.isFirm == 0 && user.externalIdentifier != 'guest'">
          <template v-if="user.bnr == 0 && user.maincontact && user.maincontact.vatin.length > 0">
            {{ user.maincontact.company }} {{ (user.maincontact.vatin != '') ? ' - ' + user.maincontact.vatin : ''}}<br>
          </template>
          <template v-else-if="user.company.length > 0">
            {{user.company}} {{ (user.vatin != '') ? ' - ' + user.vatin : ''}}<br>
          </template>
          <template v-else>
            {{user.title}} {{user.firstName}} {{user.lastName}}{{user.titleAfter}}<br>
          </template>
          {{user.street}}<br>{{user.zip}} {{user.city}}<br>{{(user.country != 156) ? user.countryName : ''}}
        </i>
        <i v-else-if="!useCustomAddress && user && user.isFirm == 0 && user.externalIdentifier == 'guest'">
          <template v-if="user.company.length > 0">
            {{user.company}} {{(user.vatin != '') ? ' - ' + user.vatin : ''}}
            <br>
          </template>
          {{user.title}} {{user.firstName}} {{user.lastName}}{{user.titleAfter}}<br>{{user.street}}<br>{{user.zip}} {{user.city}}<br>{{(user.country != 156) ? user.countryName : ''}}

        </i>
        <template v-if=" ! (user && user.isFirm == 0 && user.externalIdentifier != 'guest' && user.bnr == 0 && user.maincontact && user.maincontact.vatin.length > 0)">
        <div class="checkbox">
          <label>
            <input type="checkbox" name="useDifferentInvoiceAddress" v-model="useCustomAddress" @click="showAddresses = false">
            <span>{{$t("cart.differentInvoiceAddress", null, locale)}}</span>
          </label>
        </div>
        </template>
      </div>
    </div>
    <div v-if="showAddresses && addressBook && addressBook.length">
      <div v-for="(address, index) in addressBook" :key="index" class="row backgroundGray" style="padding:5px 0; border-top:1px solid white;">
        <div class="col-sm-9" style="line-height:40px; padding-left:15px;">
          {{index + 1}}. {{address.company}} - {{address.vatin}} {{address.salutation}} {{address.firstName}} {{address.lastName}}, {{address.street}}, {{address.zip}} {{address.city}}
        </div>
        <div class="col-sm-3 blackBar" style="margin:0;">
          <a class="btn btn-primary active" href="#" @click.prevent="selectAddreess(index)">
            {{$t("cart.choose", null, locale)}}
          </a>
        </div>
      </div>
    </div>
    
    <div v-if="useCustomAddress" class="backgroundGray">
      <div class="" style="padding: 10px 0;">
        <a class="btn btn-primary active"  href="#" @click.prevent="showAddresses = !showAddresses" v-if="addressBook && addressBook.length > 0">
          <template v-if=" ! showAddresses">
            {{$t("cart.openAddressBook", null, locale)}}
          </template>
          <template v-else>
            {{$t("cart.closeAddressBook", null, locale)}}
          </template>
        </a>
      </div>
      <input type="hidden" name="billingAddressId" v-if="selectedAddress" :value="selectedAddress"/>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="invoiceAddressCompany">{{$t("register.company", null, locale)}} (Optional)</label>
            <input class="form-control" id="invoiceAddressCompany" name="invoiceAddress[company]" @change="onFieldChange" v-model="address.company" :placeholder="$t('register.company', null, locale)">
          </div>
        </div>
         <div class="col-sm-6">
          <div class="form-group" :class=" validVatin ? '' : 'has-error' ">
            <label for="invoiceAddressVatin">{{$t("register.vatin", null, locale)}}</label>
            <input class="form-control" id="invoiceAddressVatin" name="invoiceAddress[vatin]" @change="onFieldChange" v-model="address.vatin" :placeholder="$t('register.vatin', null, locale)">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="invoice-name">{{$t("register.zuhaenden", null, locale)}}</label>
            <input class="form-control" type="text" id="invoice-name" name="invoiceAddress[info]" @change="onFieldChange" v-model="address.name" maxlength="100" :placeholder="$t('register.zuhaenden', null, locale)">
          </div>
        </div>
      </div>
      
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="invoiceAddressStreet">{{$t("register.street", null, locale)}} *</label>
            <input class="form-control" id="invoiceAddressStreet" name="invoiceAddress[street]" @change="onFieldChange" v-model="address.street" :placeholder="$t('register.street', null, locale)">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group">
            <label for="invoiceAddressZip">{{$t("register.plz", null, locale)}} *</label>
            <input class="form-control" id="invoiceAddressZip" name="invoiceAddress[zip]" @change="onFieldChange" v-model="address.zip" :placeholder="$t('register.plz', null, locale)">
          </div>
        </div>
        <div class="col-sm-8">
          <div class="form-group">
            <label for="invoiceAddressCity">{{$t("register.city", null, locale)}} *</label>
            <input class="form-control" id="invoiceAddressCity" name="invoiceAddress[city]" @change="onFieldChange" v-model="address.city" :placeholder="$t('register.city', null, locale)">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label for="invoiceAddressCountry">{{$t("register.country", null, locale)}} *</label>
            <select style="display: block; height: 2rem;" class="form-control" id="invoiceAddressCountry" name="invoiceAddress[country]" @change="onFieldChange" v-model="address.country">
              <option v-for="country in countries" :key="country.id" :value="country.id" :selected="(country.id == address.country)">{{ locale == 'de' ? country.name : country.name_en }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import _ from "lodash";

  export default {
    props: {
      user: {
        required: true,
        default: null
      },
      locale: {
        type: String,
        default: 'de'
      },
      hydrationData: {
        type: Object,
          default: function () {
              return {};
          }
      }
    },

    data() {
      return {
        showAddresses: false,
        useCustomAddress: false,
        loadingAddressBook: false,
        address: {
          company: '',
          salutation: '',
          firstName: '',
          lastName: '',
          street: '',
          city: '',
          zip: '',
          country: 156,
          vatin: '',
          mail: '',
          phone: '',
          id: null
        },
        addressBook: null,
        selectedAddress: null,
        validVatin: true,
        countries: []
      }
    },

    methods: {

      loadAddressBook() {
        this.loadingAddressBook = true;
        new Form().get('/api/me/addressbook')
          .then(addressBook => {
            this.loadingAddressBook = false;
            if (addressBook.length) {
              this.addressBook = addressBook;
            }
          })
      },

      selectAddreess(index) {
        this.useCustomAddress = true;
        this.address = this.addressBook[index];
        this.selectedAddress = this.address.id;
        this.showAddresses = false;
      },

      onFieldChange() {
        this.selectedAddress = null;
      },

      checkVatin: function () {
        if (this.address.vatin === '') {
          App.event.fire('invoice.vatin.valid', true);
          this.validVatin = true;
          return
        }

        axios.get('/shop/api/validate-vatin', {
          params: {
            vatin: this.address.vatin
          }
        })
          .then((response) => {
            App.event.fire('invoice.vatin.valid', response.data.valid);
            this.validVatin = response.data.valid;
          })
          .catch(function (error) {
            App.event.fire('invoice.vatin.valid', false);
          })
      }
    },

    watch: {
      vatin: function (newVatin, oldVatin) {
        this.debouncedCheckVatin()
      }
    },

    computed: {
      vatin () {
        return this.address.vatin;
      }
    },
      
    created() {
      this.debouncedCheckVatin = _.debounce(this.checkVatin, 500)
        
      this.countries = App.countries;
      this.address = {
        company: '',
        salutation: '',
        firstName: '',
        lastName: '',
        street: '',
        city: '',
        zip: '',
        country: 156,
        mail: '',
        phone: '',
        vatin: '',
        id: null
      };

        if (this.hydrationData.useDifferentInvoiceAddress == "on") {
            this.useCustomAddress = true;
            this.address.city=this.hydrationData.invoiceAddress.city;
            this.address.company=this.hydrationData.invoiceAddress.company;
            this.address.country=this.hydrationData.invoiceAddress.country;
            this.address.name=this.hydrationData.invoiceAddress.info;
            this.address.street=this.hydrationData.invoiceAddress.street;
            this.address.vatin=this.hydrationData.invoiceAddress.vatin;
            this.address.zip=this.hydrationData.invoiceAddress.zip;
            this.address.id=this.hydrationData.invoiceAddress.id;
            this.selectedAddress=this.hydrationData.invoiceAddress.id;
        }

      this.loadAddressBook();

      App.event.listen('countries.loaded', countries => this.countries = countries);
    }

  }
</script>
