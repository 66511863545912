'use strict';

import InstantRegistration from './components/InstantRegistration.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('instant-registration', InstantRegistration);

    }
}
