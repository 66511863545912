<template>
    <div v-if="display === 'kmveranstaltungen'" :class="results == '' ? 'km-events p-0' : 'km-events' ">
      <h2 v-if="results !== ''">{{ header }}</h2>
      <div v-html="results"></div>
    </div>
    <div v-else :class="(!loading && results == '') ? 'km-events p-0' : 'km-events mb-2'">
      <template v-if="loading">
        <loading-animation>{{$t('topevents.loadingtext', null, locale)}}</loading-animation>
      </template>
      <template v-else>
        <div class="top-events">
          <h2 v-if="!loading && results != ''">{{notopevents == false ? 'Top Events' : $t('topevents.mightinterestyou', null, locale)}}</h2>
          <div class="row event-overview" v-html="results" v-if="display === 'grid'">
          </div>
          <div class="row event-overview" id="shop" v-html="results" v-else>
          </div>
        </div>
      </template>
    </div>
</template>
<script>
import LoadingAnimation from '../../event-search/components/LoadingAnimation'

export default {
    components: {
        LoadingAnimation
    },
    props: {
        lang: {
            type: String,
            default: 'de'
        },
        area: {
            type: Number,
            default: 2
        },
        display: {
            type: String,
            default: 'list'
        },
        count: {
          type: Number,
          default: 4
        },
        notopevents: {
          type: Boolean,
          default: false
        },
        locale: {
          type: String,
          default: 'de'
        },
        header: {
          type: String,
          default: ''
        }
    },
    data() {
        return {
            loading: true,
            results: '',
        }
    },
    methods: {
        async getEvents() {
            this.loading = true
            let options = {
                lang: this.lang,
                area: this.area,
                display: this.display,
                count: this.count,
                notopevents: this.notopevents,
                locale: this.locale,
            }
            let response = await new Form(options).post("/api/topevents")
            this.results = response.results
            this.loading = false
        }

    },
    created() {
        this.getEvents()
    }
}
</script>
