'use strict';

import CartHeader from './components/CartHeader.vue';
import CartHeaderRow from './components/CartHeaderRow.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('cart-header', CartHeader);
        Vue.component('cart-header-row', CartHeaderRow);

    }
}