<template>
    <div>
        <button class="btn large btn-primary" type="button" @click="onClick" :disabled="loading || disabled">
            <i class="fa fa-spinner fa-spin" v-if="loading"></i>
            <slot>{{ $t('registerbutton.register', null, locale) }}</slot>
        </button>
    </div>
</template>

<script>
export default {

    props: {
      locale: {
        type: String,
        default: 'de'
      }
    },

    data() {
        return {
            loading: false,
            disabled: false
        }
    },

    methods: {
        onClick() {
            this.loading = true;
            App.event.fire('registration.send');
        }
    },

    created() {

        App.event.listen('registration.failed', response => this.loading = false);
        App.event.listen('registration.success', response => {
            window.location = App.url + response.redirect;
        });

        App.event.listen('vatin.valid', (valid) => {
            this.disabled = ! valid;
        });
    }
}
</script>
