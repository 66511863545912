'use strict';

import PasswordChange from "./components/PasswordChange.vue";

export default class {

    constructor() {

        Vue.component('password-change', PasswordChange);
    }
}