'use strict';

import Login from "./components/Login.vue";

export default class {

    constructor() {

        Vue.component('login', Login);

    }
}
