import locales from './locales'

export default class Language {
  constructor (locale) {
    this.$locale = locale
    this.$messages = { ...locales }
  }

  $t (key, data, locale, fallback) {
    fallback = fallback || `!-- ${key} --!`
    let [group, item] = this.parseKey(key)
    let line = this.getLine(group, item, locale) || fallback

    return this.makeReplacements(line, data)
  }

  parseKey (key) {
    let segments = key.split('.')
    let group = segments[0]
    let item = segments.length === 1
      ? null
      : segments.slice(1).join('.')

    return [group, item]
  }

  getLine (group, item, locale) {
    let lines = this.$messages[locale || this.$locale][group]

    if (item === null) {
      return lines
    }

    if (lines && lines.hasOwnProperty(item)) {
      return lines[item]
    }

    if (item.indexOf('.') !== -1) {
      let segments = item.split('.')
      for (let index in segments) {
        if (lines && lines.hasOwnProperty(segments[index])) {
          lines = lines[segments[index]]
        }
      }
    }
    return typeof lines === 'string' ? lines : null
  }

  makeReplacements (line, replace) {
    if (!replace) {
      return line
    }

    for (let key in replace) {
      let value = replace[key]
      line = line
        .replace(`:${key}`, value)
        .replace(`:${key.toUpperCase()}`, value.toUpperCase())
    }

    return line
  }
}

Language.install = function (Vue) {
  if (!Vue.prototype.hasOwnProperty('$lang')) {
    Object.defineProperty(Vue.prototype, '$lang', {
      get () { return this._lang }
    })
  }

  Vue.prototype.$t = function (key, data, locale, fallback) {
    return this.$root.$lang.$t(key, data, locale, fallback)
  }

  Vue.mixin({
    beforeCreate () {
      const options = this.$options
      options.lang = options.lang || (options.__lang ? {} : null)
      if (options.lang && options.lang instanceof Language) {
        this._lang = options.lang
      }
    },
    beforeDestroy () {
      this.$nextTick(() => {
        this._lang = null
      })
    }
  })
}
