'use strict';

import EventCartModal from './components/EventCartModal.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('event-cart-modal', EventCartModal);

    }
}
