'use strict';

import OwlCarusel from './components/OwlCarusel.vue';

export default class {

    constructor() {

        Vue.component('owl-carusel', OwlCarusel);
    }
}