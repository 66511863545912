'use strict';

import ShippingAddress from './components/ShippingAddress.vue';

export default class {

    constructor() {
        // Register component globaly
        Vue.component('shipping-address', ShippingAddress);

    }
}
