'use strict';

import AddRootToWatchlist from './components/AddRootToWatchlist.vue';

export default class {

  constructor() {
    // Register component globaly
    Vue.component('add-root-to-watchlist', AddRootToWatchlist);
  }
}