<template>
    <div id="event-cart-modal" v-if="product">
        <div :class="modalClass" tabindex="-1" role="dialog" style="display: block">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <form @submit.prevent="submit">
                    <div class="modal-header">
                        <h4 class="modal-title">{{$t("eventcartmodal.addparticipant", null, locale)}}</h4>
                        <button type="button" class="close" @click="close">
                            <i class="fa fa-times"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row" v-for="(attendee, key) in attendees" v-bind:key="key">
                          <div class="input-group attendee col-12">
                              <div class="input-group-prepend"><span class="input-group-text">{{$t("eventcartmodal.participant", null, locale)}} {{key + 1}}</span></div>
                              <select type="text" class="form-control" name="anrede" v-model="attendee.salutation" >
                                <option value="" disabled selected>{{$t("eventcartmodal.salutation", null, locale)}}</option>
                                <option value="Herr">{{$t("eventcartmodal.male", null, locale)}}</option>
                                <option value="Frau">{{$t("eventcartmodal.female", null, locale)}}</option>
                              </select>
                              <input type="text" class="form-control" name="titel" v-model="attendee.title" :placeholder="$t('register.title', null, locale)">
                              <input type="text" class="form-control" name="vorname" v-model="attendee.firstname" :placeholder="$t('register.firstname', null, locale)">
                              <input type="text" class="form-control" name="nachname" v-model="attendee.lastname" :placeholder="$t('register.surname', null, locale)">
                              <input type="email" class="form-control" name="email" v-model="attendee.email" :placeholder="$t('register.email', null, locale)">
                              <div class="input-group-append">
                                  <span class="input-group-text" id="basic-addon2">&euro; {{ attendee.price.toFixed(2) }}</span>
                              </div>
                          </div>
                          <div class="input-group attendee col-12 align-items-center" v-if="isMess20">
                            <label for="attendDays" class="form-label m-0 mr-2">Teilnahme am:</label>
                            <select type="text" class="form-control mr-3" id="attendDays" name="attendDays" v-model="attendee.attendDays" style="max-width: 300px;">
                              <option value="27.05.2020 und 28.05.2020" selected>27.05.2020 und 28.05.2020</option>
                              <option value="27.05.2020">27.05.2020</option>
                              <option value="28.05.2020">28.05.2020</option>
                            </select>
                            <label class="form-label m-0 d-flex align-items-center">
                              <input type="checkbox" name="attendConferenceDinner" class="mr-1" v-model="attendee.attendConferenceDinner">
                              Teilnahme am Conference Dinner
                            </label>
                            <div class="empty" style="flex:1">&nbsp;</div>
                          </div>
                          <div class="input-group attendee col-12" v-if="isMoreDrive">
                            <label for="attendDays2" class="form-label m-0 mr-2">Teilnahme:</label>
                            <select type="text" class="form-control mr-3" id="attendDays2" name="attendDays2" v-model="attendee.attendDays2" style="max-width: 300px;">
                              <option value="ohne Conference Dinner" selected>ohne Conference Dinner</option>
                              <option value="mit Conference Dinner">mit Conference Dinner</option>
                              <option value="mit Conference Dinner und Begleitperson (+ € 50)">mit Conference Dinner und Begleitperson (+ € 50)</option>
                            </select>
                          </div>
                          <div class="input-group attendee col-12" v-if="product.specialOptions.length > 0">
                            <div v-for="(specialOption) of product.specialOptions">
                              <label>
                                <input type="checkbox" name="specialOptions[]"  :value="specialOption.id" v-model="attendee.selectedSpecialOptions" />
                                € <span v-text="specialOption.price"></span> für <span v-text="specialOption.name" class="mr-2"></span>
                              </label>
                            </div>
                          </div>
                          <div class="input-group attendee col-12" v-if="product.requiredOptions.length > 0">
                            <div v-for="(specialOption) of product.requiredOptions">
                              <label>
                                <input type="checkbox" name="requiredOptions[]"  :value="specialOption.id" v-model="attendee.requiredSpecialOptions" />
                                € <span v-text="specialOption.price"></span> für <span v-text="specialOption.name" class="mr-2"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="error-message text-center text-danger" v-if="errors.length">
                            {{errorStrings}} {{$t("eventcartmodal.cannotbeempty", null, locale)}}
                        </div>
                        <div class="error-message text-center text-danger" v-if=" ! emailsUnique">
                           {{$t("eventcartmodal.differentemail", null, locale)}}
                        </div>
                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" @click="close">
                          {{$t("eventcartmodal.cancel", null, locale)}}
                        </button> &nbsp;
                        <button type="submit" class="btn btn-default btn-primary"><i v-if="loading" class="fa fa-spin fa-spinner"></i> 
                          {{$t("eventcartmodal.addtocart", null, locale)}}
                        </button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
        <div :class="backdropClass"></div>
    </div>
</template>

<script>

export default {
    props: {
        locale: {
            type: String,
            default: 'de'
        },
        isMessEvent: {
          type: String,
          default: '0'
        },
        isMoreDriveEvent: {
            type: String,
            default: '0'
        }
    },
    data() {
        return {
            product: null,
            attendees: [],
            user: null,
            loading: false,
            validated: false,
            valid: false,
            errors: [],
            emailsUnique: true,
            mess20id: 650999,
            specialSelectedOptions: []
        }
    },

    created() {

      new Form().get('/api/me').then( (response) => { 
          this.user = response 
      })
  
      App.event.listen('profile.loaded', (user) => {
          this.user = user
      })
  
      App.event.listen('cart.event.inserted', (product) => {
          this.product = product;

          /*
          product.specialOptions = 
          array of items with { id, price, name }
           */
          
          this.specialSelectedOptions = [];
          this.requiredSelectedOptions = [];

          for (let special of product.specialOptions) {
            this.specialSelectedOptions.push(special.id);
          }

          for (let special of product.requiredOptions) {
            this.requiredSelectedOptions.push(special.id);
          }
    
          this.createAttendees()
        
        /*
        attendee.selectedSpecialOptions: this.selectedSpecialOptions grigt id der gewählten pro teilnehmer
         */
      })

    },

    methods: {
        createAttendees() {
            let attendees = new Array()
            let attCount = this.product.qty;
            if (this.user) {
              if (this.isMess20) {
                attendees.push({
                    salutation: this.user.salutation,
                    title: this.user.title,
                    firstname: this.user.firstName,
                    lastname: this.user.lastName,
                    email: this.user.mail,
                    selectedSpecialOptions: this.specialSelectedOptions,
                    requiredSpecialOptions: this.requiredSelectedOptions,
                    costid: this.product.costid,
                    price: 0,
                    attendDays:'27.05.2020 und 28.05.2020'
                });
                attCount--
              } else if(this.isMoreDrive){
                  attendees.push({
                      salutation: this.user.salutation,
                      title: this.user.title,
                      firstname: this.user.firstName,
                      lastname: this.user.lastName,
                      email: this.user.mail,
                    selectedSpecialOptions: this.specialSelectedOptions,
                      requiredSpecialOptions: this.requiredSelectedOptions,
                    costid: this.product.costid,
                      price: 0,
                      attendDays2: 'ohne Conference Dinner'
                  });
                  attCount--
              } else {
                attendees.push({
                    salutation: this.user.salutation,
                    title: this.user.title,
                    firstname: this.user.firstName,
                    lastname: this.user.lastName,
                    email: this.user.mail,
                  selectedSpecialOptions: this.specialSelectedOptions,
                    requiredSpecialOptions: this.requiredSelectedOptions,
                  costid: this.product.costid,
                    price: 0,
                });
                attCount--
              }
            }
            for(let i = 0; i < attCount; i++) {
              if (this.isMess20) {
                  attendees.push({salutation: '', title: '', firstname: '', lastname: '', email: '', attendDays: '27.05.2020 und 28.05.2020', selectedSpecialOptions: this.specialSelectedOptions, requiredSpecialOptions: this.requiredSelectedOptions, costid: this.product.costid})
              } else if(this.isMoreDrive){
                  attendees.push({salutation: '', title: '', firstname: '', lastname: '', email: '', attendDays2: 'ohne Conference Dinner', selectedSpecialOptions: this.specialSelectedOptions, requiredSpecialOptions: this.requiredSelectedOptions, costid: this.product.costid})
                } else {
                  attendees.push({salutation: '', title: '', firstname: '', lastname: '', email: '', selectedSpecialOptions: this.specialSelectedOptions,  requiredSpecialOptions: this.requiredSelectedOptions, costid: this.product.costid})
              }
            }

            for(let j = 0; j < this.product.qty; j++) {
                if (j < this.product.reducedamount) {
                    attendees[j].price = this.product.reducedprice
                    attendees[j].origprice = this.product.reducedprice
                }
                else
                {
                    attendees[j].price = this.product.normalprice
                    attendees[j].origprice = this.product.normalprice
                }
            }
            this.attendees = attendees
        },

        close() {
            this.product = null;
        },

        isValid() {
            this.errors = []
            
            let uniqueEmails = [];
            
            for(let i = 0; i < this.attendees.length; i++)
            {
                uniqueEmails.push(this.attendees[i].email);

                if (!this.attendees[i].salutation)
                {
                    if (!this.contains(this.errors, "Anrede"))
                    {
                        this.errors.push("Anrede")
                    }
                }
                if (!this.attendees[i].firstname)
                {
                    if (!this.contains(this.errors, "Vorname"))
                    {
                        this.errors.push("Vorname")
                    }
                } 
                if (!this.attendees[i].lastname)
                {
                    if (!this.contains(this.errors, "Nachname"))
                    {
                        this.errors.push("Nachname")
                    }
                }
                if (!this.attendees[i].email)
                {
                    if (!this.contains(this.errors, "Email"))
                    {
                        this.errors.push("Email")
                    }
                }
            }

            this.emailsUnique = this.attendees.length === uniqueEmails.filter((v, i, a) => a.indexOf(v) === i).length;

            return (this.errors.length <= 0) 
                && this.emailsUnique;
        },

        contains(a, obj) {
            var i = a.length;
            while (i--) {
                if (a[i] === obj) {
                    return true;
                }
            }
            return false;
        },

      fillSpecialOptions()
      {
        for (let index in this.attendees) {
           this.attendees[index].temp = this.attendees[index].specialOptions;
          this.attendees[index].specialOptions = [];
          this.attendees[index].requiredOptions = [];

          
          for (let special of this.attendees[index].selectedSpecialOptions) {
            let spe = null;

            for (let spec of this.product.specialOptions) {
              if (special == spec.id) {
                spe = spec;
              }
            }

            if (spe == null) {
              continue;
            }

            this.attendees[index].specialOptions.push(spe);
          }
  
          for (let special of this.attendees[index].requiredSpecialOptions) {
            let spe = null;
  
            for (let spec of this.product.requiredOptions) {
              if (special == spec.id) {
                spe = spec;
              }
            }
  
            if (spe == null) {
              continue;
            }
  
            this.attendees[index].requiredOptions.push(spe);
          }

           delete this.attendees[index].temp;
        }
      },
      
        submit() {

            this.loading = true;

            this.fillSpecialOptions();
            
            if (this.isValid())
            {
                new Form({
                    pid: this.product.pid,
                    qty: this.selectedOption.attendees.length,
                    pstock: this.product.pstock,
                    options: this.selectedOption
                })
                .post('/api/cart/add')
                .then((response) => {
                    this.loading = false;
                    if(response.success) {
                        App.event.fire('cart.item.inserted', response.item)
                       // App.event.fire('cart.updated', response.data) // deactivated
                        // window.location = this.url;
                        this.product = null
                    } else {
                        swal("", this.$t("addtocart.tomanyproducts", null, this.locale), "error")
                    }
                    
                }).catch(errors => {
                    this.loading = false;
                });
            } else {
                this.loading = false;
            }
        }
    },

    computed: {
        url() {
            return App.url + '/cart';
        },

        isMess20() {
          return this.isMessEvent == '1';
        },
        
        isMoreDrive(){
            return this.isMoreDriveEvent == '1';
        },

        modalClass() {
            return this.product ? 'modal fade show' : 'modal fade';
        },

        backdropClass() {
            return this.product ? 'modal-backdrop fade show' : 'modal-backdrop fade';
        },

        selectedOption() {
            let attendees = {
                attendees: []
            }
            this.attendees.forEach((element) => {
              let extra = 0;

              // element.temp = element.specialOptions;
              // element.specialOptions = [];
              //
              // for (let special of element.temp) {
               for (let special of element.selectedSpecialOptions) {
                 let spe = null;
              
                 for (let spec of this.product.specialOptions) {
                   if (special == spec.id) {
                     spe = spec;
                   }
                 }
              
                 if (spe == null) {
                    continue;
                 }
                 //  
                 //   element.specialOptions.push(spe);
                 //  
                 extra += parseFloat(spe.price);
               }
      
              for (let special of element.requiredSpecialOptions) {
                let spe = null;
      
                for (let spec of this.product.requiredOptions) {
                  if (special == spec.id) {
                    spe = spec;
                  }
                }
      
                if (spe == null) {
                  continue;
                 }
              //  
              //   element.specialOptions.push(spe);
              //  
                 extra += parseFloat(spe.price);
              }
              //
              // delete element.temp;

              if (element.hasOwnProperty('origprice')) {
                element.price = element.origprice + extra;
              }

                attendees.attendees.push(element)
            })
            return attendees;
        },

        errorStrings() {
            return this.errors.join(', ')
        }

    }

}
</script>