<template>
    <div class="membership-add-to-cart add-to-cart">
        <div v-if="type == 'detail'" :class="['no-gutters', {hasSize:hasOptions}]">
            <div class="add">
                <button class="btn btn-primary" @click="submit" :disabled="loading" >
                    <i v-if="loading" class="fa fa-spin fa-spinner"></i>
                    <i v-if="!loading" class="fas fa-shopping-bag"></i>
                    <slot name="name">{{$t('membershipaddtocart.addtocart', null, locale)}}</slot>
                </button>
            </div>
        </div>
        <div v-if="type == 'overview'" :class="{hasSize:hasOptions}">
            <div class="basket row no-gutters">
                <div class="col-6 text-center">
                    <button @click="submit" :disabled="loading" class="btn active">
                        <i v-if="loading" class="fa fa-spin fa-spinner"></i>
                        <i v-if="!loading" class="fas fa-shopping-bag"></i>
                    </button>
                </div>
            </div>        
        </div>
    </div>
</template>
<script>
import Vue from "vue";

export default {
  props: {
      pid: {
        type: [String, Number],
        required: true,
        default: null
      },
      pseo: {
        type: [String],
        default: null
      },
      type: {
        type: [String],
        default: 'overview'
      },
      options: {
          type: Array,
          default: function () { return [] }
      },
      locale: {
          type: String,
          default: 'de'
      }
  },

  data() {
      return {
          qty: 1,
          loading: false,
          selectedArtNr: null,
      }
  },

  computed: {
      coptions() {
          let $returnValue = [];
          if (this.hasOptions) {
            let $options = this.options;
            $options.forEach(element => {
                element["ShopProductOption.options"].forEach(opt => {
                    $returnValue.push({name: (element["ShopProductOption.size"] + " - " + decodeURIComponent(opt.color)).toUpperCase(), art: opt.artikelnr});
                });
            });
          }
          return $returnValue;
      },
      firstCoption() {
          return this.coptions.slice().shift();
      },
      selectedOption() {
          let option = [];
          if (this.hasOptions) {
              this.options.forEach(element => {
                  element["ShopProductOption.options"].forEach(opt => {
                      if (opt.artikelnr == this.selectedArtNr) {
                          option = {
                              Size: element["ShopProductOption.size"],
                              ArticleNr: opt.artikelnr,
                              Color: decodeURIComponent(opt.color)
                          }
                      }
                  })
              })
          }
          return option;
      },
      hasOptions() {
          return (this.options.length > 0);
      }
  },

  mounted() {
      if(this.hasOptions) {
          this.selectedArtNr = this.firstCoption.art;
      }
  },
    created(){
        App.event.listen('profile.loaded', (user) => {
            if(document.cookie.match('(^|;) ?register_back_product=([^;]*)(;|$)')){
                this.submit()  
            }
        })

    },

  methods: {
        onSelected({currentTarget}) {
            window.location = App.url+'/product/' + this.pseo;
        },

      updateQty(qty) {
        this.qty = qty;
      },

      submit() {
        App.event.fire('cart.membership.inserted', { 
            pid: this.pid, 
            qty: this.qty, 
            pstock: this.pstock, 
            options:this.selectedOption,
            pseo: this.pseo
        })
      }
  }
}
</script>
