import SearchQuery from './components/SearchQuery'
// import SearchFilters from './components/SearchFilters'
import SearchResult from './components/SearchResult'
import SearchPagination from './components/SearchPagination'
import ShopSearchResults from './components/ShopSearchResults'
import SearchProductAmount from './components/SearchProductAmount'

export default {
  SearchQuery,
  // SearchFilters,
  SearchResult,
  SearchPagination,
  ShopSearchResults,
  SearchProductAmount
}