export default {
    mmenu: {
        navbar: {
            title: ''
        },
        extensions: [
            "fx-listitems-slide",
            "fx-panels-slide-up",
            "fx-menu-slide",
            "pagedim-black",
            "shadow-page",
            "theme-white"
        ],
        offCanvas: {
            "position": "left"
        }
    },
    slick: {
        config: {
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
        },
        responsive: {
            lg: {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            md: {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            sm: {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            xs: {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        }
    },
    magnificPopup: {
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
          verticalFit: true
        }
    },
    cookieconsent: {
      "palette": {
        "popup": {
          "background": "#395279",
          "text": "#ffffff"
        },
        "button": {
          "background": "#ffffff",
          "text": "#395279"
        }
      },
      "content": {
        "message": "Diese Webseite verwendet Cookies, um die Bedienfreundlichkeit zu erhöhen.",
        "dismiss": "Akzeptieren",
        "link": "Mehr erfahren.",
        "href": "/impressum/rechtliches-agb#c10184"
      }
    }

}