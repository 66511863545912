<template>
    <div class="ove-akademy-aktuell">
        <h2><slot>OVE ACADEMY AKTUELL</slot></h2>
        <template v-if="events">
            <a class="entry" v-for="(event, index) in events" :key="index" :href="'/shop/product/'+event.seo">
                <p>{{ event.title}}</p>
                <p class="datetime" v-if="event.city">{{event.city}}, {{event.date}}</p>
                <p class="datetime" v-else>{{event.date}}</p>
            </a>
        </template>
        <p v-else>{{$t("eventlist.noeventsavailable", null, locale)}}</p>
    </div>
</template>
<script>
export default {
    props: {
        amount: Number,
        area: Number,
        onlytopevents: {
          type: Number,
          default: 0
        },
        locale: {
            type: String,
            default: 'de'
        }
    },
    data() {
        return {
            events: [],
            baseUrl: '',
            loading: true
        }
    },
    methods: {
        async loadEvents() {
            let response = await new Form({amount: this.amount, notopevents: true, area: this.area}).post('/api/events')
            this.loading = false
            this.events = response.results
            this.baseUrl = response.baseUrl
        }
    },
    created() {
        this.loadEvents()
    }
}
</script>
<style>

</style>
